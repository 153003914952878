import { Navigate, Outlet, Route, Routes, useSearchParams } from "react-router-dom";
import AdminHome from "../pages/AdminHome";
import AdminUsers from "../pages/AdminUsers";
import CreateUser from "../pages/CreateUser";
import ViewUser from "../pages/ViewUser";
import AdminCompanies from "../pages/AdminCompanies";
import CompanyDetails from "../pages/ViewCompany";
import AdminTags from "../pages/AdminTags";
import CreateTag from "../pages/CreateTag";
import ViewTag from "../pages/ViewTag";
import AdminRequests from "../pages/AdminRequests";
import ViewRequest from "../pages/ViewRequest";
import AdminNotifications from "../pages/AdminNotifications";
import CreateNotifications from "../pages/CreateNotifications";
import NotificationDetails from "../pages/NotificationDetails";
import AdminSetting from "../pages/AdminSettings";
import AdminLogin from "../pages/Login/Admin";
import AdminUserBar from "../components/Sidebar/AdminUser";
import AdminCompanyBar from "../components/Sidebar/AdminCompany";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../redux/modules/auth/userSelector";
import Unauthorized from "../pages/Unauthorized";
import PasswordReset from "../pages/PasswordReset";
import Migration from "../pages/Migration";
import CreateCompany from "../pages/CreateCompany";
import Spinner from "../components/Spinner";
import { useEffect } from "react";
import { whoAmI } from "../redux/modules/auth/actions";
import { reset } from "../redux/modules/auth/slice";
import NotFound from "../pages/NotFound";
import { setThirdPartyUser } from "../redux/modules/thirdPartyUser/slice";
import AIResponse from "../pages/AdminCompanies/adminAiResponse"

export function AdminRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<AdminLogin />} />
      <Route path="/thirdparty/auth" element={<ProxyLogin />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route index element={<Navigate to="/home" />} />
        <Route path="/airesponse" element={<AIResponse />} />
        <Route path="home" element={<AdminHome />} />
        <Route path="users" element={<AdminUserBar />}>
          <Route index element={<AdminUsers />} />
          <Route path="new" element={<CreateUser />} />
          <Route path="view/:userId" element={<ViewUser />} />
        </Route>
        <Route path="companies" element={<AdminCompanyBar />}>
          <Route index element={<AdminCompanies />} />
          <Route path="new" element={<CreateCompany />} />
          <Route path="view/:id" element={<CompanyDetails />} />
        </Route>
        <Route path="tags" element={<AdminCompanyBar />}>
          <Route index element={<AdminTags />} />
          <Route path="new" element={<CreateTag />} />
          <Route path="view/:id" element={<ViewTag />} />
        </Route>
        <Route path="requests" element={<AdminCompanyBar />}>
          <Route index element={<AdminRequests />} />
          <Route path="view/:id" element={<ViewRequest />} />
        </Route>
        <Route path="notifications" element={<AdminCompanyBar />}>
          <Route index element={<AdminNotifications />} />
          <Route path="new" element={<CreateNotifications />} />
          <Route path="view/:id" element={<NotificationDetails />} />
        </Route>
        <Route path="migrate" element={<AdminCompanyBar />}>
          <Route index element={<Migration />} />
        </Route>
        <Route path="settings" element={<AdminSetting />} />
      </Route>
      {/* <Route path="password-reset" element={<PasswordReset />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function PrivateRoute() {
  const { status, user } = useSelector(authSelector);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!user) {
      dispatch(whoAmI()).finally(() => dispatch(reset()));
    }
  }, [dispatch]);

  if (user && user.role === "admin") {
    return <Outlet />;
  } else if (user) {
    return <Unauthorized />;
  } else if (status === "failed") {
    return <Navigate to="/login" />;
  } else {
    return <Spinner />;
  }
}

function ProxyLogin(){
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { status, user } = useSelector(authSelector);

  if(token){
    sessionStorage.setItem("token", token);
    dispatch(setThirdPartyUser(false));
    dispatch(whoAmI()).finally(() => dispatch(reset()));
  }

  if (user && user.role === "admin") {
    return <Navigate to="/home" />;
  } else if (user) {
    return <Unauthorized />;
  } else if (status === "failed") {
    return <Navigate to="/login" />;
  } else {
    return <Spinner />;
  }
}