import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  httpAddCompanyPartner,
  httpAddCompanyPartnerName,
  httpAddFlag,
  httpAddKeySource,
  httpAddNugget,
  httpAddPartnerLink,
  httpAddTag,
  httpDeleteCompanyPartner,
  httpDeleteFlag,
  httpDeleteKeySource,
  httpDeleteNugget,
  httpDeletePartnerLink,
  httpDeleteTag,
  httpEditFlag,
  httpEditKeySource,
  httpEditNugget,
  httpGetNuggets,
} from "./api";
import {
  Company,
  Flag,
  Nugget,
  RootState,
  Tag,
  companySource,
  partnerCompany,
} from "../../utils/types";
import {
  addCompanyKeySource,
  addCompanyNugget,
  addCompanyTag,
  deleteCompanyNugget,
  deleteCompanyTag,
  updateCompanyNuggets,
  deleteCompanyKeySource,
  editCompanyKeySource,
  addCompanyFlag,
  deleteCompanyFlag,
  editCompanyFlag,
  addCompanyPartner,
  deleteCompanyPartner,
  addCompanyPartnerName,
  addCompanyPartnerLink,
  deleteCompanyPartnerLink,
  updateCompanyPartners,
} from "../company/slice";
import { httpGetPartnerCompanies } from "../company/api";
import { getCompanyDetails } from "../company/actions";

export const editNugget = createAsyncThunk(
  "company/editNugget",
  async (nugget: Nugget, thunkAPI) => {
    const id = nugget.id;

    try {
      const response = await httpEditNugget(id, { nugget: nugget.nugget });
      const nuggets: Nugget[] = await httpGetNuggets(nugget.companyId);
      return thunkAPI.dispatch(
        updateCompanyNuggets({ companyid: nugget.companyId, nugget: nuggets })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addNugget = createAsyncThunk(
  "comppany/addNugget",
  async (nugget: { companyId: number; nugget: string }, thunkAPI) => {
    try {
      const response: Nugget = await httpAddNugget(
        nugget.companyId,
        nugget.nugget
      );
      return thunkAPI.dispatch(
        addCompanyNugget({ companyId: nugget.companyId, nugget: response })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteNugget = createAsyncThunk(
  "company/deleteNugget",
  async (data: { companyId: number; nuggetId: number }, thunkAPI) => {
    try {
      const reponse = await httpDeleteNugget(data.nuggetId);
      return thunkAPI.dispatch(deleteCompanyNugget(data));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

// tags actions

export const addTag = createAsyncThunk(
  "company/addtag",
  async (data: { companyId: number; tag: Tag }, thunkAPI) => {
    const { companyId, tag } = data;
    try {
      const response = await httpAddTag(companyId, tag.id);
      const updatedTag: Tag = { ...tag, updatedAt: new Date() };
      thunkAPI.dispatch(addCompanyTag({ companyId, tag: updatedTag }));
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "company/deleteTag",
  async (data: { companyId: number; tagId: number }, thunkAPI) => {
    const { companyId, tagId } = data;

    try {
      const response = await httpDeleteTag(companyId, tagId);
      return thunkAPI.dispatch(deleteCompanyTag({ companyId, tagId }));
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

//add key source

export const addKeySource = createAsyncThunk(
  "company/addkeysource",
  async (data: { companyId: number; source: string }, thunkAPI) => {
    const { companyId, source } = data;
    try {
      const response: companySource = await httpAddKeySource(companyId, {
        source,
      });
      return thunkAPI.dispatch(
        addCompanyKeySource({ companyId, keySource: response })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteKeySource = createAsyncThunk(
  "company/deletekeysource",
  async (data: { companyId: number; sourceId: number }, thunkAPI) => {
    const { companyId, sourceId } = data;

    try {
      const response = await httpDeleteKeySource(sourceId);
      return thunkAPI.dispatch(
        deleteCompanyKeySource({ companyId, keySourceId: sourceId })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editKeySource = createAsyncThunk(
  "company/editkeysource",
  async (keySource: companySource, thunkAPI) => {
    const { source, id, companyId } = keySource;

    try {
      const keySource: companySource = await httpEditKeySource(id, { source });
      thunkAPI.dispatch(editCompanyKeySource({ companyId, keySource }));
    } catch (err) {
      thunkAPI.rejectWithValue(err);
    }
  }
);

// flags thunk actions

export const addFlag = createAsyncThunk(
  "company/addflag",
  async (data: { companyId: number; flag: { flag: string } }, thunkAPI) => {
    const { companyId, flag } = data;
    try {
      const flagRes: Flag = await httpAddFlag(companyId, flag);
      const payload = { companyId, flag: flagRes };
      return thunkAPI.dispatch(addCompanyFlag(payload));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteFlag = createAsyncThunk(
  "company/deleteFlag",
  async (data: { companyId: number; flagId: number }, thunkAPI) => {
    const { companyId, flagId } = data;

    try {
      await httpDeleteFlag(flagId);
      const payload = { companyId, flagId };
      return thunkAPI.dispatch(deleteCompanyFlag(payload));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editFlag = createAsyncThunk(
  "company/editFlag",
  async (data: { companyId: number; flag: Flag }, thunkAPI) => {
    const { companyId, flag } = data;
    let payload = null;
    try {
      const flagRes: Flag = await httpEditFlag(flag.id, { flag: flag.flag });
      payload = { companyId, flag: flagRes };
      return thunkAPI.dispatch(editCompanyFlag(payload));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addPartner = createAsyncThunk(
  "company/addpartner",
  async (data: { companyId: number; partnerId: number }, thunkAPI) => {
    try {
      const { companyId, partnerId } = data;
      const res = await httpAddCompanyPartner(companyId, partnerId);
      const partnerCompanies: partnerCompany[] = await httpGetPartnerCompanies(
        companyId
      );
      thunkAPI.dispatch(
        updateCompanyPartners({
          companyid: companyId,
          partners: partnerCompanies,
        })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addMultiplePartners = createAsyncThunk(
  "company/addMultiplePartner",
  async (data: { companyId: number; partnerIds: number[] }, thunkAPI) => {
    try {
      const { companyId, partnerIds } = data;

      //partners will be two way
      await Promise.all(
        partnerIds.map((partnerId: number) =>
          httpAddCompanyPartner(companyId, partnerId)
        )
      );

      await Promise.all(
        partnerIds.map((partnerId: number) =>
          httpAddCompanyPartner(partnerId, companyId)
        )
      );

      const partnerCompanies: partnerCompany[] = await httpGetPartnerCompanies(
        companyId
      );
      thunkAPI.dispatch(
        updateCompanyPartners({
          companyid: companyId,
          partners: partnerCompanies,
        })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deletePartner = createAsyncThunk(
  "company/deletePartner",
  async (data: { companyId: number; partnerId: number }, thunkAPI) => {
    const { companyId, partnerId } = data;

    try {
      const response = Promise.all([
        httpDeleteCompanyPartner(companyId, partnerId),
        httpDeleteCompanyPartner(partnerId, companyId),
      ]);
      const payload = { companyId, partnerId };
      thunkAPI.dispatch(deleteCompanyPartner(payload));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addPartnerName = createAsyncThunk(
  "company/addPartnerWithName",
  async (data: { companyId: number; companyName: string }, thunkAPI) => {
    const { companyId, companyName } = data;

    try {
      const partner: Company = await httpAddCompanyPartnerName(
        companyId,
        companyName
      );
      let payload = { companyId, partner };

      // thunkAPI.dispatch(addCompanyPartnerName(payload));
      // thunkAPI.dispatch(getCompanyDetails(partner.id));

      const partnerCompanies: partnerCompany[] = await httpGetPartnerCompanies(
        companyId
      );
      return thunkAPI.dispatch(
        updateCompanyPartners({
          companyid: companyId,
          partners: partnerCompanies,
        })
      );
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const addPartnerLink = createAsyncThunk(
  "company/addPartnerLink",
  async (payload: { companyId: number; companyLink: string }, thunkAPI) => {
    const { companyId, companyLink } = payload;

    try {
      const partner = await httpAddPartnerLink(companyId, companyLink);
      const payload = { companyId, partner };
      thunkAPI.dispatch(addCompanyPartnerLink(payload));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deletePartnerLink = createAsyncThunk(
  "company/deletePartnerLink",
  async (payload: { companyId: number; partnerId: number }, thunkAPI) => {
    const { companyId, partnerId } = payload;
    try {
      const response = await httpDeletePartnerLink(companyId, partnerId);
      return thunkAPI.dispatch(deleteCompanyPartnerLink(payload));
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
