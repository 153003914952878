import { MouseEventHandler, useState } from "react";
import { RxCross2 as Cross } from "react-icons/rx";
import { useLocation } from "react-router-dom";

export default function AddKeySources(props: {
  closeTab: MouseEventHandler<any>;
  keySources: any[];
  setKeySources: Function;
  show?: boolean;
}) {
  const { closeTab, keySources, setKeySources, show } = props;

  const [isError, setIsError] = useState<boolean>(false);

  const { pathname } = useLocation();

  const section = pathname.split("/")[1];

  const handleInputChange = (changedText: string, index: number) => {
    if (isError) setIsError(false);
    const list = [...keySources];
    list[index].source = changedText;
    setKeySources(list);
  };

  const handleRemoveKeySources = (index: number) => {
    const list = [...keySources];
    list.splice(index, 1);
    setKeySources(list);
  };

  const handleAddKeySources = () => {
    const lastKeySourceIndex = keySources.length - 1;
    const lastText = keySources[lastKeySourceIndex]?.source;

    if (!lastText && keySources.length > 0) {
      setIsError(true);
      return;
    }

    setKeySources([...keySources, { source: "" }]);
  };

  if (!show) {
    return <></>;
  }

  return (
    <div className="company-details">
      <div className="container-fluid inner-box">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            {/* <!-- ------------------Nuggets------------- --> */}

            <div className="nugget-page">
              <div className="card w-100">
                <div className="card-body">
                  <div className="top d-flex justify-content-space">
                    <div className="top-start">
                      <div>
                        <h5 className="card-title">
                          Key Sources and Links&nbsp;
                        </h5>
                      </div>
                    </div>
                    <div className="top-end">
                      {section === "user" && (
                        <div>
                          <button className="btn suggest-btn">
                            Suggest Update
                          </button>
                        </div>
                      )}
                      <div>
                        <span role="button" onClick={closeTab}>
                          <img
                            src="/img/back.png"
                            className="pl-3 img-icon"
                            alt="back"
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="bg mt-2">
                    <div className="bg mt-2">
                      {/* render sources here */}
                      {keySources.map((keySource, i) => (
                        <div className="my-3 d-flex align-items-center">
                          <input
                            value={keySource.source}
                            className="border-0 w-75 form-control list__test"
                            placeholder="Enter Key Source Information"
                            onChange={(e) =>
                              handleInputChange(e.target.value, i)
                            }
                            onKeyUp={(e) => {
                              if (e.keyCode === 13) {
                                handleAddKeySources();
                              }
                            }}
                            disabled={section === "user"}
                          />
                          {isError && i === keySources.length - 1 && (
                            <span className="text-danger">Can't be Empty</span>
                          )}
                          {section !== "user" && (
                            <div
                              className="m-2"
                              onClick={() => handleRemoveKeySources(i)}
                            >
                              <Cross className="text-danger h4" role="button" />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="bg-inner mt-2">
                      <div className="top">
                        <div className="top-start">
                          <p className="key">
                            Key Contributer User ID &gt; abc
                          </p>
                        </div>
                        {/* <div className="top-end">
                          <div>
                            <a href="">
                              <img
                                src="img/pencil.png"
                                className="pl-3 img-icon"
                              />
                            </a>
                          </div>
                          <div>
                            <a href="">
                              <img
                                src="img/checkbox.png"
                                className="pl-3 img-icon"
                              />
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    {section !== "user" && (
                      <div
                        className="text-right my-4"
                        role="button"
                        onClick={handleAddKeySources}
                      >
                        <span className="btn key-btn">
                          <span className="fa fa-plus-circle mr-2"></span>Add
                          Key Resources & Links
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
