import { fetchTags, postTag, deleteTag, patchTag } from "./api";
import { AppThunk } from "../../store";
import { setTag } from "./slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Tag } from "../../utils/types";

export const getTags = (): AppThunk => async (dispatch) => {
  try {
    const tags: Tag[] = await fetchTags();
    tags.forEach((tag) => dispatch(setTag(tag)));
  } catch (err) {
    console.error(err);
  }
};

export const createTag = createAsyncThunk(
  "tags/create",
  async (
    tag: { tagName: string; tagType: string; tagDescription: string },
    thunkAPI
  ) => {
    try {
      const newTag: Tag = await postTag(tag);
      thunkAPI.dispatch(setTag(newTag));
      return newTag;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const removeTag = createAsyncThunk(
  "tags/delete",
  async (id: string, thunkAPI) => {
    try {
      const response = await deleteTag(id);
      //no need to call api, as we're already deleting the tag from store
      // thunkAPI.dispatch<any>(getTags())
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editTag = createAsyncThunk(
  "tags/edit",
  async (
    tag: {
      id?: string;
      tagName: string;
      tagType: string;
      tagDescription: string;
    },
    thunkAPI
  ) => {
    try {
      const { id = "" } = tag;
      delete tag.id;
      const response = await patchTag(id, tag);
      thunkAPI.dispatch<any>(getTags());
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
