const terms = `<style>
[data-custom-class='body'],
[data-custom-class='body'] * {
  background: transparent !important;
}

[data-custom-class='title'],
[data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}

[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}

[data-custom-class='link'],
[data-custom-class='link'] * {
  color: #3030F1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}
</style>

<div data-custom-class="body">
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="title" style="line-height: 1.5;">
    <span class="block-component"><span style="font-size: 19px;"></span>
      <span class="question"><strong>TERMS OF SERVICE</strong></span>
      <span class="statement-end-if-in-editor"></span></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="subtitle" style="line-height: 1.5;"><strong>Last updated</strong>
    <span class="question"><strong>March 23, 2023</strong></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.1;"></br></div>
  <div class="MsoNormal" style="line-height: 115%;"></br></div>
  <div class="MsoNormal" style="line-height: 115%;"></br></div>
  <div style="line-height: 1.5;"><strong><span data-custom-class="heading_1">AGREEMENT TO OUR LEGAL
        TERMS</span></strong></div>
</div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" id="agreement" style="line-height: 1.5;"><a name="_6aa3gkhykvst"></a></div>
</div>
<div align="center" style="line-height: 1;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We are <span
        class="block-container question question-in-editor" data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
        data-type="question">Premium Intel, LLC, a New York limited liability company dba Wendwise</span>
      <span class="block-component"></span>, doing business as <span class="question">Wendwise</span>
      <span class="block-component"></span>
      <span class="statement-end-if-in-editor"></span> (<span
        class="block-component"></span>"<strong>Company</strong>,"
      "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>"<span class="statement-end-if-in-editor">
      </span>)<span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
            <span class="question">
              <span class="block-component"></span>
            </span><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
              <span class="block-component"></span>
            </span>, a company registered in<span class="block-component"></span>
            <span class="block-component"></span>
            <span class="block-component"></span> <span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
                <span class="question">New York</span>, <span class="question">United States</span>
              </span></span>
            <span class="statement-end-if-in-editor"></span>
            <span class="block-component"></span>
          </span></span></span></span>
    </span> at <span class="question">n/a</span>
    <span class="block-component"></span></span></span>, <span class="question">Pittsford</span></span></span><span
      style="font-size: 15px;"><span data-custom-class="body_text"><span style="font-size: 15px;"><span
            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
              <span class="block-component"></span>
              <span class="block-component"></span>, <span class="question">NY</span>
              <span class="statement-end-if-in-editor"></span>
              <span class="block-component"></span>
              <span class="block-component"></span>
              <span class="question">14534</span>
              <span class="statement-end-if-in-editor"></span>
            </span></span></span></span></span><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;"><span style="font-size:11.0pt;line-height:115%;
Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1;
mso-themetint:166;">
        <span class="else-block"></span>
      </span>
    </span></span></span>
    <span class="statement-end-if-in-editor">.</span></span>
    <span class="block-component"></span></span></span></span></span></span></span>
  </div>
</div>
<div align="center" style="line-height: 1;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We operate <span class="block-component"></span>the
      website <span class="question"><a href="https://www.wendwise.com" target="_blank"
          data-custom-class="link">https://www.wendwise.com</a></span> (the <span class="block-component"></span>
      "<strong>Site</strong>"<span class="statement-end-if-in-editor"></span>)<span class="block-component"></span>
      <span class="block-component"></span>, as well as any other related products and services that refer or link to
      these legal terms (the <span class="block-component"></span>"<strong>Legal Terms</strong>"<span
        class="statement-end-if-in-editor"></span>) (collectively, the <span class="block-component"></span>
      "<strong>Services</strong>"<span class="statement-end-if-in-editor"></span>).<span
        class="block-component"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
    <span class="question">Our mission is to enable industry-wide collaboration of secondary research on insurance
      companies, products and solutions. We strive to reduce costs and improve the organization of research and
      insights on public, non-confidential information that all insurers rely upon.

    </span>
  </div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
      <span class="statement-end-if-in-editor"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You can contact us by <span class="block-component">
      </span>phone at <span class="question">n/a</span>, email at <span class="question">neil@premiumintel.com</span>
      <span class="block-component"></span>,<span class="statement-end-if-in-editor"></span> or by mail to <span
        class="question">n/a</span>
      <span class="block-component"></span>, <span class="question">Pittsford</span>
      <span class="block-component"></span>
      <span class="block-component"></span>, <span class="question">NY</span>
      <span class="statement-end-if-in-editor"></span>
      <span class="block-component"></span>
      <span class="block-component"></span>
      <span class="question">14534</span>
      <span class="statement-end-if-in-editor"></span>
      <span class="block-component"></span>
      <span class="block-component">, </span>
      <span class="question">United States</span>
      <span class="statement-end-if-in-editor"></span>
      <span class="block-component"></span>.
    </span></div>
  <div class="MsoNormal" style="line-height: 1;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">These Legal Terms constitute a legally binding agreement
      made between you, whether personally or on behalf of an entity (<span class="block-component"></span>
      "<strong>you</strong>"<span class="statement-end-if-in-editor"></span>), and <span class="question">Premium
        Intel,
        LLC, a New York limited liability company dba Wendwise</span>, concerning your access to and use of the
      Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of
      these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
      USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.<span class="block-component"></span>
      <span class="block-component"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We will provide you with prior notice of any scheduled
      changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying
      you by <span class="question">neil@premiumintel.com</span>, as stated in the email message. By continuing to use
      the Services after the effective date of any changes, you agree to be bound by the modified terms.<span
        class="block-component"></span>
      <span class="statement-end-if-in-editor"></span>
    </span></div>
</div>
<div align="center" style="line-height: 1;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
    <span class="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
      <span data-type="conditional-block">
        <span data-type="body"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
            <span class="block-component"></span>The
            Services are intended for users who are at least 18 years old. Persons under the age
            of 18 are not permitted to use or register for the Services.
          </span></span>
      </span>
      <span data-type="conditional-block">
        <span class="block-component"></span>
      </span>
  </div>
  <div class="MsoNormal" style="line-height: 1;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">We recommend that you print a copy of
    these Legal Terms for your records.</div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong>TABLE OF CONTENTS</strong>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a href="#services"><span data-custom-class="link"><span
          style="font-size: 15px;"><span data-custom-class="body_text">1. OUR SERVICES</span></span></span></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#ip"><span
        data-custom-class="body_text">2. INTELLECTUAL PROPERTY RIGHTS</span></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#userreps"></a><a
      data-custom-class="link" href="#userreps"><span style="font-size: 15px;"><span data-custom-class="body_text">3.
          USER REPRESENTATIONS</span></span></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
        <span class="block-component"></span>
      </span></span> <a data-custom-class="link" href="#products"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#products"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#purchases"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#purchases"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>4. PURCHASES AND PAYMENT<span
            class="statement-end-if-in-editor"></span>
        </span></span></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
        <span class="block-component"></span>
      </span></span></div>
  <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
        <span class="block-component"></span>
      </span></span> <a data-custom-class="link" href="#free"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#free"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>5. FREE TRIAL<span class="statement-end-if-in-editor"></span>
        </span></span></a> <a data-custom-class="link" href="#cancel"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#cancel"><span
        style="font-size: 15px;"><span data-custom-class="body_text">6. CANCELLATION</span></span></a><span
      style="font-size: 15px;"><span data-custom-class="body_text">
        <span class="statement-end-if-in-editor"></span>
      </span></span> <a data-custom-class="link" href="#software"></a> <a data-custom-class="link"
      href="#software"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#software"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#prohibited"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#prohibited"><span
        style="font-size: 15px;"><span data-custom-class="body_text">7. PROHIBITED ACTIVITIES</span></span></a> <a
      data-custom-class="link" href="#ugc"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#ugc"><span
        style="font-size: 15px;"><span data-custom-class="body_text">8. USER GENERATED CONTRIBUTIONS</span></span></a>
    <a data-custom-class="link" href="#license"></a>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#license"><span
        style="font-size: 15px;"><span data-custom-class="body_text">9. CONTRIBUTION <span class="block-component">
          </span>LICENSE<span class="statement-end-if-in-editor"></span></span></span></a> <a data-custom-class="link"
      href="#reviews"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#reviews"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#mobile"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#mobile"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#socialmedia"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#socialmedia"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#thirdparty"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#thirdparty"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>10. THIRD-PARTY WEBSITES AND CONTENT<span
            class="statement-end-if-in-editor"></span>
        </span></span></a> <a data-custom-class="link" href="#advertisers"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#advertisers"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#sitemanage"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#sitemanage"><span
        style="font-size: 15px;"><span data-custom-class="body_text">11. SERVICES MANAGEMENT</span></span></a> <a
      data-custom-class="link" href="#ppyes"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#ppyes"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#ppno"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#ppno"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
        </span></span></a> <a data-custom-class="link" href="#dmca"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#dmca"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>
          <span class="block-component"></span>
          <span class="statement-end-if-in-editor"></span>
        </span></span></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
        <span class="block-component"></span>
        <span class="block-component"></span>
        <span class="block-component"></span>
      </span></span> <a data-custom-class="link" href="#terms"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#terms"><span
        style="font-size: 15px;"><span data-custom-class="body_text">12. TERM AND TERMINATION</span></span></a> <a
      data-custom-class="link" href="#modifications"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#modifications"><span
        style="font-size: 15px;"><span data-custom-class="body_text">13. MODIFICATIONS AND
          INTERRUPTIONS</span></span></a> <a data-custom-class="link" href="#law"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#law"><span
        style="font-size: 15px;"><span data-custom-class="body_text">14. GOVERNING LAW</span></span></a> <a
      data-custom-class="link" href="#disputes"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#disputes"><span
        style="font-size: 15px;"><span data-custom-class="body_text">15. DISPUTE RESOLUTION</span></span></a> <a
      data-custom-class="link" href="#corrections"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#corrections"><span
        style="font-size: 15px;"><span data-custom-class="body_text">16. CORRECTIONS</span></span></a> <a
      data-custom-class="link" href="#disclaimer"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#disclaimer"><span
        style="font-size: 15px;"><span data-custom-class="body_text">17. DISCLAIMER</span></span></a> <a
      data-custom-class="link" href="#liability"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#liability"><span
        style="font-size: 15px;"><span data-custom-class="body_text">18. LIMITATIONS OF LIABILITY</span></span></a> <a
      data-custom-class="link" href="#indemnification"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#indemnification"><span
        style="font-size: 15px;"><span data-custom-class="body_text">19. INDEMNIFICATION</span></span></a> <a
      data-custom-class="link" href="#userdata"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#userdata"><span
        style="font-size: 15px;"><span data-custom-class="body_text">20. USER DATA</span></span></a> <a
      data-custom-class="link" href="#electronic"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#electronic"><span
        style="font-size: 15px;"><span data-custom-class="body_text">21. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
          SIGNATURES</span></span></a> <a data-custom-class="link" href="#california"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#california"><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <span class="block-component"></span>22. CALIFORNIA USERS AND RESIDENTS<span
            class="statement-end-if-in-editor"></span>
        </span></span></a> <a data-custom-class="link" href="#misc"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#misc"><span
        style="font-size: 15px;"><span data-custom-class="body_text">23. MISCELLANEOUS</span></span></a> <a
      data-custom-class="link" href="#contact"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
        style="font-size: 15px;"><span data-custom-class="body_text">24. CONTACT US</span></span></a></div>
</div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a name="_b6y29mp52qvx"></a></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="heading_1" id="services" style="line-height: 1.5;"><strong><span
        style="font-size: 19px;">1. OUR SERVICES</span></strong></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">The
      information provided when using the Services is not intended for distribution to or use by any person or entity
      in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which
      would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons
      who choose to access the Services from other locations do so on their own initiative and are solely responsible
      for compliance with local laws, if and to the extent local laws are applicable.<span class="block-component">
      </span></span>
    <span class="block-component"><span style="font-size: 15px;"></span></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">The
      Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
      Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions
      would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would
      violate the Gramm-Leach-Bliley Act (GLBA).<span class="block-component"></span>
      <span class="statement-end-if-in-editor"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
</div>
<div align="center" data-custom-class="heading_1" style="text-align: left; line-height: 1.5;"><strong><span id="ip"
      style="font-size: 19px;">2. INTELLECTUAL PROPERTY RIGHTS</span></strong></div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Our intellectual
      property</strong></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We are the owner or the licensee of all intellectual
      property rights in our Services, including all source code, databases, functionality, software, website designs,
      audio, video, text, photographs, and graphics in the Services (collectively, the <span class="block-component">
      </span>"Content"<span class="statement-end-if-in-editor"></span>), as well as the trademarks, service marks, and
      logos contained therein (the <span class="block-component"></span>"Marks"<span
        class="statement-end-if-in-editor">
      </span>).</span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Our Content and Marks are protected by copyright and
      trademark laws (and various
      other intellectual property rights and unfair competition laws) and treaties in the United
      States and around the world.</span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">The Content and Marks are provided in or through the
      Services <span class="block-component"></span>"AS IS"<span class="statement-end-if-in-editor"></span> for your
      <span class="block-component"></span>internal business purpose<span class="else-block"></span> only.</span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Your use of our
      Services</strong></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
      style="font-size: 15px;">Subject to your compliance with these Legal Terms, including the <span
        class="block-component"></span>"<span class="statement-end-if-in-editor"></span></span><a
      data-custom-class="link" href="#prohibited"><span style="font-size: 15px;">PROHIBITED ACTIVITIES</span></a><span
      style="font-size: 15px;">
      <span class="block-component"></span>"<span class="statement-end-if-in-editor"></span> section below, we grant
      you a
      non-exclusive, non-transferable, revocable <span class="block-component"></span>license<span
        class="statement-end-if-in-editor"></span> to:
    </span></div>
  <ul>
    <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 15px;">access the Services; and</span></li>
    <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 15px;">download or print a copy of any portion of the Content to which you have properly
        gained access.</span></li>
  </ul>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">solely for your <span class="block-component"></span>
      internal business purpose<span class="else-block"></span>.</span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Except as set out in this section or elsewhere in our
      Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced,
      aggregated, republished, uploaded, posted, publicly displayed, encoded,
      translated, transmitted, distributed, sold, licensed, or otherwise exploited
      for any commercial purpose whatsoever, without our express prior written
      permission.</span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">If you wish to make any use of the Services, Content, or
      Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to:
      <span class="question">neil@premiumintel.com</span>. If we ever grant you the permission to post, reproduce, or
      publicly display any part of our Services or Content, you must identify us as the owners or licensors of the
      Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on
      posting, reproducing, or displaying our Content.
    </span></div>
</div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We reserve all rights not expressly granted to you in
      and to the Services, Content, and Marks.</span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Any breach of these Intellectual Property Rights will
      constitute a material breach of our Legal Terms and your right to use our Services will terminate
      immediately.</span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><strong>Your submissions<span class="block-component">
      </strong></span></span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">Please
      review this section and the <span class="block-component"></span>"<span
        class="statement-end-if-in-editor"></span><a data-custom-class="link" href="#prohibited">PROHIBITED
        ACTIVITIES</a>
      <span class="block-component"></span>"<span class="statement-end-if-in-editor"></span> section carefully prior
      to
      using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload
      any content through the Services.
    </span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
      style="font-size: 15px;"><strong>Submissions:</strong> By directly sending us any question, comment, suggestion,
      idea, feedback, or other information about the Services (<span class="block-component"></span>"Submissions"<span
        class="statement-end-if-in-editor"></span>), you agree to assign to us all intellectual property rights in
      such
      Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and
      dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
      <span class="block-component"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
      style="font-size: 15px;"><strong>You are responsible for what you post or upload:</strong> By sending us
      Submissions<span class="block-component"></span> through any part of the Services<span class="block-component">
      </span> you:</span></div>
  <ul>
    <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 15px;">confirm that you have read and agree with our <span class="block-component"></span>"
        <span class="statement-end-if-in-editor"></span>
      </span><a data-custom-class="link" href="#prohibited"><span style="font-size: 15px;">PROHIBITED
          ACTIVITIES</span></a><span style="font-size: 15px;">
        <span class="block-component"></span>"<span class="statement-end-if-in-editor"></span> and will not post,
        send,
        publish, upload, or transmit through the Services any Submission<span class="block-component"></span> that is
        illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to
        any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
      </span></li>
    <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">to
        the extent permissible by applicable law, waive any and all moral rights to any such Submission<span
          class="block-component"></span>;</span></li>
    <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 15px;">warrant that any such Submission<span class="block-component"></span> are original to
        you or that you have the necessary rights and <span class="block-component"></span>licenses<span
          class="statement-end-if-in-editor"></span> to submit such Submissions<span class="block-component"></span>
        and
        that you have full authority to grant us the above-mentioned rights in relation to your Submissions<span
          class="block-component"></span>; and</span></li>
    <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 15px;">warrant and represent that your Submissions<span class="block-component"></span> do
        not
        constitute confidential information.</span></li>
  </ul>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">You are solely responsible for your
    Submissions<span class="block-component"></span> and you expressly agree to reimburse us for any and all losses
    that
    we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or
    (c) applicable law.<span class="block-component"></span>
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
</div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="heading_1" id="userreps" style="line-height: 1.5;"><a
      name="_5hg7kgyv9l8z"></a><strong><span
        style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
            style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">3.</span></strong></span></strong></span>
        </strong>USER REPRESENTATIONS</span></strong></div>
</div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">By using the Services, you represent and warrant
      that:</span>
    <span class="block-container if" data-type="if" id="d2d82ca8-275f-3f86-8149-8a5ef8054af6">
      <span data-type="conditional-block">
        <span class="block-component" data-record-question-key="user_account_option" data-type="statement"></span>
      </span><span style="color: rgb(89, 89, 89); font-size: 11pt;">(</span><span
        style="color: rgb(89, 89, 89); font-size: 14.6667px;">1</span><span
        style="color: rgb(89, 89, 89); font-size: 11pt;">) you have the legal capacity and you agree to comply with
        these Legal Terms;</span>
      <span class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
        <span data-type="conditional-block">
          <span class="block-component" data-record-question-key="user_u13_option" data-type="statement"></span>
        </span><span style="color: rgb(89, 89, 89); font-size: 11pt;">(</span><span
          style="color: rgb(89, 89, 89); font-size: 14.6667px;">2</span><span
          style="color: rgb(89, 89, 89); font-size: 11pt;">) you are not a
          minor in the jurisdiction in which you reside<span class="block-container if" data-type="if"
            id="76948fab-ec9e-266a-bb91-948929c050c9">
            <span data-type="conditional-block">
              <span class="block-component" data-record-question-key="user_o18_option" data-type="statement"></span>
            </span>; (</span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">3</span><span
            style="color: rgb(89, 89, 89); font-size: 11pt;">) you will not access the Services through automated or
            non-human means, whether through a bot, script or
            otherwise; (</span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">4</span><span
            style="color: rgb(89, 89, 89); font-size: 11pt;">) you will not use the Services for any illegal or <span
              class="block-component"></span>unauthorized<span class="statement-end-if-in-editor"></span> purpose; and
            (</span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;">5</span><span
            style="color: rgb(89, 89, 89); font-size: 11pt;">) your use of the Services will not violate any
            applicable
            law or regulation.</span><span style="color: rgb(89, 89, 89); font-size: 14.6667px;"></span>
  </div>
</div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" style="text-align: justify; line-height: 115%;">
    <div class="MsoNormal" style="line-height: 17.25px;">
      <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
          style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">If you provide any information that
          is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account
          and refuse any and all current or future use of the Services (or any portion thereof).</span></div>
      <div class="MsoNormal" style="line-height: 1.1; text-align: left;">
        <span class="block-component"></span></span>
      </div>
      </span>
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"><span style="font-size: 15px;"></span></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"><span style="font-size: 15px;"></span></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
  </div>
</div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="heading_1" id="purchases" style="line-height: 1.5;"><a
      name="_ynub0jdx8pob"></a><strong><span
        style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
            style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">4.</span></strong></span></strong></span>
        </strong>PURCHASES AND PAYMENT</span></strong></div>
</div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We accept the following forms of payment:</span></div>
  <div class="MsoNormal" style="text-align:justify;line-height:115%;">
    <div class="MsoNormal" style="text-align: left; line-height: 1;"></br></div>
  </div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; margin-left: 20px;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">
      <span class="forloop-component"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"></br></span></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You agree to provide current, complete, and accurate
      purchase and account information for all purchases made via the Services. You further agree to promptly update
      account and payment information, including email address, payment method, and payment card expiration date, so
      that we can complete your transactions and contact you as needed. Sales tax will be added to the price of
      purchases as deemed required by us. We may change prices at any time. All payments shall be </span><span
      style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">in <span
        class="question">US dollars</span>.</span></div>
</div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You agree to pay all charges at the prices then in
      effect for your purchases and any applicable shipping fees, and you <span
        class="block-component"></span>authorize
      <span class="statement-end-if-in-editor"></span> us to charge your chosen payment provider for any such amounts
      upon placing your order. <span class="block-container if" data-type="if"
        id="9c0216a1-d094-fd73-a062-9615dc795ffc">
        <span data-type="conditional-block">
          <span class="block-component" data-record-question-key="recurring_charge_option"
            data-type="statement"></span>
          <span data-type="body">If your order is subject to recurring charges, then you consent to our charging your
            payment method on a recurring basis without requiring your prior approval for each recurring charge, until
            such time as you cancel the applicable order. </span>
        </span>
        <span class="statement-end-if-in-editor" data-type="close"></span>
      </span>We reserve the right to correct
      any errors or mistakes in pricing, even if we have already requested or
      received payment.
    </span></div>
</div>
<div align="center" style="line-height: 1.5;"></br></div>
<div align="center" style="text-align: left;">
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We reserve the right to refuse any order placed through
      the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or
      per order. These restrictions may include orders placed by or under the same customer account, the same payment
      method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit
      orders that, in our sole <span class="block-component"></span>judgment<span class="statement-end-if-in-editor">
      </span>, appear to be placed by dealers, resellers, or distributors.</span><span
      style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
      <span data-type="conditional-block" style="color: rgb(10, 54, 90); text-align: left;">
        <span class="block-component" data-record-question-key="return_option" data-type="statement"
          style="font-size: 15px;"></span>
      </span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"><span style="font-size: 15px;"></span></span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"><span style="font-size: 15px;"></span></span><span style="font-size: 15px;">
      <span class="block-component"></span>
    </span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="heading_1" id="free" style="line-height: 1.5;"><span
      style="font-size: 19px;"><strong>5. FREE TRIAL</strong></span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">We
      offer a <span class="question">30</span>-day free trial to new users who register with the Services. <span
        class="block-component"></span>
      <span class="question">The account will be charged according to the user's chosen subscription</span> at the end
      of the free trial.<span class="else-block"></span>
      <span class="statement-end-if-in-editor"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="heading_1" id="cancel" style="line-height: 1.5;"><span
      style="font-size: 19px;"><strong>6. CANCELLATION</strong></span></div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
    <span class="block-component"><span style="font-size: 15px;"></span></span><span style="font-size: 15px;">All
      purchases are non-refundable. <span class="statement-end-if-in-editor"></span>
      <span class="block-component"></span>You can cancel your subscription at any time by contacting us using the
      contact information provided below.<span class="else-block"></span> Your cancellation will take effect at the
      end
      of the current paid term.
    </span>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">If you
      are unsatisfied with our Services, please email us at <span class="question">neil@premiumintel.com</span>
      <span class="block-component"></span> or call us at <span class="question">n/a</span>
      <span class="statement-end-if-in-editor"></span>.<span class="statement-end-if-in-editor"></span>
    </span></div>
  <div class="MsoNormal" style="line-height: 1.5;">
    <span class="block-component"></span>
  </div>
  <div class="MsoNormal" style="text-align: justify; line-height: 1.5;"><span
      style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
      <span data-type="conditional-block" style="color: rgb(10, 54, 90); text-align: left;">
        <span data-type="body">
          <div class="MsoNormal" style="font-size: 15px; line-height: 1.5;"></br></div>
        </span>
      </span>
    </span>
    <div class="MsoNormal" data-custom-class="heading_1" id="prohibited" style="text-align: left; line-height: 1.5;">
      <strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">7.</span></strong></span></strong></span>
          </strong>PROHIBITED ACTIVITIES</span></strong>
    </div>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" style="text-align: justify; line-height: 1;">
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">You may not access or use the
        Services for any purpose other than that for which we make the Services available. The Services may not be
        used in connection with any commercial <span class="block-component"></span>endeavors<span
          class="statement-end-if-in-editor"></span> except those that are specifically endorsed or approved by
        us.</span></div>
  </div>
  <div class="MsoNormal" style="line-height: 1.5;"></br></div>
  <div class="MsoNormal" style="text-align: justify; line-height: 1;">
    <div class="MsoNormal" style="line-height: 17.25px;">
      <div class="MsoNormal" style="line-height: 1.1;">
        <div class="MsoNormal" style="line-height: 17.25px;">
          <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">As a user of the Services, you
              agree not to:</span></div>
        </div>
        <ul>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                style="font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Systematically
                retrieve data or other content from the Services to create or compile, directly or indirectly, a
                collection, compilation, database, or directory without written permission from us.</span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Trick,
                      defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                      information such as user passwords.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Circumvent,
                      disable, or otherwise interfere with security-related features of the Services, including
                      features that prevent or restrict the use or copying of any Content or enforce limitations on
                      the use of the Services and/or the Content contained therein.</span></span></span></span></span>
          </li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Disparage,
                      tarnish, or otherwise harm, in our opinion, us and/or the
                      Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                      any information obtained from the Services in order to harass, abuse, or harm another
                      person.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Make
                      improper use of our support services or submit false reports of abuse or
                      misconduct.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                      the Services in a manner inconsistent with any applicable laws or
                      regulations.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Engage
                      in <span class="block-component"></span>unauthorized<span
                        class="statement-end-if-in-editor"></span>
                      framing of or linking to the Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload
                      or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material,
                      including excessive use of capital letters and spamming (continuous posting of repetitive text),
                      that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies,
                      impairs, disrupts, alters, or interferes with the use, features, functions, operation, or
                      maintenance of the Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Engage
                      in any automated use of the system, such as using scripts to send comments or messages, or using
                      any data mining, robots, or similar data gathering and extraction
                      tools.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Delete
                      the copyright or other proprietary rights notice from any
                      Content.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Attempt
                      to impersonate another user or person or use the username of another
                      user.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Upload
                      or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                      information collection or transmission mechanism, including without limitation, clear graphics
                      interchange formats (<span class="block-component"></span>"gifs"<span
                        class="statement-end-if-in-editor"></span>), 1×1 pixels, web bugs, cookies, or other similar
                      devices (sometimes referred to as <span class="block-component"></span>"spyware" or "passive
                      collection mechanisms" or "pcms"<span class="statement-end-if-in-editor"></span>
                      ).</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Interfere
                      with, disrupt, or create an undue burden on the Services or the networks or services connected
                      to the Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Harass,
                      annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion
                      of the Services to you.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Attempt
                      to bypass any measures of the Services designed to prevent or restrict access to the Services,
                      or any portion of the Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Copy
                      or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or
                      other code.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Except
                      as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the
                      software comprising or in any way making up a part of the
                      Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Except
                      as may be the result of standard search engine or Internet browser usage, use, launch, develop,
                      or distribute any automated system, including without limitation, any spider, robot, cheat
                      utility, scraper, or offline reader that accesses the Services, or use or launch any <span
                        class="block-component"></span>unauthorized<span class="statement-end-if-in-editor"></span>
                      script or other software.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                      a buying agent or purchasing agent to make purchases on the
                      Services.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Make
                      any <span class="block-component"></span>unauthorized<span class="statement-end-if-in-editor">
                      </span> use of the Services, including collecting usernames and/or email addresses of users by
                      electronic or other means for the purpose of sending unsolicited email, or creating user
                      accounts by automated means or under false <span class="block-component"></span>pretenses<span
                        class="statement-end-if-in-editor"></span>.</span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 15px;"><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                  style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);"><span
                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"><span
                      style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">Use
                      the Services as part of any effort to compete with us or otherwise use the Services and/or the
                      Content for any revenue-generating <span class="block-component"></span>endeavor<span
                        class="statement-end-if-in-editor"></span> or commercial enterprise.</span><span
                      style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-style: initial; text-decoration-color: initial; color: rgb(89, 89, 89);">
                      <span class="forloop-component"></span>
                    </span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="question">Use the Services to advertise or offer to sell goods and services.</span>
              <span class="forloop-component"></span>
            </span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="question">Sell or otherwise transfer your profile.</span>
              <span class="forloop-component"></span>
            </span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="question">Download and/or reproduce content</span>
              <span class="forloop-component"></span>
            </span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="question">Reverse Engineer platform and content</span>
              <span class="forloop-component"></span>
            </span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="question">Cannot disclose or share product with current or potential competitor</span>
              <span class="forloop-component"></span>
            </span></span></span></span></span></li>
          <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="question">Cannot disclose platform or product with any third party</span>
              <span class="forloop-component"></span>
            </span></li>
        </ul>
      </div>
      <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    </div>
    <div class="MsoNormal" style="line-height: 17.25px;">
      <div class="MsoNormal" style="line-height: 1;">
        <span class="block-container if" data-type="if" style="text-align: left;">
          <span data-type="conditional-block">
            <span data-type="body">
              <div class="MsoNormal" data-custom-class="heading_1" id="ugc" style="line-height: 1.5;"><strong><span
                    style="line-height: 24.5333px; font-size: 19px;"><strong><span
                        style="line-height: 24.5333px; font-size: 19px;"><strong><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                                style="line-height: 115%; font-family: Arial; font-size: 19px;">8.</span></strong></span></strong></span>
                    </strong>USER GENERATED CONTRIBUTIONS</span></strong></div>
            </span>
          </span>
        </span>
      </div>
      <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
      <div class="MsoNormal" style="line-height: 1;">
        <span class="block-container if" data-type="if" style="text-align: left;">
          <span data-type="conditional-block">
            <span data-type="body">
              <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
                  style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
                  <span class="block-container if" data-type="if" id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
                    style="text-align: left;">
                    <span data-type="conditional-block">
                      <span class="block-component" data-record-question-key="user_post_content_option"
                        data-type="statement"></span><span
                        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">The Services does not
                        offer users to submit or post content.<span class="block-component">
                          <span class="block-component"></span></div>
            </span>
          </span>
        </span>
      </div>
      <div class="MsoNormal" style="line-height: 1.5;"></br></div>
      <div class="MsoNormal" style="text-align: justify; line-height: 1;">
        <span class="block-container if" data-type="if" style="text-align: left;">
          <span data-type="conditional-block">
            <span data-type="body">
              <div class="MsoNormal" data-custom-class="heading_1" id="license" style="line-height: 1.5;">
                <strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span
                        style="line-height: 24.5333px; font-size: 19px;"><strong><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                                style="line-height: 115%; font-family: Arial; font-size: 19px;">9.</span></strong></span></strong></span>
                    </strong>CONTRIBUTION <span class="block-component"></span>LICENSE<span
                      class="statement-end-if-in-editor"></span></span></strong>
              </div>
            </span>
          </span>
        </span>
      </div>
      <div class="MsoNormal" style="line-height: 1.5;"></br></div>
      <div class="MsoNormal" style="line-height: 1;">
        <span class="block-container if" data-type="if" id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
          style="text-align: left;">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="user_post_content_option" data-type="statement">
            </span></span>
        </span>
        </span>
        </span>
      </div>
      <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">You and Services agree that we
            may access, store, process, and use any information and personal data that you provide<span
              class="block-component"></span> and your choices (including settings).</span></span></div>
      <div class="MsoNormal" style="line-height: 1.5;"></br></div>
      <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">By submitting suggestions or other
          feedback regarding the Services, you agree that we can use and share such feedback for any purpose without
          compensation to you.<span class="block-component"></span></span></div>
      <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"></br></div>
      <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"><span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="block-container if" data-type="if" style="text-align: left;">
                <span class="statement-end-if-in-editor" data-type="close"></span>
              </span>
            </span></span></span></div>
      <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
        <span class="block-container if" data-type="if" style="text-align: left;">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="review_option" data-type="statement"></span>
          </span></span></span></span>
      </div>
      <div class="MsoNormal" style="line-height: 1.5;">
        <span class="block-container if" data-type="if" style="text-align: left;">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="mobile_app_option" data-type="statement"></span>
          </span></span></span></span>
      </div>
      <div class="MsoNormal" style="line-height: 1.5;">
        <span class="block-container if" data-type="if" style="text-align: left;">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="socialnetwork_link_option" data-type="statement">
            </span>
      </div>
      </span>
      </span>
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5;">
      <span class="block-container if" data-type="if" style="text-align: left;">
        <span data-type="conditional-block">
          <span class="block-component" data-record-question-key="3rd_party_option" data-type="statement"></span>
        </span>
      </span>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="thirdparty" style="line-height: 1.5;"><strong><span
          style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;">10.</span></strong></span></strong>
        </span>THIRD-PARTY WEBSITES AND CONTENT</strong></div>
    <div class="MsoNormal" style="line-height: 1.5;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">The Services may contain (or you may
        be sent via the <span class="block-component"></span>Site<span class="block-component"></span>) links to other
        websites (<span class="block-component"></span>"Third-Party Websites"<span class="statement-end-if-in-editor">
        </span>) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video,
        information,
        applications, software, and other content or items belonging to or originating from third parties (<span
          class="block-component"></span>"Third-Party Content"<span class="statement-end-if-in-editor"></span>). Such
        <span style="font-size: 14.6667px;">Third-Party</span> Websites and <span
          style="font-size: 14.6667px;">Third-Party</span> Content are not investigated, monitored, or checked for
        accuracy, appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites
        accessed through the Services or any <span style="font-size: 14.6667px;">Third-Party</span> Content posted on,
        available through, or installed from the Services, including the content, accuracy, offensiveness, opinions,
        reliability, privacy practices, or other policies of or contained in the <span
          style="font-size: 14.6667px;">Third-Party</span> Websites or the <span
          style="font-size: 14.6667px;">Third-Party</span> Content. Inclusion of, linking to, or permitting the use or
        installation of any <span style="font-size: 14.6667px;">Third-Party</span> Websites or any <span
          style="font-size: 14.6667px;">Third-Party </span>Content does not imply approval or endorsement thereof by
        us. If you decide to leave the Services and access the <span style="font-size: 14.6667px;">Third-Party</span>
        Websites or to use or install any <span style="font-size: 14.6667px;">Third-Party</span> Content, you do so at
        your own risk, and you should be aware these Legal Terms no longer govern. You should review the applicable
        terms and policies, including privacy and data gathering practices, of any website to which you navigate from
        the Services or relating to any applications you use or install from the Services. Any purchases you make
        through <span style="font-size: 14.6667px;">Third-Party</span> Websites will be through other websites and
        from other companies, and we take no responsibility whatsoever in relation to such purchases which are
        exclusively between you and the applicable third party. You agree and acknowledge that we do not endorse the
        products or services offered on <span style="font-size: 14.6667px;">Third-Party</span> Websites and you shall
        hold us blameless from any harm caused by your purchase of such products or services. Additionally, you shall
        hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way
        from any <span style="font-size: 14.6667px;">Third-Party</span> Content or any contact with <span
          style="font-size: 14.6667px;">Third-Party</span> Websites.</span></div>
    <div class="MsoNormal" style="line-height: 1.5;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5;">
      <span class="block-container if" data-type="if" style="text-align: left;">
        <span class="statement-end-if-in-editor" data-type="close"></span>
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5;">
      <span class="block-container if" data-type="if" style="text-align: left;">
        <span data-type="conditional-block">
          <span class="block-component" data-record-question-key="advertiser_option" data-type="statement"></span>
        </span>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="sitemanage" style="line-height: 1.5;"><strong><span
          style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">11.</span></strong></span></strong></span>
          </strong>SERVICES MANAGEMENT</span></strong></div>
    <div class="MsoNormal" style="line-height: 1.5;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">We reserve the right, but not the
      obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action
      against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation,
      reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse,
      restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your
      Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability,
      to remove from the Services or otherwise disable all files and content that are excessive in size or are in any
      way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights
      and property and to facilitate the proper functioning of the Services.</div>
    <div class="MsoNormal" style="line-height: 1.5;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5;">
      <span class="block-container if" data-type="if" style="text-align: left;">
        <span data-type="conditional-block">
          <span class="block-component" data-record-question-key="privacy_policy_option" data-type="statement"></span>
        </span>
        <span class="block-container if" data-type="if">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="privacy_policy_followup" data-type="statement"
              style="font-size: 14.6667px;"></span></span>
        </span>
      </span></span>
      </span>
      </span>
      </span></span></span>
      </span></span></span></span></span>
      </span></span></span>
      </span>
      </span></span></span>
      </span></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5;">
      <span class="block-container if" data-type="if" style="text-align: left;">
        <span data-type="conditional-block">
          <span class="block-component" data-record-question-key="copyright_agent_option" data-type="statement">
            <span class="block-component"></span>
            <span class="block-component"></span>
          </span>
          <span class="block-container if" data-type="if" style="text-align: left;">
            <span class="statement-end-if-in-editor" data-type="close"></span>
          </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"></span>
      <span class="block-container if" data-type="if" style="text-align: left;">
        <span class="statement-end-if-in-editor" data-type="close">
          <span class="block-component"></span>
        </span>
        <span class="block-component"></span>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="terms" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">12.</span></strong></span></strong></span>
          </strong>TERM AND TERMINATION</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">These Legal Terms shall remain in
        full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS,
        WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
        SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
        WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR
        OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE
        <span class="block-container if" data-type="if" id="a6e121c2-36b4-5066-bf9f-a0a33512e768">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="user_account_option" data-type="statement"></span>
          </span>ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">If we terminate or suspend your
        account for any reason, you are prohibited from registering and creating a new account under your name, a fake
        or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In
        addition to terminating or suspending your account, we reserve the right to take appropriate legal action,
        including without limitation pursuing civil, criminal, and injunctive redress.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="modifications"
      style="line-height: 1.5; text-align: left;"><strong><span
          style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">13.</span></strong></span></strong></span>
          </strong>MODIFICATIONS AND INTERRUPTIONS</span></strong></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">We reserve the right to change,
        modify, or remove the contents of the Services at any time or for any reason at our sole discretion without
        notice. However, we have no obligation to update any information on our Services.<span
          class="block-component">
        </span> We will not be liable to you or any third party for any modification, price change, suspension, or
        discontinuance of the Services.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">We cannot guarantee the Services will
        be available at all times. We may experience hardware, software, or other problems or need to perform
        maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to
        change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
        caused by your inability to access or use the Services during any downtime or discontinuance of the Services.
        Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to
        supply any corrections, updates, or releases in connection therewith.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="law" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">14.</span></strong></span></strong></span>
          </strong>GOVERNING LAW</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
        <span class="block-component"></span>
      </span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">These Legal Terms and your use of the
        Services are governed by and construed in accordance with the laws of <span class="block-container if"
          data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="governing_law" data-type="statement"></span>
            <span data-type="body">the State of <span class="block-container question question-in-editor"
                data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3" data-type="question">New York</span>
            </span>
          </span>
          <span class="statement-end-if-in-editor" data-type="close"></span>
        </span> applicable to agreements made and to be entirely performed within<span class="block-container if"
          data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b" style="font-size: 14.6667px;">
          <span data-type="conditional-block"> <span
              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);">
              <span class="block-container if" data-type="if" id="b86653c1-52f0-c88c-a218-e300b912dd6b">
                <span data-type="conditional-block">
                  <span class="block-component" data-record-question-key="governing_law" data-type="statement"></span>
                  <span data-type="body">the State of <span class="block-container question question-in-editor"
                      data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3" data-type="question">New York</span>
                  </span>
                </span>
                <span class="statement-end-if-in-editor" data-type="close"></span>
              </span><span style="font-size: 14.6667px;">, </span>without regard to its conflict of law
              principles.<span class="block-component"></span>
            </span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="disputes" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">15.</span></strong></span></strong></span>
          </strong>DISPUTE RESOLUTION</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"></span>
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"></span>
    </div>
    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;"><strong>Binding
        Arbitration</strong></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
      <span class="block-component"><span style="font-size: 15px;"></span></span><span style="font-size: 15px;">If the
        Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except those Disputes
        expressly excluded below) will be finally and exclusively resolved by binding arbitration. YOU UNDERSTAND THAT
        WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall
        be commenced and conducted under the Commercial Arbitration Rules of the American Arbitration Association (
        <span class="block-component"></span>"AAA"<span class="statement-end-if-in-editor"></span>) and, where
        appropriate, the AAA’s Supplementary Procedures for Consumer Related Disputes (<span class="block-component">
        </span>"AAA Consumer Rules"<span class="statement-end-if-in-editor"></span>), both of which are available at
        the
        <span style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);"><a data-custom-class="link"
            href="http://www.adr.org" rel="noopener noreferrer" target="_blank">American Arbitration Association (AAA)
            website</a></span>. Your arbitration fees and your share of arbitrator compensation shall be governed by
        the AAA Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. <span
          class="block-component"></span>The arbitration may be conducted in person, through the submission of
        documents, by phone, or online. The arbitrator will make a decision in writing, but need not provide a
        statement of reasons unless requested by either Party. The arbitrator must follow applicable law, and any
        award may be challenged if the arbitrator fails to do so. Except where otherwise required by the applicable
        AAA rules or applicable law, the arbitration will take place in <span class="block-component"></span>
        <span class="question">Monroe</span>, <span class="statement-end-if-in-editor"></span>
        <span class="block-component"></span>
        <span class="question">New York</span>
        <span class="statement-end-if-in-editor"></span>. Except as otherwise provided herein, the Parties may
        litigate
        in court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter
        <span class="block-component"></span>judgment<span class="statement-end-if-in-editor"></span> on the award
        entered
        by the arbitrator.
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 15px;">If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
        shall be commenced or prosecuted in the</span>
      <span class="block-component" style="font-size: 15px;"></span><span style="font-size: 15px;"> state and federal
        courts</span>
      <span class="statement-end-if-in-editor" style="font-size: 15px;"></span><span style="font-size: 15px;"> located
        in</span>
      <span class="block-component" style="font-size: 15px;"></span>
      <span class="question">Monroe</span><span style="font-size: 15px;">,</span>
      <span class="statement-end-if-in-editor" style="font-size: 15px;"></span>
      <span class="block-component" style="font-size: 15px;"></span>
      <span class="question" style="font-size: 15px;">New York</span>
      <span class="statement-end-if-in-editor" style="font-size: 15px;"></span><span style="font-size: 15px;">, and
        the
        Parties hereby consent to, and waive all <span class="block-component"></span>defenses<span
          class="statement-end-if-in-editor"></span> of lack of personal jurisdiction, and forum non conveniens with
        respect to venue and jurisdiction in such<span class="block-component"></span> state and federal courts<span
          class="statement-end-if-in-editor"></span>. Application of the United Nations Convention on Contracts for
        the
        International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded from
        these Legal Terms.</span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 15px;">
        <span class="block-component"></span>If this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or
        unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed
        for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.<span
          class="block-component"></span>
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
      <strong>Restrictions</strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">The Parties agree
      that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent
      permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or
      authority for any Dispute to be arbitrated on a class-action basis or to <span class="block-component"></span>
      utilize<span class="statement-end-if-in-editor"></span> class action procedures; and (c) there is no right or
      authority for any Dispute to be brought in a purported representative capacity on behalf of the general public
      or any other persons.</div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
      <span class="block-component"></span><strong>Exceptions to Arbitration</strong>
      <span class="else-block"></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
      <span class="block-component"></span>The Parties agree that the following Disputes are not subject to the above
      provisions concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the
      validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from,
      allegations of theft, piracy, invasion of privacy, or <span class="block-component"></span>unauthorized<span
        class="statement-end-if-in-editor"></span> use; and (c) any claim for injunctive relief. If this provision is
      found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that
      portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of
      competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the
      personal jurisdiction of that court.<span class="else-block"></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="statement-end-if-in-editor">
        <span class="statement-end-if-in-editor"></span>
      </span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="corrections" style="line-height: 1.5; text-align: left;">
      <strong><span style="font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">16.</span></strong></span></strong></span>
          </strong>CORRECTIONS</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">There may be
      information on the Services that contains typographical errors, inaccuracies, or omissions, including
      descriptions, pricing, availability, and various other information. We reserve the right to correct any errors,
      inaccuracies, or omissions and to change or update the information on the Services at any time, without prior
      notice.</div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="disclaimer" style="line-height: 1.5; text-align: left;">
      <span style="font-size: 19px; color: rgb(0, 0, 0);"><strong><span
            style="line-height: 24.5333px; font-size: 19px;"><strong><span
                style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;">17.</span></strong></span></strong></span>
          DISCLAIMER</strong></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
        BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY
        LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
        SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
        ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
        (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
        SERVICES, (3) ANY <span class="block-component"></span>UNAUTHORIZED<span class="statement-end-if-in-editor">
        </span> ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY
        BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD
        PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
        INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
        SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
        APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
        BEST <span class="block-component"></span>JUDGMENT<span class="statement-end-if-in-editor"></span> AND
        EXERCISE
        CAUTION WHERE APPROPRIATE.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="liability" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">18.</span></strong></span></strong></span>
          </strong>LIMITATIONS OF LIABILITY</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><span data-custom-class="body_text">IN NO EVENT WILL WE
          OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
          LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.</span>
        <span class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943"><span
            data-custom-class="body_text">
            <span data-type="conditional-block">
              <span class="block-component" data-record-question-key="limitations_liability_option"
                data-type="statement"></span>
              <span data-type="body">NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU
                FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO
                <span class="block-container if" data-type="if" id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                  <span data-type="conditional-block">
                    <span class="block-component" data-record-question-key="limitations_liability_option"
                      data-type="statement"></span>
                    <span data-type="body">THE
                      AMOUNT PAID, IF ANY, BY YOU TO US<span class="block-container if" data-type="if"
                        id="19e172cb-4ccf-1904-7c06-4251800ba748">
                        <span data-type="conditional-block">
                          <span class="block-component" data-record-question-key="limilation_liability_time_option"
                            data-type="statement"> </span>
                          <span data-type="body"><span
                              style="font-size: 11pt; color: rgb(89, 89, 89); text-transform: uppercase;">DURING THE
                              <span class="block-container question question-in-editor"
                                data-id="5dd68d46-ed6f-61c7-cd66-6b3f424b6bdd" data-type="question">six (6)</span>
                              mONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING
                            </span></span>
                        </span>
                        <span class="statement-end-if-in-editor" data-type="close"></span>
                      </span>
                    </span>
                  </span>
                  <span data-type="conditional-block">
                    <span class="block-component" data-record-question-key="limitations_liability_option"
                      data-type="statement">.
                    </span></span>
                </span>
              </span><span data-custom-class="body_text">CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW
                LIMITATIONS
                ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                SOME OR
                ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                RIGHTS.</span>
            </span>
          </span></span>
        <span class="statement-end-if-in-editor" data-type="close"><span data-custom-class="body_text"></span></span>
      </span></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="indemnification"
      style="line-height: 1.5; text-align: left;"><strong><span
          style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">19.</span></strong></span></strong></span>
          </strong>INDEMNIFICATION</span></strong></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">You agree to
        defend, indemnify, and hold us harmless, including our subsidiaries,
        affiliates, and all of our respective officers, agents, partners, and
        employees, from and against any loss, damage, liability, claim, or demand, including
        reasonable attorneys’ fees and expenses, made by any third party due to or
        arising out of: <span class="block-container if" data-type="if" id="475fffa5-05ca-def8-ac88-f426b238903c">
          <span data-type="conditional-block">
            <span class="block-component" data-record-question-key="user_post_content_option" data-type="statement">
            </span>
          </span>(<span style="font-size: 14.6667px;">1</span>) use of the Services; (<span
            style="font-size: 14.6667px;">2</span>) breach of these Legal Terms; (<span
            style="font-size: 14.6667px;">3</span>) any breach of your representations and warranties set forth in
          these Legal Terms; (<span style="font-size: 14.6667px;">4</span>) your violation of the rights of a third
          party, including but not limited to intellectual property rights; or (<span
            style="font-size: 14.6667px;">5</span>) any overt harmful act toward any other user of the Services with
          whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense,
          to assume the exclusive <span class="block-component"></span>defense<span
            class="statement-end-if-in-editor">
          </span> and control of any matter for which you are required to indemnify us, and you agree to cooperate, at
          your expense, with our <span class="block-component"></span>defense<span class="statement-end-if-in-editor">
          </span> of such claims. We will use reasonable efforts to notify you of any such claim, action, or
          proceeding
          which is subject to this indemnification upon becoming aware of it.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="userdata" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">20.</span></strong></span></strong></span>
          </strong>USER DATA</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">We will maintain
        certain data that you transmit to the Services for the purpose of managing the
        performance of the Services, as well as data relating to your use of the Services. Although we perform regular
        routine backups
        of data, you are solely responsible for all data that you transmit or that
        relates to any activity you have undertaken using the Services. You agree
        that we shall have no liability to you for any loss or corruption of any such
        data, and you hereby waive any right of action against us arising from any such
        loss or corruption of such data.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="electronic" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">21.</span></strong></span></strong></span>
          </strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">Visiting the Services, sending us emails, and completing
        online forms constitute electronic communications. You consent to receive electronic communications, and you
        agree that all agreements, notices, disclosures, and other communications we provide to you electronically,
        via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY
        AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
        NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby
        waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any
        jurisdiction which require an original signature or delivery or retention of non-electronic records, or to
        payments or the granting of credits by any means other than electronic means.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"></span>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="california" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">22.</span></strong></span></strong></span>
          </strong>CALIFORNIA USERS AND RESIDENTS</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">If any complaint
        with us is not satisfactorily resolved, you can contact the Complaint
        Assistance Unit of the Division of Consumer Services of the California
        Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N
        112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916)
        445-1254.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="statement-end-if-in-editor"></span>
    </div>
    <div class="MsoNormal" data-custom-class="heading_1" id="misc" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
              style="line-height: 24.5333px; font-size: 19px;"><strong><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;">23.</span></strong></span></strong></span>
          </strong>MISCELLANEOUS</span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">These Legal Terms and any policies or operating rules
        posted by us on the Services or in respect to the Services constitute the entire agreement and understanding
        between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not
        operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by
        law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible
        or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If
        any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not
        affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership,
        employment or agency relationship created between you and us as a result of these Legal Terms or use of the
        Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them.
        You hereby waive any and all <span class="block-component"></span>defenses<span
          class="statement-end-if-in-editor"></span> you may have based on the electronic form of these Legal Terms
        and
        the lack of signing by the parties hereto to execute these Legal Terms.</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
      <span class="block-component"><span style="font-size: 15px;"></span></span>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="heading_1" id="contact" style="line-height: 1.5; text-align: left;">
      <strong><span style="line-height: 115%; font-family: Arial;"><span style="font-size: 19px;"><strong><span
                style="line-height: 24.5333px; font-size: 19px;"><strong><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;">24.</span></strong></span></strong></span>
            </strong>CONTACT US</span></span></strong>
    </div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;">In order to resolve a complaint regarding the Services
        or to receive further information regarding use of the Services, please contact us at:</span></div>
    <div class="MsoNormal" style="line-height: 1.5; text-align: left;"></br></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
          <span class="question"><strong>Premium Intel, LLC, a New York limited liability company dba
              Wendwise</strong>
          </span><strong>
            <span class="block-component"></span>
          </strong>
        </span></span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 15px;"><span style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
          <span class="question"><strong>
              <span class="question">n/a</span>
            </strong></span><span style="line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);">
            <span class="statement-end-if-in-editor"></span>
          </span>
          <span class="block-component"></span>
        </span></span></span></span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 15px;"><strong><span style="color: rgb(89, 89, 89);">
            <span class="question">Pittsford</span>
            <span class="block-component"></span>
            <span class="block-component"></span>, <span class="question">NY</span>
            <span class="statement-end-if-in-editor"></span>
            <span class="block-component"></span>
            <span class="block-component"></span>
            <span class="question">14534</span>
            <span class="statement-end-if-in-editor"></span>
          </span></strong><strong><span style="color: rgb(89, 89, 89);">
            <span class="block-component"></span>
          </span>
          <span class="block-component"></span>
          <span class="block-component"></span>
        </strong></span></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span
        style="font-size: 15px;">
        <span class="question"><strong>United States</strong></span><strong>
          <span class="statement-end-if-in-editor"><span style="color: rgb(89, 89, 89);"><strong><span
                  style="font-size: 15px;">
                  <span class="statement-end-if-in-editor"><span style="color: rgb(89, 89, 89);"><strong><span
                          style="font-size: 15px;">
                          <span class="statement-end-if-in-editor">
                            <span class="block-component"></span></strong>
                      <span class="statement-end-if-in-editor"><strong></strong></span>
    </div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><strong><span
          style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><strong>
            <span class="block-component"></span>Phone: <span class="question">n/a</span>
            <span class="statement-end-if-in-editor"></span>
          </strong></span></strong></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><strong><span
          style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><strong>
            <span class="block-component"></span>
          </strong></span></strong></span></strong></div>
    <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><strong><span
          style="font-size:11.0pt;line-height:115%;font-family:Arial;
Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"><strong>
            <span class="question">neil@premiumintel.com</span>
          </strong></span></strong></div>
  </div>
</div>
<style>
  ul {
    list-style-type: square;
  }

  ul>li>ul {
    list-style-type: circle;
  }

  ul>li>ul>li>ul {
    list-style-type: square;
  }

  ol li {
    font-family: Arial;
  }
</style>
</div>
`;

export default terms;
