import axios from "../../../adapters/axios";

export const fetchTags = async () => {
  const response = await axios.get("tags");
  return response.data;
};

export const postTag = async (tag: {
  tagName: string;
  tagType: string;
  tagDescription: string;
}) => {
  const response = await axios.post("tags", tag);
  return response.data;
};

export const deleteTag = async (id: string) => {
  const response = await axios.delete(`tags/${id}`);
  return response.data;
};

export const patchTag = async (
  id: string,
  tag: { tagName: string; tagType: string; tagDescription: string }
) => {
  const response = await axios.put(`tags/${id}`, tag);
  return response.data;
};
