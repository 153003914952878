// import { ReactDOM } from "react";
import { createPortal } from "react-dom";
// import { ImCross as Cross } from "react-icons/im";
import { RxCross1 as Cross } from "react-icons/rx";
import { TiTick as Tick } from "react-icons/ti";
import styles from "./style.module.css";

export default function Notification(props: {
  message: string;
  status: string;
}) {
  const { status, message } = props;

  if (status === "success") {
    return createPortal(
      <div
        className={`fixed-bottom m-4 alert alert-success rounded d-flex align-items-center float-right ${styles["notification-box"]}`}
        style={{
          width: "400px",
          textAlign: "center",
          transition: "all ease-in 0.5s",
        }}
      >
        {/* <Tick style={{ fontSize: "3rem", color: "white" }} /> */}
        <Tick style={{ fontSize: "3rem" }} />

        {/* <p className="text-white m-0">{message}</p> */}
        <p className="m-0">{message}</p>
      </div>,
      document.getElementById("notification") as HTMLElement
    );
  } else if (status === "failed") {
    return createPortal(
      <div
        className={`fixed-bottom m-4 alert alert-danger rounded d-flex align-items-center float-right ${styles["notification-box"]}`}
        style={{
          width: "400px",
          textAlign: "center",
          transition: "all ease-in 0.5s",
        }}
      >
        {/* <Tick style={{ fontSize: "3rem", color: "white" }} /> */}
        <Cross style={{ fontSize: "2rem" }} />

        {/* <p className="text-white m-0">{message}</p> */}
        <p className="m-0 mx-3">{message}</p>
      </div>,
      document.getElementById("notification") as HTMLElement
    );
  } else {
    return <></>;
  }
}
