import { useDispatch } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/modules/auth/actions";

export default function AdminCompanyBar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <div className="vertical-nav bg-white" id="sidebar">
      <h1 className="logo">
          Wendwise
          <sup className="tm">TM</sup>
        </h1>
        <ul className="nav flex-column bg-white mb-0 px-0 ">
          <li className="nav-item">
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-home" aria-hidden="true"></i>Home
            </NavLink>
          </li>
          <>
            <li className="nav-item">
              <NavLink
                to="/companies"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white bg-primary"
                    : "nav-link text-dark"
                }
              >
                <i className="fa fa fa-building-o" aria-hidden="true"></i>
                Companies
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/tags"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white bg-primary"
                    : "nav-link text-dark"
                }
              >
                <i className="fa fa-tag" aria-hidden="true"></i>
                Tags
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/requests"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white bg-primary"
                    : "nav-link text-dark"
                }
              >
                <i className="fa fa-commenting-o" aria-hidden="true"></i>
                Requests
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/notifications"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white bg-primary"
                    : "nav-link text-dark"
                }
              >
                <i className="fa fa-bell-o" aria-hidden="true"></i>
                Notifications
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/migrate"
                className={({ isActive }) =>
                  isActive
                    ? "nav-link text-white bg-primary"
                    : "nav-link text-dark"
                }
              >
                <i className="fa fa-database" aria-hidden="true"></i>
                Migrate
              </NavLink>
            </li>
          </>
          <li className="nav-item">
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-cog" aria-hidden="true"></i>
              Admin Settings
            </NavLink>
          </li>
          <li className="nav-item mb-2">
            <a
              role="button"
              onClick={handleLogout}
              className="nav-link text-dark"
            >
              <i className="fa fa-power-off text-dark" aria-hidden="true"></i>
              Logout
            </a>
          </li>
        </ul>
      </div>
      {/* <!--Page Content--> */}
      <Outlet />
    </>
  );
}
