import { MouseEventHandler, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { Modal } from "react-bootstrap";

import { Company } from "../../redux/utils/types";
import axios from "../../adapters/axios";

export default function KeyPartnersPrompt(props: {
  show: boolean;
  handleOk: Function;
  handleCancel: MouseEventHandler<any>;
  selectedPartners: Company[];
  setPrompt: Function;
  currentCompanyId?: number;
}) {
  const {
    show,
    handleOk,
    handleCancel,
    selectedPartners,
    setPrompt,
    currentCompanyId,
  } = props;
  const loadOptions = async (
    query: string,
    prev_option: unknown,
    additional: any
  ) => {
    const { page } = additional;
    const response = await axios.post(`companies/paginate/${page}`, {
      keyword: query,
      tags: [],
    });
    const companies: Company[] = response.data.data;
    let uniqueIds = new Set<number>();
    let options = companies.reduce((acc: any[], company) => {
      if (!uniqueIds.has(company.id)) {
        uniqueIds.add(company.id);
        acc.push({
          label: company.companyName,
          value: company.id,
        });
      }
      return acc;
    }, []);
    // const companies: Company[] = response.data.data;
    // let options = companies.map((company) => ({
    //   label: company.companyName,
    //   value: company.id,
    // }));

    // filter options that are already partners and the self company
    const partnerSet = new Set(selectedPartners?.map((partner) => partner.id));

    options = options.filter(
      (option) =>
        !partnerSet.has(option.value) && option.value !== currentCompanyId
    );

    const totalCount = response.data.pageCount;
    return {
      options,
      hasMore: page < totalCount,
      additional: { page: page + 1 },
    };
  };
  const [value, setValue] = useState<any>([]);

  return (
    <Modal show={show} centered={true} size="lg">
      <div className="modal-content p-3">
        <div className="modal-body">
          <div className="delete-tag">
            <p>Are you sure you want to add this Partner to the company ?</p>
            <div className="container-fluid mt-5">
              <AsyncPaginate
                value={value}
                loadOptions={loadOptions}
                closeMenuOnSelect={false}
                onChange={setValue}
                additional={{ page: 1 }}
                isMulti={true}
                placeholder="Select Partner Company"
                // components={animatedComponents}
              />
              <div className="my-5">
                <button
                  className="btn company-btn"
                  onClick={() => setPrompt("name")}
                >
                  Add New Company
                </button>
                <button
                  className="btn company-btn"
                  onClick={() => setPrompt("link")}
                >
                  Add Company Link
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <button
            className="btn back-btn"
            onClick={() => {
              handleOk(
                value.map((v: any) => ({ id: v.value, companyName: v.label }))
              );
              setValue([]);
            }}
          >
            Yes
          </button>
          <button type="button" className="btn back-btn" onClick={handleCancel}>
            No
          </button>
        </div>
      </div>
    </Modal>
  );
}
