import { useState } from "react";
import { Modal } from "react-bootstrap";

export default function CompanyLinkPrompt(props: {
  show: boolean;
  setPrompt: Function;
  handleOk: Function;
}) {
  const { show, setPrompt, handleOk } = props;

  const [partnerLink, setPartnerLink] = useState<string>("");
  return (
    <Modal show={show} centered={true} size="lg">
      <div className="modal-content p-3">
        <div className="modal-body">
          <div className="delete-tag">
            <p>
              Are you sure you want to add this new company link to company
              partner's category ?
            </p>
            <div className="container-fluid add-company">
              <input
                type="url"
                className="form-control"
                placeholder="Company link (https)"
                value={partnerLink}
                onChange={(e) => setPartnerLink(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-around">
          <button
            className="btn back-btn"
            onClick={(_) => handleOk([{ partnerLink }])}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn back-btn"
            onClick={() => setPrompt("company")}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
}
