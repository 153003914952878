export default function TextInput(props: {
  label: string;
  attrs: any;
  value: string;
  errors: any;
  handleChange: any;
}) {
  const { label, attrs, errors = [], handleChange, value } = props;

  return (
    <div className="form-group">
      <label htmlFor={attrs.id}>{label}</label>
      <input
        {...attrs}
        value={value ?? ""}
        className="form-control"
        onChange={(e) => handleChange(e.target.value)}
        style={{ borderColor: errors?.length ? "red" : "" }}
      />
      <div>
        {errors?.map((err: string) => (
          <p className="m-3 text-danger font-weight-bold">{err}</p>
        ))}
      </div>
    </div>
  );
}
