import axios from '../../../adapters/axios/'


export const httpGetRequests = async ()=> {
  const response = await axios.get('requests')
  return response.data
}


export const httpSendRequest = async (request : {companyId: number, comment: string}) => {
  const response = await axios.post('requests', request)
  return response.data
}

export const httpCloseRequest = async (id: number) => {
  const response =  await axios.get(`requests/${id}/close`)
  return response.data
}
