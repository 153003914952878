import { MouseEventHandler, useState } from "react";
import TagPrompt from "../TagPrompt";
import { Tag } from "../../redux/utils/types";
import { RxCross2 as Cross } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";

export default function AddTags(props: {
  closeTab: MouseEventHandler<HTMLSpanElement>;
  tags: Tag[];
  setTags: any;
  show?: boolean;
}) {
  const { closeTab, setTags, tags, show } = props;
  const [isPrompt, setIsPrompt] = useState<boolean>(false);

  const { pathname } = useLocation();

  const section = pathname.split("/")[1];

  const handleAddTags = (tagsInput: any) => {
    const { companyType, productType, solutionType } = tagsInput;

    const tagsArray: Tag[] = [];

    companyType && tagsArray.push(...companyType);
    productType && tagsArray.push(...productType);
    solutionType && tagsArray.push(...solutionType);

    const newTagsList: Tag[] = [...tags, ...tagsArray];

    setTags(newTagsList);
    setIsPrompt(false);
  };

  const handleRemoveTag = (id: any) => {
    const filteredTag = tags.filter((tag) => tag.id !== id);
    setTags(filteredTag);
  };

  if (!show) {
    return <></>;
  }

  return (
    <>
      <TagPrompt
        show={isPrompt}
        handleOk={handleAddTags}
        handleCancel={() => setIsPrompt(false)}
        selectedTags={tags}
        // setTags={setTags}
      />
      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">Tags&nbsp;</h5>
                        </div>
                      </div>
                      <div className="top-end">
                        {section === "user" && (
                          <div>
                            <Link className="btn suggest-btn" to="suggetion">
                              Suggest Update
                            </Link>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <></>
                    <div className="bg mt-2">
                      <div className="bg mt-2">
                        {tags.map((tag: Tag) => (
                          <div key={tag.id} className="my-3 d-flex">
                            <p className="border rounded w-75 p-2 bg-white list__test">
                              {tag.tagName}
                            </p>
                            <div
                              className="m-2"
                              onClick={() => handleRemoveTag(tag.id)}
                            >
                              {section !== "user" && (
                                <Cross
                                  className="text-danger h4"
                                  role="button"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="bg-inner mt-2">
                        <div className="top"></div>
                      </div>

                      {section !== "user" && (
                        <div className="text-right my-4">
                          <button
                            className="btn key-btn"
                            onClick={() => setIsPrompt(true)}
                          >
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Tags
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
