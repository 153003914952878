import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../redux/modules/auth/userSelector";
import AdminBar from "../../components/Sidebar/Admin";
import { NavbarToggler } from "../../components/NavbarToggler";

export default function AdminHome() {
  const dispatch = useDispatch<any>();

  const user: any = useSelector(userSelector);

  return (
    <>
      {/* <!--Page Content--> */}
      {/* {!user && <Navigate to="/login" />} */}
      <AdminBar />
      <div className="page-content" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Welcome to Insurance Database</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        {/* <!-- Demo content --> */}
        <div className="container-fluid admin admin-home">
          <p className="admin-para">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>

          <div className="row">
            <div className="col-sm-12 col-md-6">
              <div className="box">
                <Link to="/users" className="mx-auto">
                  <div className="center mx-auto box-eclipse">
                    <img
                      src="/img/Icon.png"
                      className="image-icon mx-auto d-block"
                      alt=""
                    />
                  </div>
                  <h2 className="user">User Registration & Management</h2>
                  <div className="center mx-auto box-vector">
                    <img
                      src="/img/Vector1.png"
                      className="mx-auto d-block animated"
                      alt="arrow"
                    />
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-sm-12 col-md-6">
              <div className="box">
                <Link to="/companies" className="mx-auto">
                  <div className="center mx-auto box-eclipse">
                    <img
                      src="/img/Frame.png"
                      className="image-icon mx-auto d-block"
                      alt="arrow"
                    />
                  </div>
                  <h2 className="user">Database Management</h2>
                  <div className="center mx-auto box-vector">
                    <img
                      src="/img/Vector1.png"
                      className="mx-auto d-block animated"
                      alt="arrow"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
