import { useNavigate } from "react-router-dom";
export default function Back(props: { to?: any }) {
  const { to = -1 } = props;
  const navigate = useNavigate();
  return (
    <button
      type="button"
      onClick={(_) => navigate(to)}
      className="btn back-btn"
    >
      Back
    </button>
  );
}
