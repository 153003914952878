import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { tagsSelector } from "../../redux/modules/tags/selector";
import { Tag } from "../../redux/utils/types";
import { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function TagPrompt(props: {
  show: boolean;
  handleOk: any;
  handleCancel: any;
  selectedTags: Tag[];
  // setTags: Function;
}) {
  const { show, handleOk, handleCancel, selectedTags } = props;

  const tagsMap = useSelector(tagsSelector);

  let tags: any[] = Array.from(tagsMap.values());

  const companyTags: Tag[] = [];
  const productTags: Tag[] = [];
  const solutionTags: Tag[] = [];

  const selectedIds = selectedTags.map((tag) => tag.id);

  //filter the tags which are already selected
  tags = tags.filter((tag) => !selectedIds.includes(tag.id));

  tags.forEach((tag) => {
    tag.tagType === "company"
      ? companyTags.push(tag)
      : tag.tagType === "product"
      ? productTags.push(tag)
      : solutionTags.push(tag);
  });

  // const [companyType, setCompanyType] = useState<Tag | undefined>(undefined);
  // const [productType, setProductType] = useState<Tag | undefined>(undefined);
  // const [solutionType, setSolutionType] = useState<Tag | undefined>(undefined);

  const handleAddTag = () => {
    handleOk(tagsInput);
    setTagsInput({});
  };

  const usersOptions = companyTags.map((tag: Tag) => {
    return { label: tag.tagName, value: tag.id };
  });

  const animatedComponents = makeAnimated();

  const styles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    input: (provided: any, state: any) => ({
      ...provided,
      minHeight: "30px",
      gridArea: "2/1/1/2",
    }),
    placeholder: (provided: any) => ({ ...provided, gridArea: "1/1/2/3" }),
  };

  const companyTagsInput = companyTags.map((tag: any) => ({
    value: tag.id,
    label: tag.tagName,
  }));

  const productTagsInput = productTags.map((tag: Tag) => ({
    value: tag.id,
    label: tag.tagName,
  }));

  const solutionTagsInput = solutionTags.map((tag: Tag) => ({
    value: tag.id,
    label: tag.tagName,
  }));

  const [tagsInput, setTagsInput] = useState<any>({});

  return (
    <Modal show={show} size="lg" centered={true}>
      <div className="modal-content p-3">
        <div className="modal-body">
          <div className="delete-tag">
            <p>Are you sure you want to add this Tag to the company ? </p>
            <div className="container-fluid">
              <div className="row d-flex select-category">
                <div className="col-12 form-group">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                    isMulti
                    options={companyTagsInput}
                    styles={styles}
                    placeholder="Select Company Type Tags"
                    onChange={(e) =>
                      setTagsInput({
                        ...tagsInput,
                        companyType: e.map((input: any) =>
                          tagsMap.get(input.value.toString())
                        ),
                      })
                    }
                  />
                </div>

                <div className="col-12 form-group">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                    isMulti
                    options={productTagsInput}
                    styles={styles}
                    placeholder="Select Product Type Tags"
                    onChange={(e) =>
                      setTagsInput({
                        ...tagsInput,
                        productType: e.map((input: any) =>
                          tagsMap.get(input.value.toString())
                        ),
                      })
                    }
                  />
                </div>
                <div className="col-12 form-group">
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={[colourOptions[4], colourOptions[5]]}
                    isMulti={true}
                    placeholder="Select Solution Type Tags"
                    options={solutionTagsInput}
                    styles={styles}
                    onChange={(e) =>
                      setTagsInput({
                        ...tagsInput,
                        solutionType: e.map((input: any) =>
                          tagsMap.get(input.value.toString())
                        ),
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button className="btn back-btn" onClick={handleAddTag}>
            Yes
          </button>
          <button
            type="button"
            className="btn back-btn"
            data-dismiss="modal"
            onClick={handleCancel}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
}
