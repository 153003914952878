import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, Tag } from "../../utils/types";
import { createTag, editTag, removeTag } from "./actions";

const initialState: RootState["tags"] = {
  status: "idle",
  message: "",
  map: new Map<string, Tag>(),
};

export const tags = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTag: (state, action: PayloadAction<Tag>) => {
      state.map.set(action.payload.id.toString(), action.payload);
    },

    
    reset: (state) => {
      state.status = "idle";
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder

      //create tag
      .addCase(createTag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(createTag.fulfilled, (state, action: any) => {
        state.status = "success";
        state.message = "Tag created Successfully";
      })

      .addCase(createTag.rejected, (state, action) => {
        state.status = "failed";
        state.message = "something went wrong";
      })

      //remove tag
      .addCase(removeTag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(removeTag.fulfilled, (state, action: any) => {
        state.status = "success";
        const id = action.payload.id;
        const deleted = state.map.delete(String(id));
        state.message = "Tag removed Successfully";
        // state.status = 'idle'
      })

      .addCase(removeTag.rejected, (state) => {
        state.status = "failed";
      })

      //edit tag
      .addCase(editTag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(editTag.fulfilled, (state) => {
        state.status = "success";
        state.message = "Tag updated Successfully";
      })

      .addCase(editTag.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default tags.reducer;

export const { setTag, reset } = tags.actions;
