import { useDispatch, useSelector } from "react-redux";
import { usersSelector } from "../../../redux/modules/users/selectors";
import { EmptyList } from "../../../components/EmptyList";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpGetActivities } from "./api";
import Paginate from "../../../components/Paginate";
import Spinner from "../../../components/Spinner";
import { NavbarToggler } from "../../../components/NavbarToggler";
import styles from "./style.module.css";

export default function ActivityFeed() {
  const users = useSelector(usersSelector);

  //pagination states
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [activities, setActivities] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    httpGetActivities(page).then((data) => {
      setActivities(data.activities ?? []);
      setPageCount(data.pageCount);
      setTotal(data.total);
      setLoading(false);
    });
  }, [page]);

  const handlePageChange = (event: any) => {
    setPage(event.selected + 1);
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="page-content p-0 bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Activity</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="table-responsive" id="collapse1">
            <table className="table global-table">
              <thead>
                <tr>
                  <th scope="row">No.</th>
                  <th scope="row">Activity</th>
                  <th scope="row">Date</th>
                  <th scope="row">Time</th>
                  <th scope="row">User ID</th>
                </tr>
              </thead>

              <tbody className="text-center">
                <>
                  {activities.map((act: any, i: number) => {
                    const {
                      createdBy,
                      updatedBy,
                      createdAt,
                      updatedAt,
                      type,
                      activity,
                      company,
                    } = act;
                    const userId = type === "create" ? createdBy : updatedBy;
                    const username = users.get(userId.toString())?.user_id;

                    const date = new Date(updatedAt ?? createdAt);

                    let mes = "";

                    if (activity === "company") {
                      mes =
                        type === "create"
                          ? `New Company added named ${company}`
                          : `${company} info updated`;
                    } else {
                      mes =
                        type === "update"
                          ? `${
                              activity[0].toUpperCase() + activity.substring(1)
                            } updated in ${company}`
                          : `${
                              activity[0].toUpperCase() + activity.substring(1)
                            } added in ${company}`;
                    }
                    const offset = (page - 1) * 15;
                    return (
                      <tr key={i + offset}>
                        <th scope="row">{i + offset + 1}</th>
                        <td style={{ maxWidth: "250px" }}>{mes}</td>
                        <td>
                          {date.toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </td>
                        <td>
                          {date.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })}
                        </td>
                        <td>{username}</td>
                      </tr>
                    );
                  })}
                  <tr className={styles.__page_fix__}>
                    <td colSpan={5}>
                      <div>
                        <Paginate
                          page={page}
                          pageCount={pageCount}
                          handlePageChange={handlePageChange}
                          total={total}
                        />
                      </div>
                    </td>
                  </tr>
                </>
                {activities.length === 0 && (
                  <EmptyList title="No Activity Found :(" />
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="footer">
          <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
        </div>
      </div>
    </>
  );
}
