import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { companySelector } from "../../redux/modules/company/selectors";
import { Company } from "../../redux/utils/types";
import { useState } from "react";
import Spinner from "../../components/Spinner";
import { requestsSelector } from "../../redux/modules/requests/selectors";
import { sendRequest } from "../../redux/modules/requests/actions";
import Popup from "../../components/Modal";
import { reset } from "../../redux/modules/requests/slice";

export default function Suggestion() {
  const { id: companyId, type } = useParams();
  const navigate = useNavigate();

  const companies: Map<string, Company> = useSelector(companySelector);
  const company: Company | undefined = companies.get(companyId || "");
  const sugg = `Company Id: ${company?.id}\nCompany Name: ${company?.companyName}\n\nPlease Write your Suggetions here...
  `;
  const [suggestion, setSuggestion] = useState<string>(sugg);

  const { status, message } = useSelector(requestsSelector);
  const dispatch = useDispatch<any>();

  const handleSendRequest = () => {
    if (!type || !companyId) return;
    const request = { companyId: +companyId, comment: suggestion, type };
    dispatch(sendRequest(request));
  };

  const handleSuccessModal = () => {
    dispatch(reset());
    navigate(-1);
  };

  return (
    <>
      {status === "loading" && <Spinner />}
      <Popup
        show={status === "success"}
        handleClose={handleSuccessModal}
        message={message}
      />
      <div className="page-content p-0" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Suggetions</h2>
              <div className="toggler">
                <button
                  className="btn nav-btn navbar-toggler d-block d-lg-none"
                  type="button"
                >
                  <span className="fa fa-bars"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid contact">
          <div className="contact-box">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group subtitle">
                <label>
                  Please add your queries and insights regarding Companies,
                  Tags, Partners and Flags in the Comment Box and press
                  Send.......
                </label>
                <textarea
                  className="form-control"
                  placeholder="Comment/ Queries/ Insights"
                  value={suggestion}
                  onChange={(e) => setSuggestion(e.target.value)}
                  style={{ minHeight: "200px", maxHeight: "700px" }}
                ></textarea>
              </div>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn suggest-btn"
                  onClick={handleSendRequest}
                >
                  Send Suggetion
                </button>
                <button onClick={() => navigate(-1)} className="btn back-btn">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
