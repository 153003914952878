import { useEffect } from "react";
import "./App.css";
import Routers from "./routes/Routes";
// const CLIENT_ID = '629515516716-cec287dvhpqn6fiefioe4thtqpt1ri8o.apps.googleusercontent.com'

function App() {
  return <Routers />;
}

export default App;
