import { useParams, useNavigate } from "react-router-dom";
import { useState, useCallback, useRef, useEffect } from "react";
import { debounce } from "lodash";
import { usersSelector } from "../../redux/modules/users/selectors";
import { useSelector, useDispatch } from "react-redux";
import Prompt from "../../components/ConfirmationPrompt";
import {
  deleteUser,
  editUser,
  updatePassword,
} from "../../redux/modules/users/actions";
import Popup from "../../components/Modal";
import { reset } from "../../redux/modules/users/slice";
import validator from "validator";
import Spinner from "../../components/Spinner";
import { GrEdit as Pencil } from "react-icons/gr";
import { RootState } from "../../redux/utils/types";
import Back from "../../components/Buttons/Back";
import axios from "../../adapters/axios";
import { NavbarToggler } from "../../components/NavbarToggler";
import TextInput from "../../components/TextInput";
import NotificationToast from "../../components/NotificationToast";

export default function ViewUser() {
  const { userId: id = "1" } = useParams();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPrompt, setIsPrompt] = useState<boolean>(false);

  const navigate = useNavigate();

  const users = useSelector(usersSelector);

  const dispatch = useDispatch();

  const user: any = users.get(id + "");

  const [isEditingPass, setIsEditingPass] = useState<boolean>(false);

  const [isUserNameAvailable, setIsUserNameAvailable] =
    useState<boolean>(false);

  const form = useRef<HTMLFormElement>(null);

  const handleDeleteProfile = () => {
    dispatch<any>(deleteUser(id));
    navigate(-1);
  };

  const [userData, setUserData] = useState<any>(user ?? {});
  const [errors, setErrors] = useState<any>({});
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const handleSuccessModal = () => {
    dispatch(reset());
    navigate(-1);
  };

  const { status, message } = useSelector((state: RootState) => state.users);
  // const {status, message} = useSelector(userSele)

  const handleUpdatePassword = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = { id: +id, password: userData.password };
    dispatch<any>(updatePassword(payload));

    function validate() {
      if (userData.password !== userData.confirmPassword) {
        setErrors({ ...errors, confirmPassword: ["Password do not match !"] });
        return false;
      }
      return true;
    }

    setIsEditingPass(false);
    setUserData({ ...userData, password: "", confirmPassword: "" });
  };

  const checkAvailabiliy = async (val: any) => {
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (regex.test(val)) {
      setIsUserNameAvailable(false);
      return;
    }
    try {
      const res = await axios.post("checkAvailability", { user_id: val });
      setIsUserNameAvailable(res.data.available);
    } catch (err) {}
  };

  const handleChange = useCallback(
    debounce((val) => checkAvailabiliy(val), 500),
    []
  );

  useEffect(() => {
    if (status === "success") {
      setIsEditing(false);

      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        clearTimeout(t);
        dispatch(reset());
      };
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (status === "failed") {
      dispatch(reset());
    }
  }, [userData]);

  const handleEditProfile = (e: any) => {
    e.preventDefault();
    if (form?.current?.checkValidity()) {
      delete userData.password;
      delete userData.confirmPassword;
      dispatch<any>(editUser(userData));
      // setIsEditing(false);
    } else {
      form?.current?.reportValidity();
    }
  };

  return (
    <>
      {status === "loading" && <Spinner />}
      {/* <Popup
        show={status === "success"}
        message={message}
        handleClose={handleSuccessModal}
      /> */}

      <Prompt
        show={isPrompt}
        prompt="Are you sure you want to delete this user?"
        handleCancel={() => setIsPrompt(false)}
        handleOk={handleDeleteProfile}
      />
      <div className="page-content bg-white" id="content">
        <div className="row-header text-right">
          <NavbarToggler />
        </div>

        <div className="user-box pb-4">
          <div className="container-fluid">
            <form ref={form} style={{ paddingBottom: 0 }}>
              <div className="form-buttons">
                <Back />
                {!isEditing ? (
                  <div className="test1 d-inline-block">
                    <button
                      type="button"
                      className="btn suggest-btn"
                      // onClick={() => setIsEditing(true)}
                      onClick={(e: any) => setIsEditing(true)}
                    >
                      Edit Profile
                    </button>
                    <button
                      type="button"
                      className="btn back-btn pull-right"
                      onClick={(e: any) => {
                        setIsPrompt(true);
                      }}
                    >
                      Delete Profile
                    </button>
                  </div>
                ) : (
                  <div className="test2 d-inline-block">
                    <button
                      // type="submit"
                      type="button"
                      className="btn suggest-btn"
                      onClick={handleEditProfile}
                    >
                      Save
                    </button>
                    <button
                      className="btn back-btn pull-right"
                      onClick={() => setIsEditing(false)}
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>

              {status === "failed" && (
                <span className="text-danger font-weight-bold">{message}</span>
              )}

              <TextInput
                label="First Name"
                attrs={{
                  type: "text",
                  id: "firstName",
                  placeholder: "First Name",
                  required: true,
                  disabled: !isEditing,
                }}
                value={userData.firstName}
                errors={errors.firstName}
                handleChange={(value: string) =>
                  setUserData({ ...userData, firstName: value })
                }
              />

              <TextInput
                label="Last Name"
                attrs={{
                  type: "text",
                  id: "lastName",
                  placeholder: "Last Name",
                  required: true,
                  disabled: !isEditing,
                }}
                value={userData.lastName}
                errors={errors.lastName}
                handleChange={(value: string) =>
                  setUserData({ ...userData, lastName: value })
                }
              />

              <TextInput
                label="Email ID"
                attrs={{
                  type: "email",
                  id: "email",
                  placeholder: "Your Email ID",
                  required: true,
                  disabled: !isEditing,
                  // disabled: true,
                  // title: "Emails Can't be edited",
                }}
                value={userData.email}
                errors={errors.email}
                handleChange={(value: string) =>
                  setUserData({ ...userData, email: value })
                }
              />

              <TextInput
                label="User ID"
                attrs={{
                  type: "text",
                  id: "user_id",
                  placeholder: "Enter a Unique user ID",
                  required: true,
                  title: "Space and special characters are not allowed",
                  disabled: !isEditing,
                }}
                value={userData.user_id}
                errors={errors.user_id}
                handleChange={(value: string) => {
                  setUserData({ ...userData, user_id: value });
                  handleChange(value);
                }}
              />

              {!errors.user_id &&
                userData.user_id?.length >= 4 &&
                userData.user_id !== user.user_id &&
                isEditing &&
                (isUserNameAvailable ? (
                  <p
                    className="font-weight-bold m-2"
                    style={{ color: "green" }}
                  >
                    User Name Available :)
                  </p>
                ) : (
                  <p className="text-danger font-weight-bold m-2">
                    User Name Not available :(
                  </p>
                ))}

              <TextInput
                label="Company Name"
                attrs={{
                  type: "text",
                  id: "company",
                  placeholder: "Company Name",
                  required: true,
                  disabled: !isEditing,
                }}
                value={userData.companyName}
                errors={errors.company}
                handleChange={(value: string) =>
                  setUserData({ ...userData, companyName: value })
                }
              />
            </form>

            <form
              onSubmit={handleUpdatePassword}
              style={{ border: "2px solid rgb(179 179 179)" }}
              className="p-4 mt-5 rounded"
            >
              <div className="d-flex justify-content-end">
                {!isEditingPass ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(_) => setIsEditingPass(!isEditingPass)}
                  >
                    Edit Password
                  </button>
                ) : (
                  <div>
                    <button
                      className="btn btn-secondary mx-2"
                      type="button"
                      onClick={(_) => setIsEditingPass(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-success mx-2">
                      Save
                    </button>
                  </div>
                )}
              </div>
              {/* password updation will be manually */}
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div className="d-flex align-items-center">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    className="form-control"
                    id="password"
                    placeholder="Enter new password"
                    name="password"
                    value={userData.password || ""}
                    disabled={!isEditingPass}
                    onChange={(e: any) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$"
                    title="Must contain at least one number, one uppercase letter, one lowercase letter, one special character and at least 10 characters"
                    required={true}
                  />
                  <div className="validation">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#validation"
                    >
                      <i className="fa fa-question-circle-o"></i>
                    </button>
                  </div>
                  <span
                    className="mx-4"
                    title="See Password"
                    role="button"
                    onClick={(_) => setPasswordVisibility(!passwordVisibility)}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                </div>
              </div>

              <TextInput
                label="Confirm Password"
                attrs={{
                  type: "password",
                  id: "c-password",
                  placeholder: "Retype Password",
                  required: true,
                  disabled: !isEditingPass,
                }}
                value={userData.confirmPassword}
                handleChange={(value: string) => {
                  setUserData({ ...userData, confirmPassword: value });
                  if (errors?.confirmPassword?.length) {
                    setErrors({ ...errors, confirmPassword: [] });
                  }
                }}
                errors={errors.confirmPassword}
              />
            </form>
          </div>
        </div>
        {/* </div> */}
        <NotificationToast show={status === "success"} message={message} />
      </div>

      <div
        className="modal fade"
        id="validation"
        role="dialog"
        aria-labelledby="passwordvalidation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <ul>
                <li>10 characters or longer</li>
                <li>At least one number</li>
                <li>At least one lowercase letter</li>
                <li>At least one uppercase letter</li>
                <li>At least one special character</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// modal
