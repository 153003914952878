export function Contributors(props: { contributors: string[] }) {
  const { contributors } = props;

  return (
    <>
      {contributors?.map((c, i) => (
        <span key={i} className="mx-1 font-weight-bold">
          {i !== contributors.length - 1 ? c + ", " : c}
        </span>
      ))}
    </>
  );
}
