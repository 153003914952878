import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import tags from "../../data/tags.json";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/utils/types";
import NotificationToast from "../../components/NotificationToast";
import { reset } from "../../redux/modules/tags/slice";
import { NavbarToggler } from "../../components/NavbarToggler";
import { EmptyList } from "../../components/EmptyList";

export default function AdminTags() {
  const {
    map: tagsMap,
    status,
    message,
  } = useSelector((state: RootState) => state.tags);
  const tagsArray: any = Array.from(tagsMap.values());

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        dispatch(reset());
      }, 5000);
    }
  }, [status, dispatch]);

  return (
    <>
      <div className="page-content bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <h2 className="head">Tags</h2>
              <Link to="new" className="btn suggest-btn" role="button">
                Create Tags
              </Link>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="table-responsive tags-table">
            <table className="table global-table">
              <thead>
                <tr>
                  <th scope="col" className="text-center">
                    No.
                  </th>
                  <th scope="col">Tag Name</th>
                  <th scope="col">Type</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {tagsArray.map((tag: any, index: number) => {
                  const { tagName, tagType, id } = tag;
                  return (
                    <tr>
                      <th scope="row">{index + 1}.</th>
                      <td>{tagName}</td>
                      <td>{tagType}</td>
                      <td className="text-right">
                        <Link to={`view/${id}`} className="btn view-btn">
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
                {tagsArray.length === 0 && (
                  <EmptyList
                    title="No Tags Present :("
                    subtitle="Add some tags by clicking above button, newly added tags will be listed here."
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <NotificationToast show={status === "success"} message={message} />
    </>
  );
}

/*
              <tr>
                <th scope="row">4.</th>
                <td>User ID 04</td>
                <td>abc1@evon.com</td>
                <td className="text-right">
                  <a href="create-tag-admin.html" className="btn view-btn">
                    View
                  </a>
                </td>
              </tr>

*/
