import { useDispatch, useSelector } from "react-redux";
import { requestsSelector } from "../../../redux/modules/requests/selectors";
import { Request } from "../../../redux/utils/types";
import { usersSelector } from "../../../redux/modules/users/selectors";
import { EmptyList } from "../../../components/EmptyList";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { getRequests } from "../../../redux/modules/requests/actions";
import { authSelector } from "../../../redux/modules/auth/userSelector";
import { NavbarToggler } from "../../../components/NavbarToggler";

export default function Requests() {
  const { requests } = useSelector(requestsSelector);
  const users = useSelector(usersSelector);

  let req = [...requests];
  const { user } = useSelector(authSelector);

  //filter only my requests
  req = req.filter((request: Request) => {
    return request.userId === user.id;
  });

  req = req.sort((r1, r2) => {
    const d1 = new Date(r1.createdAt);
    const d2 = new Date(r2.createdAt);

    return d1 > d2 ? -1 : 1;
  });

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(getRequests());
  }, [dispatch]);

  return (
    <div className="page-content p-0 bg-white" id="content">
      <div className="row-header">
        <div className="container-fluid">
          <div className="align-items-center d-flex justify-content-between">
            <h2 className="head">Requests</h2>
            {/* <div className="toggler">
              <button
                className="btn nav-btn navbar-toggler d-block d-lg-none"
                type="button"
              >
                <span className="fa fa-bars"></span>
              </button>
            </div> */}
            <NavbarToggler />
          </div>
        </div>
      </div>

      <div className="container-fluid px-0">
        <div className="table-responsive" id="collapse1">
          <table className="table global-table">
            <thead>
              <tr>
                <th scope="row">No.</th>
                <th scope="row">Request</th>
                <th scope="row">Date</th>
                <th scope="row">Time</th>
                <th scope="row">User ID</th>
                <th scope="row">Status</th>
              </tr>
            </thead>

            <tbody className="text-center">
              <>
                {req.map((r: Request, i: number) => {
                  const userId = users.get(r.userId.toString())?.user_id;
                  const date: any = new Date(r.createdAt);
                  return (
                    <tr key={r.id}>
                      <th scope="row">{i + 1}</th>
                      <td>{r.type + " Change"}</td>
                      <td>
                        {date.toLocaleDateString("en-us", {
                          dateStyle: "full",
                        })}
                      </td>
                      <td>
                        {date.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </td>
                      <td>{userId}</td>
                      <td>{r.status === 1 ? "Open" : "Closed"}</td>
                    </tr>
                  );
                })}
              </>
              {req?.length === 0 && (
                <EmptyList
                  title="You've not requested any change yet :)"
                  subtitle="Go to company page and suggest new changes and get featured as key contributor in company page."
                />
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="footer">
        <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
      </div>
    </div>
  );
}
