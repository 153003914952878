import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "../../redux/modules/auth/userSelector";
import { MouseEventHandler, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updatePassword } from "../../redux/modules/users/actions";
import { RootState } from "../../redux/utils/types";
import NotificationToast from "../../components/NotificationToast";
import { reset } from "../../redux/modules/users/slice";
import Spinner from "../../components/Spinner";
import AdminBar from "../../components/Sidebar/Admin";
import { NavbarToggler } from "../../components/NavbarToggler";
import TextInput from "../../components/TextInput";

export default function AdminSetting() {
  const user = useSelector(userSelector);

  const { status, message } = useSelector((state: RootState) => state.users);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [errors, setErrors] = useState<any>([]);

  const [passWarning, setPassWarning] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const handleUpdateProfile = (e: any) => {
    e.preventDefault();

    if (!validate()) return;

    const passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;

    if (passwordValid.test(password)) {
      dispatch(updatePassword({ id: user.id, password }));
    } else {
      setPassWarning(true);
    }
  };

  useEffect(() => {
    if (status === "success") {
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => dispatch(reset()), 5000);
    }
  }, [status, dispatch]);

  const validate = () => {
    if (password !== confirmPassword) {
      setErrors(["Passwords do not match !"]);
      return false;
    }
    return true;
  };

  return (
    <>
      {status === "loading" && <Spinner />}
      <PasswordValidationModal
        show={passWarning}
        onHide={() => setPassWarning(false)}
      />
      <AdminBar />
      <div className="page-content bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Settings</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="user-box">
          <div className="container-fluid">
            <form onSubmit={handleUpdateProfile}>
              <div className="form-group form-buttons">
                <button type="submit" className="btn suggest-btn pull-right">
                  Save Settings
                </button>
              </div>

              <div className="form-group">
                <label htmlFor="email">Email ID</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Your Email ID"
                  value={user?.email}
                  disabled={true}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="edit d-flex align-items-center">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    className="form-control edit-icon"
                    placeholder="Enter new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required={true}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$"
                    title="Must contain at least one number, one uppercase letter, one lowercase letter, one special character and at least 10 characters"
                    tabIndex={1}
                  />
                  <div className="validation">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#validation"
                      onClick={(_) => setPassWarning(true)}
                    >
                      <i className="fa fa-question-circle-o"></i>
                    </button>
                  </div>
                  <span
                    className="mx-4"
                    title={
                      passwordVisibility ? "Hide Password" : "See Password"
                    }
                    role="button"
                    onClick={(_) => setPasswordVisibility(!passwordVisibility)}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                </div>

                <TextInput
                  label="Confirm Password"
                  attrs={{
                    type: "password",
                    id: "c-password",
                    placeholder: "Retype Password",
                    required: true,
                    tabIndex: 2,
                  }}
                  value={confirmPassword}
                  handleChange={(value: string) => {
                    setConfirmPassword(value);
                    if (errors.length) {
                      setErrors([]);
                    }
                  }}
                  errors={errors}
                />
              </div>
            </form>
          </div>
        </div>
        {/* <!-- Modal --> */}
      </div>
      <NotificationToast show={status === "success"} message={message} />
    </>
  );
}

function PasswordValidationModal(props: {
  show: boolean;
  onHide: MouseEventHandler;
}) {
  const { show, onHide } = props;

  return (
    <Modal show={show} centered={true} onHide={onHide as any}>
      <div className="modal-content">
        <div className="modal-body">
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">&times;</span>
          </button>
          <ul>
            <li>10 characters or longer</li>
            <li>At least one number</li>
            <li>At least one lowercase letter</li>
            <li>At least one uppercase letter</li>
            <li>At least one special letter</li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
