import { MouseEventHandler } from "react";
import { useState } from "react";
import { RxCross2 as Cross } from "react-icons/rx";
import { useLocation } from "react-router-dom";

export default function AddFlag(props: {
  closeTab: MouseEventHandler<any>;
  flags: any[];
  setFlags: Function;
  show?: boolean;
}) {
  const { closeTab, flags, setFlags, show } = props;

  //error : when user adds nugget without inputing in text in last nugget
  const [isError, setIsError] = useState<boolean>(false);

  const { pathname } = useLocation();

  const section = pathname.split("/")[1];

  const handleInputChange = (changedText: string, index: number) => {
    if (isError) setIsError(false);
    const list = [...flags];
    list[index].flag = changedText;
    setFlags(list);
  };

  const handleRemoveFlag = (index: number) => {
    const list = [...flags];
    list.splice(index, 1);
    setFlags(list);
  };

  const handleAddFlag = () => {
    const lastNuggetIndex = flags.length - 1;
    const lastText = flags[lastNuggetIndex]?.flag;

    if (!lastText && flags.length > 0) {
      setIsError(true);
      return;
    }

    setFlags([...flags, { flag: "" }]);
  };

  if (!show) {
    return <></>;
  }

  return (
    <div className="company-details">
      <div className="container-fluid inner-box">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            {/* <!-- ------------------Nuggets------------- --> */}

            <div className="nugget-page">
              <div className="card w-100">
                <div className="card-body">
                  <div className="top d-flex justify-content-space">
                    <div className="top-start">
                      <div>
                        <h5 className="card-title">Flags&nbsp;</h5>
                      </div>
                    </div>
                    <div className="top-end">
                      {section === "user" && (
                        <div>
                          <button className="btn suggest-btn">
                            Suggest Update
                          </button>
                        </div>
                      )}
                      <div>
                        <span role="button" onClick={closeTab}>
                          <img
                            src="/img/back.png"
                            className="pl-3 img-icon"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="bg mt-2">
                    <div className="bg-inner mt-2">
                      {/* flags list will go here */}

                      {flags.map((flag, i) => (
                        <div className="my-3 d-flex align-items-center">
                          <input
                            value={flag.description}
                            className="border-0 w-75 form-control list__test text-black"
                            placeholder="Enter Flag Information"
                            onChange={(e) =>
                              handleInputChange(e.target.value, i)
                            }
                            onKeyUp={(e) => {
                              if (e.keyCode === 13) {
                                handleAddFlag();
                              }
                            }}
                            disabled={section === "user"}
                          />
                          {isError && i === flags.length - 1 && (
                            <span className="text-danger">Can't be Empty</span>
                          )}
                          {section !== "user" && (
                            <div
                              className="m-2"
                              onClick={() => handleRemoveFlag(i)}
                            >
                              <Cross className="text-danger h4" role="button" />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="bg-inner mt-2">
                      <div className="top">
                        <div className="top-start">
                          <p className="key">
                            Key Contributer User ID &gt; abc
                          </p>
                        </div>
                      </div>
                    </div>

                    {section !== "user" && (
                      <div className="text-right my-4">
                        <span
                          className="btn key-btn"
                          role="button"
                          onClick={handleAddFlag}
                        >
                          <span className="fa fa-plus-circle mr-2"></span>Add
                          Flags
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
