import { createSlice } from "@reduxjs/toolkit";
import {
  register,
  login,
  logout,
  whoAmI,
  getNotifications,
  acceptTerms,
} from "./actions";
import { RootState } from "../../utils/types";

const initialState: RootState["user"] = {
  status: "idle",
  message: "",
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.status = "loading";
      })

      .addCase(register.fulfilled, (state, action) => {
        state.status = "success";
        state.message = "User Created Successfully !";
        // state.user = action.payload
      })

      .addCase(register.rejected, (state, action: any) => {
        state.status = "failed"; // state.user = null
        if (action.payload.response?.data?.error?.code === "ER_DUP_ENTRY") {
          state.message = "Email Already Exists";
        } else {
          state.message = "Something went wrong, Pleaese try again !";
        }
      })

      .addCase(login.pending, (state) => {
        state.status = "loading";
      })

      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload;
        state.status = "success";
      })

      .addCase(login.rejected, (state, action: any) => {
        state.user = null;
        state.message = "invalid email or password";
        state.status = "failed";
      })

      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })

      .addCase(whoAmI.pending, (state) => {
        state.status = "loading";
      })

      .addCase(whoAmI.fulfilled, (state, action: any) => {
        state.user = action.payload;
        state.status = "success";
      })

      .addCase(whoAmI.rejected, (state, action: any) => {
        state.status = "failed";
        // state.isFailed = true;
        // state.user = null;
        // state.error = "invalid email or password"
      })

      .addCase(getNotifications.pending, (state) => {
        state.status = "idle";
      })

      .addCase(getNotifications.fulfilled, (state, action) => {
        state.status = "success";

        // jugaad
        state.status = "idle";
        if (state.user) {
          state.user.notifications = action.payload;
        }
      })

      .addCase(acceptTerms.fulfilled, (state, action) => {
        if (state.user) {
          state.user.isTermsAccepted = 1;
        }
      });
  },
});

export default authSlice.reducer;
export const { reset } = authSlice.actions;
