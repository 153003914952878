import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/Spinner";

import { editTag, removeTag } from "../../redux/modules/tags/actions";
import { reset } from "../../redux/modules/tags/slice";
import { useNavigate } from "react-router-dom";
import Prompt from "../../components/ConfirmationPrompt";
import { RootState } from "../../redux/utils/types";
import Back from "../../components/Buttons/Back";
import { NavbarToggler } from "../../components/NavbarToggler";
import NotificationToast from "../../components/NotificationToast";

export default function ViewTag() {
  const [isEditing, setIsEditing] = useState(false);

  const [isPrompt, setIsPrompt] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id = "1" } = useParams();
  const {
    map: tags,
    status,
    message,
  } = useSelector((state: RootState) => state.tags);

  const tag: any = tags.get(id.toString());

  const [tagName, setTagName] = useState(tag.tagName);
  const [tagType, setTagType] = useState(tag.tagType);
  const [tagDescription, setTagDescription] = useState(tag.tagDescription);

  const handleDeleteTag = () => {
    setIsPrompt(false);
    dispatch<any>(removeTag(id));
    navigate(-1);
  };

  const handleEditTag = async () => {
    if (!tagName || !tagType || !tagDescription) return;
    const tag = { id, tagName, tagType, tagDescription };
    await dispatch<any>(editTag(tag));
    setIsEditing(false);
  };

  useEffect(() => {
    if (status === "success") {
      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  return (
    <>
      {status === "loading" && <Spinner />}

      <Prompt
        show={isPrompt}
        prompt="Are you sure you want to delete this tag?"
        handleCancel={() => setIsPrompt(false)}
        handleOk={handleDeleteTag}
      />

      <div className="page-content" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Tags</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="notifications">
          <div className="container-fluid">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group form-buttons">
                <Back to="/tags" />
                {!isEditing ? (
                  <>
                    <button
                      className="btn suggest-btn"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit Tag
                    </button>
                    <button
                      className="btn back-btn pull-right"
                      onClick={() => setIsPrompt(true)}
                    >
                      Delete Tag
                    </button>
                  </>
                ) : (
                  <>
                    <button className="btn suggest-btn" onClick={handleEditTag}>
                      Save
                    </button>
                    <button
                      className="btn back-btn pull-right"
                      onClick={() => setIsEditing(false)}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>

              <div className="notification-box">
                <div className="form-group">
                  <label htmlFor="tag">Tag Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="tag"
                    value={tagName}
                    disabled={!isEditing}
                    onChange={(e) => setTagName(e.target.value)}
                    required={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="Company Name">Tag Type</label>
                  <select
                    className="custom-select form-control"
                    aria-label="Default select example"
                    required={true}
                    disabled={!isEditing}
                    onChange={(e) => setTagType(e.target.value)}
                  >
                    <option value="" disabled hidden>
                      Tag Type
                    </option>
                    <option
                      value="companyType"
                      selected={tagType === "company"}
                    >
                      Company Type
                    </option>
                    <option value="product" selected={tagType === "product"}>
                      Product Type
                    </option>
                    <option value="solution" selected={tagType === "solution"}>
                      Solution Type
                    </option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    value={tagDescription}
                    disabled={!isEditing}
                    // required={true}
                    onChange={(e) => setTagDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          className="modal fade delete-modal"
          id="validation"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="delete-tag">
                  <p>Are you sure you want to delete this tag?</p>
                  <span className="btn Yes-btn" onClick={handleDeleteTag}>
                    Yes
                  </span>
                  <button
                    type="button"
                    className="btn No-btn"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationToast show={status === "success"} message={message} />
    </>
  );
}
