import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../utils/types'

const initialState: RootState['thirdPartyUser'] = true;

export const thirdPartyUser = createSlice({
 name: 'thirdPartyUser',
 initialState,
 reducers: {
    setThirdPartyUser: (state, action: PayloadAction<boolean>) => {
      // state = action.payload;
      return action.payload;
    },
 },
});

export default thirdPartyUser.reducer;

export const { setThirdPartyUser } = thirdPartyUser.actions;
