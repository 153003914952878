import axios from "../../../adapters/axios";
import { User } from "../../utils/types";

export const fetchNotifications = async () => {
  const response = await axios.get(`user/notifications`);
  return response.data;
};

export const httpAcceptTerms = async (user: User) => {
  const response = await axios.patch("/users/acceptTerms", user);
  return response.data;
};
