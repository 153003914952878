import { MouseEventHandler, useEffect } from "react";
import { useState } from "react";
import { RxCross2 as Cross } from "react-icons/rx";
import { GrEdit as Pencil } from "react-icons/gr";
import { AiFillSave as Save } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { Flag } from "../../redux/utils/types";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addFlag,
  deleteFlag,
  editFlag,
} from "../../redux/modules/companyDetails/actions";
import { selectCompanyDetails } from "../../redux/modules/companyDetails/selector";
import { reset } from "../../redux/modules/companyDetails/slice";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import { Contributors } from "../Contributors";
import { authSelector } from "../../redux/modules/auth/userSelector";
import NotificationToast from "../NotificationToast";

export default function ViewAddFlags(props: {
  closeTab: MouseEventHandler<HTMLSpanElement>;
  flags: any[];
  setFlags: Function;
  contributors?: string[];
}) {
  const { closeTab, flags, setFlags, contributors } = props;
  // const [flags, setFlags] = useState([{ text: "" }]);
  const { pathname } = useLocation();

  const { user } = useSelector(authSelector);

  //error : when user adds nugget without inputing in text in last nugget
  const [newFlag, setNewFlag] = useState<string>("");
  const [isNewFlag, setIsNewFlag] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<any>();

  const handelAddNewFlag = () => {
    if (!newFlag) return;
    const id = Number(pathname.split("/")[3]);
    const payload = { companyId: id, flag: { flag: newFlag } };
    setIsLoading(true);
    dispatch(addFlag(payload));
  };

  const { status, message } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      setIsNewFlag(false);
      setNewFlag("");
      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  const latestUpdate = getLatestUpdate(flags);

  let lastUpdate = "";
  if (latestUpdate) {
    const { time, day, date } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  return (
    <>
      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">Flags&nbsp;</h5>
                        </div>
                        <div>
                          <Time time={lastUpdate} />
                        </div>
                      </div>

                      <div className="top-end">
                        <div></div>
                        {user.role === "user" && (
                          <div>
                            <Link
                              className="btn suggest-btn"
                              to="suggestion/flag"
                            >
                              Suggest Update
                            </Link>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bg mt-2">
                      <FlagList flags={flags} />
                      {isNewFlag && (
                        <div className="my-3 d-flex align-items-center">
                          <input
                            value={newFlag}
                            className="border-0 w-75 form-control"
                            placeholder="Enter Flag information"
                            onChange={(e) => setNewFlag(e.target.value)}
                          />

                          <div>
                            <button
                              className="btn btn-success mx-2"
                              onClick={handelAddNewFlag}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-secondary mx-2"
                              onClick={() => setIsNewFlag(false)}
                            >
                              Cancel
                            </button>
                          </div>

                          {isLoading && (
                            <Spinner
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </div>
                      )}
                      <div className="bg-inner mt-2">
                        <div className="top">
                          <div className="top-start">
                            <p className="key">
                              Key Contributors &gt;
                              <Contributors contributors={contributors ?? []} />
                            </p>
                          </div>
                          <div className="top-end">
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      {user.role === "admin" && (
                        <div className="text-right my-4">
                          <span
                            className="btn back-btn add-btn"
                            role="button"
                            onClick={() => setIsNewFlag(true)}
                          >
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Flag
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationToast message={message} show={status === "success"} />
      </div>
    </>
  );
}

function FlagList(props: { flags: Flag[] }) {
  const { flags } = props;
  return (
    <div>
      {flags.map((f: Flag, i: number) => (
        <FlagItem key={f.id} flag={f} />
      ))}
    </div>
  );
}

function FlagItem(props: { flag: Flag }) {
  const { flag } = props;
  const { pathname } = useLocation();

  const [isEditing, setIsEditing] = useState(false);
  const { user } = useSelector(authSelector);

  const companyId = Number(pathname.split("/")[3]);

  const [flagInput, setFlagInput] = useState(flag.flag);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<any>();

  const handleUpdateFlag = async () => {
    if (!flagInput) return;
    const newFlag = { ...flag, flag: flagInput };
    setIsLoading(true);
    const payload = { companyId, flag: newFlag };
    dispatch(editFlag(payload));
  };

  const handleDeleteFlag = () => {
    const id = Number(pathname.split("/")[4]);
    setIsLoading(true);
    const payload = { companyId, flagId: flag.id };
    dispatch(deleteFlag(payload));
  };

  const { status } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      setIsEditing(false);
    }
  }, [status]);

  return (
    <div className="my-3 d-flex align-items-center">
      <input
        value={flagInput}
        className="border-0 w-75 form-control list__test"
        placeholder="Enter Flag Detail"
        disabled={user.role === "user" || !isEditing}
        onChange={(e) => setFlagInput(e.target.value)}
      />

      {user.role === "admin" && (
        <div className="m-2">
          {!isEditing ? (
            <Pencil
              className="h4 mx-2"
              role="button"
              title="Edit Nugget"
              onClick={() => setIsEditing(true)}
            />
          ) : (
            // <Save
            //   className="h3 mx-2"
            //   role="button"
            //   title="Save"
            //   onClick={handleUpdateFlag}
            // />

            <>
              <button
                className="btn btn-success mx-2"
                onClick={handleUpdateFlag}
                title="Save"
              >
                Save
              </button>
              <button
                className="btn btn-secondary mx-2"
                onClick={() => setIsEditing(false)}
                title="Save"
              >
                Cancel
              </button>
            </>
          )}
          <Cross
            className="text-danger h4"
            role="button"
            onClick={handleDeleteFlag}
            title="Delete Nugget"
          />
          {isLoading && <Spinner style={{ width: "50px", height: "50px" }} />}
        </div>
      )}
    </div>
  );
}
