import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { createCompany } from "../../redux/modules/company/actions";
import { reset } from "../../redux/modules/company/slice";
import Nuggets from "../../components/Nuggets";
import KeySources from "../../components/KeySources";
import Tags from "../../components/Tags";
import KeyPartners from "../../components/KeyPartners";
import Flags from "../../components/Flags";
import AddNuggets from "../../components/AddNuggets";
import AddTags from "../../components/AddTags";
import AddKeySources from "../../components/AddKeySources";
import AddKeyPartners from "../../components/AddKeyPartners";
import { Tag, Company, Request, partnerCompany } from "../../redux/utils/types";
import AddFlag from "../../components/AddFlag";
import { useEffect } from "react";
import { companyInfoSelector } from "../../redux/modules/company/selectors";

import { NavbarToggler } from "../../components/NavbarToggler";
import NotificationToast from "../../components/NotificationToast";
import Notification from "../../components/Notification";
import TopBar from "./TopBar";

type Tab = "none" | "nuggets" | "tags" | "keySources" | "keyPartners" | "flags";
type FlagColor = "none" | "orange" | "black" | "green";

export default function CreateCompany() {
  const [currentTab, setCurrentTab] = useState<Tab>("none");
  const [newCompanyId, setNewCompanyId] = useState<number>(0);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const { status, message } = useSelector(companyInfoSelector);

  const [nuggets, setNuggets] = useState<any>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [flags, setFlags] = useState([]);
  const [keyPartners, setKeyPartners] = useState<partnerCompany[]>([]);
  const [keySources, setKeySources] = useState<any>([]);

  useEffect(() => {
    if (status === "success") {
      if (newCompanyId)
        navigate(`/companies/view/${newCompanyId}`, {
          replace: true,
          state: { isNewCompany: true },
        });
    }
    if (status === "failed") {
      const handler = setTimeout(() => dispatch(reset()), 5000);

      return () => clearTimeout(handler);
    }
  }, [status, dispatch, newCompanyId, navigate]);

  //this function will only be called in create company page
  const handleCreateCompany = async (company: any) => {
    const companyData: any = {};
    companyData.company = company;
    companyData.nuggets = nuggets.map((nugget: any) => nugget.nugget);
    companyData.tags = tags.map((tag: any) => tag.id);
    companyData.keySources = keySources.map((source: any) => source.source);
    companyData.flags = flags.map((flag: any) => flag.flag);
    companyData.keyPartners = keyPartners;

    const newCompany = await dispatch(createCompany(companyData));
    setNewCompanyId(newCompany.payload.id);
  };
  const goBackHandler = () => {
    switch (currentTab) {
      case "nuggets":
      case "tags":
      case "keySources":
      case "keyPartners":
      case "flags":
        setCurrentTab("none");
        break;
      default:
        navigate(-1);
    }
  };

  return (
    <>
      <div className="page-content bg-white" id="content">
        {status === "loading" && <Spinner />}

        <div className="row-header text-right">
          <NavbarToggler />
        </div>

        <TopBar
          handleCreateCompany={handleCreateCompany}
          handleBack={goBackHandler}
        />

        {/* company details */}
        <div className="company-details">
          <div className="container-fluid inner-box">
            {currentTab === "none" ? (
              <div className="row d-flex justify-content-center">
                <div className="col-xl-7">
                  <Nuggets
                    selectTab={() => setCurrentTab("nuggets")}
                    nuggets={nuggets}
                    // contributors={keyCont.nugget}
                  />
                  <KeySources
                    selectTab={() => setCurrentTab("keySources")}
                    keySources={keySources}
                    // contributors={keyCont.source}
                  />
                </div>
                <div className="col-xl-5">
                  <Tags
                    selectTab={() => setCurrentTab("tags")}
                    tags={tags}
                    // contributors={keyCont.tag}
                  />
                  <KeyPartners
                    selectTab={() => setCurrentTab("keyPartners")}
                    keyPartners={keyPartners}
                  />
                  <div className="flags mt-3">
                    <Flags
                      selectTab={() => setCurrentTab("flags")}
                      flags={flags}
                      // contributors={keyCont.flag}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <AddNuggets
                  closeTab={() => setCurrentTab("none")}
                  nuggets={nuggets}
                  setNuggets={setNuggets}
                  show={currentTab === "nuggets"}
                />
                <AddTags
                  closeTab={() => setCurrentTab("none")}
                  tags={tags}
                  setTags={setTags}
                  show={currentTab === "tags"}
                />
                <AddKeySources
                  closeTab={() => setCurrentTab("none")}
                  keySources={keySources}
                  setKeySources={setKeySources}
                  show={currentTab === "keySources"}
                />
                <AddFlag
                  closeTab={() => setCurrentTab("none")}
                  flags={flags}
                  setFlags={setFlags}
                  show={currentTab === "flags"}
                />
                <AddKeyPartners
                  closeTab={() => setCurrentTab("none")}
                  keyPartners={keyPartners}
                  setKeyPartners={setKeyPartners}
                  show={currentTab === "keyPartners"}
                />
              </>
            )}
          </div>
        </div>

        {/* <NotificationToast show={status === "success"} message={message} /> */}
        {status === "failed" && (
          <Notification message={message} status={status} />
        )}
      </div>
    </>
  );
}
