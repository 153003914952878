import { useState } from "react";
import axios from "../../adapters/axios";
import Spinner from "../../components/Spinner";
import Popup from "../../components/Modal";
// import { GoogleLogin } from 'react-google-login';
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

type state = "loading" | "idle" | "success" | "failed";

export default function Migration() {
  const [state, setState] = useState("idle");
  const [inputLink, setInputLink] = useState("");

  /* global google */
  const win: any = window;
  const google: any = win.google;

  const httpMigrateData = async (doc_id: string, google_token: string) => {
    // await axios.post(
    //   "/migrate",
    //   { doc_id },
    //   {
    //     headers: {
    //       google_token,
    //     },
    //   }
    // );
    await axios.post(
      "/migrate",
      { doc_id, google_token },
      {
        headers: {
          google_token,
        },
      }
    );
  };

  const httpPopulateData = async (doc_id: string, google_token: string) => {
    await axios.post("/create-list", { doc_id }, { headers: { google_token } });
  };

  const isUrlValid = (url: string): boolean => {
    let res = url.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  };

  const getSheetId = (url: string) => {
    // event.preventDefault();
    let sheetUrl = url.split("/").splice(0, 6);
    if (
      isUrlValid(url) &&
      sheetUrl.length === 6 &&
      sheetUrl[2] === "docs.google.com"
    ) {
      // setGoogleSheetId(sheetUrl[5]);
      return sheetUrl[5];
    }
  };

  const handleMigrateData: any = async () => {
    if (!inputLink) return;
    const doc_id = getSheetId(inputLink) || "";

    const test = await google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: "https://www.googleapis.com/auth/spreadsheets",
      callback: async (tokenResponse: any) => {
        try {
          setState("loading");
          await httpMigrateData(doc_id, tokenResponse.access_token);
          setState("success");
        } catch (err) {
          setState("idle");
          console.error(err);
        }
      },
    });
    const token = await test.requestAccessToken();
  };

  const handlePopulateData = async () => {
    if (!inputLink) return;
    const doc_id = getSheetId(inputLink) || "";

    const test = await google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: "https://www.googleapis.com/auth/spreadsheets",
      callback: async (tokenResponse: any) => {
        try {
          setState("loading");
          await httpPopulateData(doc_id, tokenResponse.access_token);
          setState("success");
        } catch (err) {
          setState("idle");
          console.error(err);
        }
      },
    });
    const token = await test.requestAccessToken();
  };

  return (
    <>
      {state === "loading" && <Spinner />}
      <Popup
        show={state === "success"}
        message="Data Migrated Successfully"
        handleClose={() => setState("idle")}
      />
      <div className="page-content" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <h2 className="head">Companies</h2>
              <div className="d-flex">
                <button
                  className="btn suggest-btn mx-3"
                  onClick={handleMigrateData}
                >
                  Import Google Sheet
                </button>
                <button
                  className="btn suggest-btn"
                  onClick={handlePopulateData}
                >
                  Populate Sheet in Alphabetical Order
                </button>
              </div>
              <div className="toggler">
                <button
                  className="btn nav-btn navbar-toggler d-block d-lg-none"
                  type="button"
                >
                  <span className="fa fa-bars"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="px-3">
            <div className="form-group">
              <label htmlFor="importdata">Import Data from Google sheet</label>
              <input
                type="url"
                className="form-control bg-light"
                id="importdata"
                placeholder="Enter Google sheet link"
                value={inputLink}
                onChange={(e) => setInputLink(e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* <GoogleLogin
    clientId="629515516716-cec287dvhpqn6fiefioe4thtqpt1ri8o.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  /> */}
      </div>
    </>
  );
}
