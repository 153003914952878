import { MouseEventHandler } from "react";
import { Tag } from "../../redux/utils/types";
import { Contributors } from "../Contributors";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import { useLocation } from "react-router-dom";
export default function Tags(props: {
  selectTab: MouseEventHandler<any>;
  tags: Tag[];
  contributors?: string[];
}) {
  const { selectTab, tags, contributors } = props;
  const latestUpdate = getLatestUpdate(tags);
  let lastUpdate = "";

  if (latestUpdate) {
    const { time, date, day } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  const { pathname } = useLocation();
  const view = pathname.split("/")[2];

  return (
    <div className="tags">
      <div className="card w-100">
        <div className="card-body">
          <div className="top">
            <div className="top-start">
              <div>
                <h5 className="card-title">Tags&nbsp;</h5>
              </div>
              <div>{view === "view" && <Time time={lastUpdate} />}</div>
            </div>
            <div className="top-end">
              <div>
                <span onClick={selectTab} role="button">
                  <img alt="" src="/img/Vector.png" className="pl-3" />
                </span>
              </div>
            </div>
          </div>

          <div className="bg mt-2">
            <div className="bg-inner scroll-tag mt-2">
              {tags.map(({ tagName, id }) => (
                <p
                  key={id}
                  className="p-2 border rounded my-3"
                  style={{ backgroundColor: "lightgrey" }}
                >
                  {tagName}
                </p>
              ))}
            </div>
            <div className="bg-inner mt-2">
              <p className="key">
                Key Contributors &gt;
                <Contributors contributors={contributors || []} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
