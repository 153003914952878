import styles from "./styles.module.css";
import { ReactComponent as Loader } from "../../assets/spinner2.svg";
export default function Spinner() {
  return (
    <>
      <div className={styles.loader}>
        <Loader style={{ width: "100px" }} />
      </div>
    </>
  );
}
