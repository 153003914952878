import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchAllNotifications, postNotification } from './api'
import { updateNotifications } from './slice'



export const getAllNotifications = createAsyncThunk(
  'notifications/get',
  async (_, thunkAPI) => {
    try {
      const notifications: any = await fetchAllNotifications()
      thunkAPI.dispatch(updateNotifications(notifications))
      return notifications
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const sendNotification = createAsyncThunk(
  'notification/send',
  async (
    notification: { title: string; subtitle: string; userIds: number[] },
    thunkAPI
  ) => {
    try {
      return await postNotification(notification)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
