import axios from "../../../adapters/axios";

export const httpEditNugget = async (id: number, nugget: any) => {
  const response = await axios.patch(`nuggets/${id}`, nugget);
  return response.data;
};

export const httpGetNuggets = async (id: number) => {
  const response = await axios.get(`companies/${id}/nuggets`);
  return response.data;
};

export const httpAddNugget = async (id: number, nugget: string) => {
  const response = await axios.post(`companies/${id}/nuggets`, {
    nugget: nugget,
  });
  return response.data;
};

export const httpDeleteNugget = async (id: number) => {
  const response = await axios.delete(`nuggets/${id}`);
  return response.data;
};

// tags apis

export const httpAddTag = async (companyId: number, tagId: number) => {
  const response = await axios.post(`companies/${companyId}/tags`, { tagId });
  return response.data;
};

export const httpDeleteTag = async (companyId: number, tagId: number) => {
  const response = await axios.delete(`companies/${companyId}/tags/${tagId}`);
  return response.data;
};

//keysources apis

export const httpAddKeySource = async (
  companyId: number,
  keySource: { source: string }
) => {
  const response = await axios.post(
    `companies/${companyId}/company-sources`,
    keySource
  );
  return response.data;
};

export const httpEditKeySource = async (
  id: number,
  keySource: { source: string }
) => {
  const response = await axios.patch(`company-sources/${id}`, keySource);

  return response.data;
};

export const httpDeleteKeySource = async (id: number) => {
  const response = await axios.delete(`company-sources/${id}`);
  return response.data;
};

// flags apis

export const httpAddFlag = async (
  companyId: number,
  flag: { flag: string }
) => {
  const response = await axios.post(`companies/${companyId}/flags`, flag);
  return response.data;
};

export const httpEditFlag = async (id: number, flag: { flag: string }) => {
  const response = await axios.patch(`flags/${id}`, flag);
  return response.data;
};

export const httpDeleteFlag = async (id: number) => {
  const response = await axios.delete(`flags/${id}`);
  return response.data;
};

// company partner apis

export const httpAddCompanyPartner = async (
  companyId: number,
  partnerId: number
) => {
  const response = await axios.post(
    `companies/${companyId}/partner-companies`,
    { partnerCompanyId: partnerId }
  );
  return response.data;
};

export const httpDeleteCompanyPartner = async (
  companyId: number,
  partnerId: number
) => {
  const response = await axios.delete(
    `companies/${companyId}/partner-companies/${partnerId}`
  );
  return response.data;
};

export const httpAddCompanyPartnerName = async (
  companyId: number,
  companyName: string
) => {
  const response = await axios.post(`companies/${companyId}/createAndPartner`, {
    companyName,
  });

  return response.data;
};

export const httpAddPartnerLink = async (
  companyId: number,
  partnerLink: string
) => {
  const response = await axios.post(`companies/${companyId}/addLinkPartner`, {
    partnerLink,
  });
  return response.data;
};

export const httpDeletePartnerLink = async (
  companyId: number,
  partnerId: number
) => {
  const response = await axios.delete(`partner-companies/${partnerId}`);
  return response.data;
};
