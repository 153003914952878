import axios from '../../../adapters/axios'

export const fetchAllNotifications = async () => {
  // const response = await axios.get('notification-details')
  //jugaad
  const response = await axios.get('user/notifications')
  return response.data
}

export const postNotification = async (notification: {
  title: string
  subtitle: string
  userIds: number[]
}) => {
  const response = await axios.post('notification-details', notification)
  return response.data
}
