import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { createTag } from "../../redux/modules/tags/actions";
import { tagsStatusSelector } from "../../redux/modules/tags/selector";
import Popup from "../../components/Modal";
import { reset } from "../../redux/modules/tags/slice";
import { useNavigate } from "react-router-dom";
import Back from "../../components/Buttons/Back";
import NotificationToast from "../../components/NotificationToast";
import { RootState } from "../../redux/utils/types";
// import SuccessModal from "../../components/SuccessModal";

export default function CreateTag() {
  const [tagName, setTagName] = useState("");
  const [tagType, setTagType] = useState("");
  const [tagDescription, setTagDescription] = useState("");

  const [newTagId, setNewTagId] = useState(0);

  const dispatch = useDispatch<any>();

  const { status } = useSelector((state: RootState) => state.tags);

  const navigate = useNavigate();

  const handleCreateTag = async () => {
    if (!tagName || !tagType || !tagDescription) return;
    const tag = { tagName, tagType, tagDescription };
    const res = await dispatch(createTag(tag));
    // navigate(`/tags/view/${res.payload.id}`);
    setNewTagId(res?.payload?.id);
  };

  useEffect(() => {
    if (status === "success" && newTagId) {
      navigate(`/tags/view/${newTagId}`, { replace: true });
    }
  }, [status, newTagId, navigate]);

  return (
    <>
      {status === "loading" && <Spinner />}

      <div className="page-content" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Tags</h2>
              <div className="toggler">
                <button
                  className="btn nav-btn navbar-toggler d-block d-lg-none"
                  type="button"
                >
                  <span className="fa fa-bars"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="notifications">
          <div className="container-fluid">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group form-buttons">
                <Back to="/tags" />
                <button
                  className="btn suggest-btn mx-2"
                  onClick={handleCreateTag}
                >
                  Create Tag
                </button>
              </div>

              <div className="notification-box">
                <div className="form-group">
                  <label htmlFor="tag">Tag Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="tag"
                    placeholder="Tag 01"
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    required={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="Company Name">Tag Type</label>
                  <select
                    className="custom-select form-control"
                    aria-label="Default select example"
                    required={true}
                    onChange={(e) => setTagType(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Tag Type
                    </option>
                    <option value="company">Company Type</option>
                    <option value="product">Product Type</option>
                    <option value="solution">Solution Type</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Add Description"
                    value={tagDescription}
                    // required={true}
                    onChange={(e) => setTagDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
