import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { NavbarToggler } from "../../../components/NavbarToggler";
import styles from "./style.module.css";
import Select from "react-select";
import CompanyList from "../../../components/CompanyList";
import { companySelector } from "../../../redux/modules/company/selectors";
import Spinner from "../../../components/Spinner";
import { getCompanyPage, getSortedCompanyPage } from "../../../redux/modules/company/actions";

interface SelectOption {
  value: string;
  label: string;
}

const selectOptions: SelectOption[] = [
  { value: 'nuggets', label: 'most active nuggets' },
  { value: 'sources', label: 'most active sources' },
  { value: 'partners', label: 'most active partners' },
];

export default function Analytics() {
  const dispatch = useDispatch<any>();
  const companiesMap = useSelector(companySelector);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption | null>(null);
  const [keyword, setKeyword] = useState("");
  const [flagged, setflagged] = useState(false);
  const [inSearch, setInSearch] = useState(true);
  const [tags, setTags] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const companiesArray = Array.from(companiesMap.values());

  const fetchCompanies = () => {
    if (selectedOptions) {
      //console.log(" selectedOptions")
      setLoading(true);
      dispatch(getSortedCompanyPage({
        pageNumber: currentPage,
        keyword,
        tags,
        sortBy: selectedOptions.value as "nuggets" | "sources" | "partners",
      })).finally(() => {
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
    else {
      //console.log("not selectedOptions")
      //setLoading(true);
      dispatch(
        getCompanyPage({
          pageNumber: currentPage,
          keyword,
          tags,
          flagged,
          inSearch
        })
      ).then(() => window.scrollTo({ top: 0, behavior: "smooth" }));
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, [selectedOptions, keyword, tags, currentPage, inSearch, flagged]);

  // Update selected options and reset to page 1
  const handleSelectChange = (option: SelectOption | null) => {
    setSelectedOptions(option);
    setCurrentPage(1); // Reset to the first page
  };

  // handle page changes
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="page-content p-0 bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Analytics</h2>
              <NavbarToggler />
            </div>
          </div>
          <div className="row d-flex ml-3">
            <div className={styles.select_container}>
              <Select<SelectOption, false>
                placeholder="Most Active Companies"
                closeMenuOnSelect={true}
                options={selectOptions}
                onChange={handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid px-0">
          <CompanyList
            hideFlags={true}
            filters={{
              keyword,
              tags,
              inSearch: true,
              setKeyword,
            }}
            sortedCompanies={companiesArray}
            onPageChange={handlePageChange}
          />
        </div>
        <div className="footer">
          <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
        </div>
      </div>
    </>
  );
}