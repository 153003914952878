import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CompanyList from "../../components/CompanyList";
import { RootState } from "../../redux/utils/types";
import NotificationToast from "../../components/NotificationToast";
import { reset } from "../../redux/modules/company/slice";
import { NavbarToggler } from "../../components/NavbarToggler";
import Spinner from "../../components/Spinner";
import axios from "../../adapters/axios";

export default function AdminCompanies() {
  const [searchInput, setSearchInput] = useState("");
  const [tags, setTags] = useState([]);

  const [aisearchInput, setAISearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { status, message } = useSelector((state: RootState) => state.company);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => dispatch(reset()), 5000);
    }
  }, [status, dispatch]);


  const handleAIGenerator = async () => {
    try {
      setIsLoading(true);
      const params = { question: aisearchInput }; // Define parameters object
      const {data, status, statusText} = await axios.get('aiGenerator', { params }); 
      if (status === 200) {
        setIsLoading(false);
        navigate("/airesponse", {state: {responseData: data, searchInput: aisearchInput} });
      } else {
        console.error("Error:", statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {status === "loading" && <Spinner />}
      <div className="page-content" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <h2 className="head">Companies</h2>
              <Link to="new" className="btn suggest-btn" role="button">
                Create Company Profile
              </Link>
              <NavbarToggler />
            </div>
          </div>

          <div className="col-md-10 m-auto">
            <div className="input-box">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Keyword Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        
        <div
          // className="row search-bar d-flex justify-content-center"
          // style={{ paddingTop: "1rem" }}
          className="col-md-11 m-auto"
        >
          <div className="col-md-12">
            <div className="input-box">
              <div className="form-group has-search d-flex align-items-center">
                <span className="fa fa-search form-control-feedback d-flex align-items-center"></span>
                <input
                  type="text"
                  className="form-control"
                  style={{ fontSize: "18px" }}
                  placeholder="Ask AI a Question to complement your Wendwise research"
                  value={aisearchInput}
                  onChange={(e) => setAISearchInput(e.target.value)}
                />
                <button
                  // href="search-result.html"
                  className="col-md-3 btn btn-info btn-sm ml-4 rounded-pill"
                  style={{ fontSize: "16px" }}
                  id="user-sbtn"
                  onClick={handleAIGenerator}
                  disabled={!aisearchInput.trim()}
                >
                  Ask AI*
                </button>
              </div>
              <div className="">
                <p className="text-dark" style={{ fontStyle: "italic", fontWeight: "600", fontSize: "13px" }}>*Please enter any question or topic that can enhance your research. The output is limited by design. Please do not enter credentials or private information - (Refer to our terms of service)</p>
              </div>
            </div>
          </div>
        </div>
        <CompanyList
          filters={{
            keyword: searchInput,
            tags: tags,
            setKeyword: setSearchInput,
          }}
        />
        {isLoading && <Spinner />}
      </div>
      <NotificationToast show={status === "success"} message={message} />
    </>
  );
}

