export default function Unauthorized() {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="text-center">
        {/* <img className='w-50' src="/img/unauthorized.png" alt="401 error" /> */}
        <span style={{ fontSize: "5rem" }}>🙅</span>
        <h2 className="text-danger">
          You Are Not Authorised to Access this Page
        </h2>
        <h4 style={{ color: "grey" }}>Please contact to admin</h4>
      </div>
    </div>
  );
}
