import { MouseEventHandler, useEffect, useRef } from "react";
import { useState } from "react";
import { RxCross2 as Cross } from "react-icons/rx";
import { GrEdit as Pencil } from "react-icons/gr";
import { AiFillSave as Save } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import { Nugget } from "../../redux/utils/types";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addNugget,
  deleteNugget,
  editNugget,
} from "../../redux/modules/companyDetails/actions";
import { selectCompanyDetails } from "../../redux/modules/companyDetails/selector";
import { reset } from "../../redux/modules/companyDetails/slice";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import NotificationToast from "../NotificationToast";
import { Contributors } from "../Contributors";
import { authSelector } from "../../redux/modules/auth/userSelector";
import Notification from "../Notification";

export default function ViewAddNuggets(props: {
  closeTab: MouseEventHandler<HTMLSpanElement>;
  nuggets: any[];
  setNuggets: Function;
  contributors: string[];
}) {
  const { closeTab, nuggets, setNuggets, contributors } = props;
  // const [nuggets, setNuggets] = useState([{ text: "" }]);
  const { pathname } = useLocation();

  const { user } = useSelector(authSelector);
  //type can be either view or new

  //error : when user adds nugget without inputing in text in last nugget
  const [newNugget, setNewNugget] = useState<string>("");
  const [isNewNugget, setIsNewNugget] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<any>();

  const handleAddNugget = async () => {
    const id = Number(pathname.split("/")[3]);
    const payload = { companyId: id, nugget: newNugget };
    setIsLoading(true);
    const test = await dispatch(addNugget(payload));
  };

  const { status, message } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success" || status === "failed") {
      setIsLoading(false);
      setIsNewNugget(false);
      setNewNugget("");

      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  const latestUpdate = getLatestUpdate(nuggets);

  let lastUpdate = "";
  if (latestUpdate) {
    const { time, day, date } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  return (
    <>
      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">Nuggets&nbsp;</h5>
                        </div>
                        <div>
                          <Time time={lastUpdate} />
                        </div>
                      </div>

                      <div className="top-end">
                        {user.role === "user" && (
                          <div>
                            <Link
                              className="btn suggest-btn"
                              to="suggestion/nugget"
                            >
                              Suggest Update
                            </Link>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bg mt-2">
                      <NuggetList nuggets={nuggets} />
                      {isNewNugget && (
                        <div className="my-3 d-flex align-items-center">
                          <input
                            value={newNugget}
                            className="border-0 w-75 form-control"
                            placeholder="Enter Nugget Information"
                            onChange={(e) => setNewNugget(e.target.value)}
                          />
                          <div>
                            <button
                              className="btn btn-success mx-2"
                              onClick={handleAddNugget}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-secondary mx-2"
                              onClick={() => setIsNewNugget(false)}
                            >
                              Cancel
                            </button>
                          </div>
                          {isLoading && (
                            <Spinner
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </div>
                      )}
                      <div className="bg-inner mt-2">
                        <div className="top">
                          <div className="top-start">
                            <p className="key">
                              Key Contributors &gt;
                              <Contributors contributors={contributors ?? []} />
                            </p>
                          </div>
                          <div className="top-end">
                            <div></div>
                            <div></div>
                          </div>
                        </div>
                      </div>

                      {user.role === "admin" && (
                        <div className="text-right my-4">
                          <span
                            className="btn back-btn add-btn"
                            role="button"
                            onClick={() => setIsNewNugget(true)}
                          >
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Nuggets
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {<NotificationToast show={status === "success"} message={message} />}
        {/* {<Failed} */}
        {/* <NotificationToast show={true} message="hello bhai" /> */}
        {/* <Notification status={status} message={message} /> */}
      </div>
    </>
  );
}

function NuggetList(props: { nuggets: Nugget[] }) {
  const { nuggets } = props;
  return (
    <div>
      {nuggets.map((nugget: any, i: number) => (
        <NuggetItem key={nugget.id} nugget={nugget} />
      ))}
    </div>
  );
}

function NuggetItem(props: { nugget: Nugget }) {
  const { nugget } = props;
  const { pathname } = useLocation();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useSelector(authSelector);
  const [nugInput, setNugInput] = useState(nugget.nugget);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<any>();

  const handleUpdateNugget = async () => {
    const newNugget = { ...nugget, nugget: nugInput };
    setIsLoading(true);
    dispatch(editNugget(newNugget));
  };

  const handleDeleteNugget = () => {
    setIsLoading(true);
    const id = Number(pathname.split("/")[3]);
    dispatch(deleteNugget({ companyId: id, nuggetId: nugget.id }));
  };

  const { status } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success" || status === "failed") {
      setIsLoading(false);
      setIsEditing(false);
    }
  }, [status]);

  // textarea auto height fix

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  }, [nugInput, textareaRef]);

  return (
    <div className="my-3 d-flex align-items-center">
      <textarea
        value={nugInput}
        className="border-0 w-75 form-control list__test"
        disabled={user.role === "user" || !isEditing}
        onChange={(e) => setNugInput(e.target.value)}
        ref={textareaRef}
      ></textarea>
      {user.role === "admin" && (
        <div className="m-2">
          {!isEditing ? (
            <Pencil
              className="h4 mx-2"
              role="button"
              title="Edit Nugget"
              onClick={() => setIsEditing(true)}
            />
          ) : (
            // <Save
            //   className="h3 mx-2"
            //   role="button"
            //   title="Save"
            //   onClick={handleUpdateNugget}
            // />
            <>
              <button
                className="btn btn-success mx-2"
                title="Save"
                onClick={handleUpdateNugget}
              >
                Save
              </button>
              <button
                className="btn btn-secondary mx-2"
                title="Cancel"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </button>
            </>
          )}
          <Cross
            className="text-danger h4"
            role="button"
            onClick={handleDeleteNugget}
            title="Delete Nugget"
          />
          {isLoading && <Spinner style={{ width: "50px", height: "50px" }} />}
        </div>
      )}
    </div>
  );
}
