import { MouseEventHandler } from "react";
import { useState } from "react";
import { RxCross2 as Cross } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";

export default function AddNuggets(props: {
  closeTab: MouseEventHandler<HTMLSpanElement>;
  nuggets: any[];
  setNuggets: Function;
  show?: boolean;
}) {
  const { closeTab, nuggets, setNuggets, show } = props;
  // const [nuggets, setNuggets] = useState([{ text: "" }]);
  const { pathname } = useLocation();

  const section = pathname.split("/")[1];
  //error : when user adds nugget without inputing in text in last nugget
  const [isError, setIsError] = useState<boolean>(false);

  const handleInputChange = (changedText: string, index: number) => {
    if (isError) setIsError(false);
    const list = [...nuggets];
    list[index].nugget = changedText;
    setNuggets(list);
  };

  const handleRemoveNugget = (index: number) => {
    const list = [...nuggets];
    list.splice(index, 1);
    setNuggets(list);
  };

  const handleAddNugget = () => {
    const lastNuggetIndex = nuggets.length - 1;
    const lastText = nuggets[lastNuggetIndex]?.nugget;

    if (!lastText && nuggets.length > 0) {
      setIsError(true);
      return;
    }

    setNuggets([...nuggets, { nugget: "" }]);
  };

  if (!show) {
    return <></>;
  }

  return (
    <div className="company-details">
      <div className="container-fluid inner-box">
        <div className="row d-flex justify-content-center">
          <div className="col-md-12 col-sm-12">
            {/* <!-- ------------------Nuggets------------- --> */}

            <div className="nugget-page">
              <div className="card w-100">
                <div className="card-body">
                  <div className="top d-flex justify-content-space">
                    <div className="top-start">
                      <div>
                        <h5 className="card-title">Nuggets&nbsp;</h5>
                      </div>
                    </div>

                    <div className="top-end">
                      <div></div>
                      {section === "user" && (
                        <div>
                          <Link className="btn suggest-btn" to="suggetion">
                            Suggest Update
                          </Link>
                        </div>
                      )}
                      <div>
                        <span role="button" onClick={closeTab}>
                          <img
                            src="/img/back.png"
                            className="pl-3 img-icon"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="bg mt-2">
                    <div>
                      {nuggets.map((nugget, i) => (
                        <div className="my-3 d-flex align-items-center">
                          <input
                            value={nugget.nugget}
                            className="border-0 w-75 form-control list__test"
                            placeholder="Enter Nugget Information"
                            disabled={section === "user"}
                            onChange={(e) =>
                              handleInputChange(e.target.value, i)
                            }
                            onKeyUp={(e) => {
                              if (e.keyCode === 13) {
                                handleAddNugget();
                              }
                            }}
                          />
                          {isError && i === nuggets.length - 1 && (
                            <span className="text-danger">Can't be Empty</span>
                          )}
                          {section !== "user" && (
                            <div
                              className="m-2"
                              onClick={() => handleRemoveNugget(i)}
                            >
                              <Cross className="text-danger h4" role="button" />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="bg-inner mt-2">
                      <div className="top">
                        <div className="top-end"></div>
                      </div>
                    </div>

                    {section !== "user" && (
                      <div className="text-right my-4">
                        <span
                          className="btn back-btn add-btn"
                          role="button"
                          onClick={handleAddNugget}
                        >
                          <span className="fa fa-plus-circle mr-2"></span>Add
                          Nuggets
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
