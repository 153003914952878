// export function linkify (text: string) {
//   var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
//   return text.replace(urlRegex, function (url) {
//     return '<a href="' + url + '">' + url + '</a>'
//     return url
//   })
// }

export function linkify (text: string): string {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi
  let link = ''
  text.replace(urlRegex, function (url) {
    link = url
    return '<a href="' + url + '">' + url + '</a>'
  })
  return link
}
