import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { authSelector } from "../../../redux/modules/auth/userSelector";
import { reset } from "../../../redux/modules/auth/slice";
import { login, logout, whoAmI } from "../../../redux/modules/auth/actions";

export default function AdminLogin() {
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const auth = useSelector(authSelector);
  // const user = useSelector(userSelector);

  const { status, message, user } = auth;

  const handleLogin = () => {
    const userCredentials = { email, password };
    localStorage.setItem("remember_me", keepSignedIn.toString());
    dispatch(login(userCredentials));
  };

  useEffect(() => {
    if (user) {
      if (user.role === "admin") {
        dispatch(reset());
        navigate("/home");
      } else {
        setError("You are Not Authorised to Login to Admin Site");
        dispatch(reset());
      }
    }
  }, [user, navigate, dispatch]);

  useEffect(() => {
    if (status === "failed") {
      dispatch(reset());
    }
    if (error) {
      setError("");
      dispatch(logout());
    }
  }, [email, password]);

  useEffect(() => {
    const token_persist = localStorage.getItem("remember_me");

    if (token_persist === "true") {
      setKeepSignedIn(true);
    } else {
      setKeepSignedIn(false);
    }
  }, []);

  return (
    <>
      {status === "loading" && <Spinner />}

      <div className="sign-up-wrap">
      <h1 className="logo">
          Wendwise
          <sup className="tm">TM</sup>
        </h1>
        <div className="form-container-wrap">
          <form
            className="form-container1"
            onSubmit={(e) => e.preventDefault()}
          >
            <h2>Admin Sign In</h2>
            <div className="form-group">
              {status === "failed" && (
                <p className="text-danger font-weight-bold">{message}</p>
              )}
              <p className="text-danger font-weight-bold">{error}</p>
              <label htmlFor="useremail">Email</label>
              <input
                type="email"
                className="form-control"
                id="useremail"
                placeholder="Enter Your email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="passwd">Password</label>
              <input
                type="password"
                className="form-control"
                id="passwd"
                placeholder="Enter Password"
                // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number, one uppercase, one lowercase letter, one special character and at least 10 characters"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="forgot-link d-flex align-items-center justify-content-between">
              <div className="custom-control custom-checkbox login-check">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="remember"
                  checked={keepSignedIn}
                  onChange={(_) => setKeepSignedIn(!keepSignedIn)}
                />
                <label htmlFor="remember" className="custom-control-label">
                  Keep me signed in
                </label>
              </div>
              {/* <Link to="/password-reset">Forgot Password?</Link> */}
            </div>
            <button
              type="submit"
              className="btn mt-5 btn-block"
              id="btn-submit"
              // onClick={() => navigate("/admin/home")}
              onClick={handleLogin}
            >
              Sign In
            </button>
          </form>
        </div>
        {/* <Spinner /> */}
      </div>
    </>
  );
}
