import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, User } from "../../utils/types";

import { enableMapSet } from "immer";
import { editUser, deleteUser, updatePassword } from "./actions";

enableMapSet();

const initialState: RootState["users"] = {
  status: "idle",
  message: "",
  map: new Map<string, User>(),
};

const users = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    updateUsers: (state, action: PayloadAction<User>) => {
      state.map.set(action.payload.id.toString(), action.payload);
    },

    //doubt
    getUserProfile: (state, action: PayloadAction<string>) => {
      const profile: any = state.map.get(action.payload);
      return profile;
    },

    reset: (state) => {
      state.status = "idle";
      state.message = "";
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteUser.fulfilled, (state, action: any) => {
        state.status = "success";
        state.message = "User Removed Successfully";

        const id = action.payload.id;
        const deleted = state.map.delete(String(id));

        // state.status = 'idle'
      })

      .addCase(deleteUser.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(editUser.pending, (state) => {
        state.status = "loading";
      })

      .addCase(editUser.fulfilled, (state, action) => {
        state.status = "success";
        const id = action.payload.id;
        //we'lll get edited user as payload
        state.map.set(id.toString(), action.payload);
        state.message = "User Updated Successfully";
      })

      .addCase(editUser.rejected, (state, action: any) => {
        state.status = "failed";
        if (action.payload.response?.data?.error?.code === "ER_DUP_ENTRY") {
          state.message = "Email Already Exists";
        } else {
          state.message = "Something went wrong, Pleaese try again !";
        }
      })

      .addCase(updatePassword.pending, (state) => {
        state.status = "loading";
      })

      .addCase(updatePassword.fulfilled, (state) => {
        state.status = "success";
        state.message = "Password Updated Successfully";
      });
  },
});

export default users.reducer;

export const { updateUsers, getUserProfile, reset } = users.actions;
