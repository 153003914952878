import { useParams, useNavigate } from "react-router-dom";
import { Request } from "../../redux/utils/types";
import { useDispatch, useSelector } from "react-redux";
import { requestsSelector } from "../../redux/modules/requests/selectors";
import { usersSelector } from "../../redux/modules/users/selectors";
import { closeRequest } from "../../redux/modules/requests/actions";
import Spinner from "../../components/Spinner";
import Popup from "../../components/Modal";
import { reset } from "../../redux/modules/requests/slice";
import Back from "../../components/Buttons/Back";
import { NavbarToggler } from "../../components/NavbarToggler";
export default function ViewRequest() {
  const { id = "0" } = useParams();
  const navigate = useNavigate();
  const { requests, status, message }: any = useSelector(requestsSelector);
  const request: Request = requests.find(
    (req: Request) => req.id.toString() === id.toString()
  );

  const users = useSelector(usersSelector);
  const userId = request.userId.toString();
  const username = users?.get(userId)?.user_id;
  const comment = request.comment;
  const date = new Date(request.createdAt).toDateString();
  const time = new Date(request.createdAt).toLocaleTimeString("en-IN", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const dispatch = useDispatch<any>();

  const handleCloseRequest = () => {
    const reqId = +id;
    dispatch(closeRequest(reqId));
  };

  const handleSuccessModal = () => {
    dispatch(reset());
    navigate(-1);
  };

  return (
    <>
      {status === "loading" && <Spinner />}
      <Popup
        show={status === "success"}
        handleClose={handleSuccessModal}
        message={message}
      />
      <div className="page-content p-0" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Requests</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="notifications">
          <div className="container-fluid">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group form-buttons">
                <Back />
                {request.status === 1 && (
                  <button
                    onClick={handleCloseRequest}
                    className="btn btn-primary request-btn pull-right text-white"
                  >
                    Close Requests
                  </button>
                )}
              </div>

              <div className="container notification-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="text">User ID</label>
                      <input
                        type="text"
                        className="form-control"
                        id="text"
                        placeholder="Your User ID"
                        value={username}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email ID</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Your Email ID"
                        value={request.emailId}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="date">Date</label>
                      <input
                        type="text"
                        className="form-control"
                        id="date"
                        value={date}
                        placeholder="DD/MM/YYYY"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="time">Time</label>
                      <input
                        type="text"
                        className="form-control"
                        id="time"
                        placeholder="00:00"
                        disabled={true}
                        value={time}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="user-comment">User Comment</label>
                  <textarea
                    className="form-control"
                    id="user-comment"
                    placeholder="user comment"
                    disabled={true}
                    value={comment}
                    style={{ minHeight: "200px" }}
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
