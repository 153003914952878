import { useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { authSelector } from "../../../redux/modules/auth/userSelector";
import { tagsSelector } from "../../../redux/modules/tags/selector";
import { useEffect, useState } from "react";
import { companySelector } from "../../../redux/modules/company/selectors";
import CompanyList from "../../../components/CompanyList";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import { RootState } from "../../../redux/utils/types";
import Spinner from "../../../components/Spinner";
import style from "./style.module.css";
import  axios from '../../../adapters/axios'

export default function Home() {
  const { user } = useSelector(authSelector);
  const tagsMap = useSelector(tagsSelector);
  const companiesMap = useSelector(companySelector);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { status } = useSelector((state: RootState) => state.company);
  let companiesArray = Array.from(companiesMap.values());

  const tags = Array.from(tagsMap.values());

  let companyTags: any = tags.filter((tag) => tag.tagType === "company");
  let productTags: any = tags.filter((tag) => tag.tagType === "product");
  let solutionTags: any = tags.filter((tag) => tag.tagType === "solution");

  const [searchInput, setSearchInput] = useState("");
  const [aisearchInput, setAISearchInput] = useState("");
  const [responseData, setResponseData] = useState("");
  const [tagsInput, setTagsInput] = useState<number[]>([]);

  const animatedComponents = makeAnimated();

  companyTags = companyTags.map((tag: any) => {
    return { value: tag.id, label: tag.tagName };
  });

  productTags = productTags.map((tag: any) => {
    return { value: tag.id, label: tag.tagName };
  });

  solutionTags = solutionTags.map((tag: any) => {
    return { value: tag.id, label: tag.tagName };
  });

  const [filters, setFilters] = useState<any>({
    company: [],
    product: [],
    solution: [],
  });

  useEffect(() => {
    setTagsInput([...filters.company, ...filters.product, ...filters.solution]);
  }, [filters]);

  // filter based on tags
  /*
`1. create array of all filter tag ids
2. create set of each company tags ids
3. if set contains any tagId from filter tag array, include else exclude
  */

  const filterSet = new Set<number>();

  filters.company.forEach((tag: number) => filterSet.add(tag));
  filters.product.forEach((tag: number) => filterSet.add(tag));
  filters.solution.forEach((tag: number) => filterSet.add(tag));

  companiesArray = companiesArray.filter((company) => {
    if (
      searchInput &&
      company.companyName.toLowerCase().includes(searchInput.toLowerCase())
    ) {
      return true;
    }
    if (company.companyTags) {
      for (const tag of company.companyTags) {
        if (filterSet.has(+tag.id)) {
          return true;
        }
      }
    }
    return false;
  });

  // }
  companiesArray = companiesArray.filter(
    (company) => company.flagColor !== "black" && company.flagColor !== "orange"
  );
  // ui fix
  const styles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const keywordInParam = searchParams.get("keyword");

  //AI Handling
  const handleAIGenerator = async () => {
    try {
      setIsLoading(true);
      const params = { question: aisearchInput }; // Define parameters object
      const {data, status, statusText} = await axios.get('aiGenerator', { params }); 
      if (status === 200) {
        setIsLoading(false);
        navigate("/airesponse", {state: {responseData: data, searchInput: aisearchInput} });
      } else {
        console.error("Error:", statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {!user && <Navigate to="/login" />}
      {/* {status === "loading" && <Spinner />} */}
      <div className="page-content bg-white" id="content">
        <div className="text-right">
          <div className="toggler p-2">
            <button
              className="btn nav-btn navbar-toggler d-block d-lg-none pull-right"
              type="button"
            >
              <span className="fa fa-bars"></span>
            </button>
          </div>
        </div>
        <div className="container">
          <div
            className="row search-bar d-flex justify-content-center"
            style={{ paddingTop: "1rem" }}
          >
            <div className="col-md-12">
              <div className="input-box">
                <div className="form-group has-search d-flex align-items-center">
                  <span className="fa fa-search form-control-feedback d-flex align-items-center"></span>
                  <input
                    type="text"
                    className="form-control"
                    style={{ fontSize: "18px" }}
                    placeholder="Keyword Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button
                    // href="search-result.html"
                    className="col-md-3 btn btn-info btn-sm ml-4 rounded-pill"
                    style={{ fontSize: "16px" }}
                    id="user-sbtn"
                    // onClick={handleSearch}
                  >
                    Wendwise Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex select-category justify-content-around">
            <div className={style.select_container}>
              <Select
                placeholder="Select Company Type Tag"
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={companyTags}
                className="form-input"
                isMulti={true}
                styles={styles}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    company: e.map((t: any) => t.value),
                  });
                }}
              />
            </div>
            <div className={style.select_container}>
              <Select
                placeholder="Select Product Type Tag"
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={productTags}
                className="form-input"
                isMulti={true}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    product: e.map((t: any) => t.value),
                  });
                }}
              />
            </div>
            <div className={style.select_container}>
              <Select
                placeholder="Select Solution Type Tag"
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={solutionTags}
                className="form-input"
                isMulti={true}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    solution: e.map((t: any) => t.value),
                  });
                }}
              />
            </div>
          </div>
          <div
            className="row search-bar d-flex justify-content-center"
            style={{ paddingTop: "1rem" }}
          >
            <div className="col-md-12">
              <div className="input-box">
                <div className="form-group has-search d-flex align-items-center">
                  <span className="fa fa-search form-control-feedback d-flex align-items-center"></span>
                  <input
                    type="text"
                    className="form-control"
                    style={{ fontSize: "18px" }}
                    placeholder="Ask AI a Question to complement your Wendwise research"
                    value={aisearchInput}
                    onChange={(e) => setAISearchInput(e.target.value)}
                  />
                  <button
                    // href="search-result.html"
                    className="col-md-3 btn btn-info btn-sm ml-4 rounded-pill"
                    style={{ fontSize: "16px" }}
                    id="user-sbtn"
                    onClick={handleAIGenerator}
                    disabled={!aisearchInput.trim()}
                  >
                    Ask AI*
                  </button>
                </div>
                <div className="">
                  <p className="text-dark" style={{ fontStyle: "italic", fontWeight: "600", fontSize: "13px" }}>*Please enter any question or topic that can enhance your research. The output is limited by design. Please do not enter credentials or private information - (Refer to our terms of service)</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row d-flex justify-content-center py-3">
            <button
              // href="search-result.html"
              className="btn btn-primary"
              id="user-sbtn"
            // onClick={handleSearch}
            >
              Wendwise Search
            </button>
          </div> */}
        </div>
        {/* {companiesArray.lengt > 0 && (
          <CompanyList companies={companiesArray} />
        )} */}
        {/* {(!!searchInput || filterSet.size !== 0 || keywordInParam?.trim()) && ( */}
        <CompanyList
          hideFlags={true}
          filters={{
            keyword: searchInput,
            tags: tagsInput,
            inSearch: true,
            setKeyword: setSearchInput,
          }}
        />
        {/* )} */}
        {isLoading && <Spinner />}
      </div>
    </>
  );
}