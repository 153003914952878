import { useState, useEffect, useRef, MouseEventHandler } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  companyInfoSelector,
  companySelector,
} from "../../redux/modules/company/selectors";
import {
  editCompany,
  removeCompany,
} from "../../redux/modules/company/actions";
import Prompt from "../../components/ConfirmationPrompt";
import Spinner from "../../components/Spinner";
import { reset } from "../../redux/modules/company/slice";
import { authSelector } from "../../redux/modules/auth/userSelector";
import { Company } from "../../redux/utils/types";
type FlagColor = "none" | "orange" | "black" | "green";

export default function TopBar(props: {
  handleCreateCompany: Function;
  handleBack: MouseEventHandler;
}) {
  const { status, message } = useSelector(companyInfoSelector);

  //compnay will be undefined if its a create compnay tab

  const { handleCreateCompany, handleBack } = props;

  const [companyName, setCompanyName] = useState("");
  const [flagColor, setFlagColor] = useState<FlagColor>("black");

  const handleSubmit = (e: any) => {
    if (formRef?.current?.checkValidity()) {
      //check if company already exists in the db

      const company = { companyName, flagColor };
      handleCreateCompany(company);
    } else {
      formRef?.current?.reportValidity();
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      {status === "loading" && <Spinner />}
      <div className="container-fluid">
        <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <div className="company">
            <div className="company-card">
              <div
                className="position-relative"
                role="button"
                // style={{ height: "80px" }}
              >
                <img
                  src="/img/company.png"
                  style={{ width: "100px" }}
                  alt="company-logo"
                />
              </div>
              <div>
                <textarea
                  id="companyname"
                  className="title company-input mt-2 form-control"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required={true}
                ></textarea>
              </div>
            </div>

            <div className="form-buttons">
              <button className="btn back-btn mx-2" onClick={handleBack}>
                Back
              </button>

              <button className="btn suggest-btn mx-2" onClick={handleSubmit}>
                Create Company
              </button>
            </div>
          </div>

          <div className="check-flags">
            <div className="custom-control custom-checkbox login-check">
              <input
                type="checkbox"
                className="custom-control-input"
                id="flag1"
                name="flag"
                value="green"
                checked={flagColor === "green"}
                onChange={(e) =>
                  flagColor === "green"
                    ? setFlagColor("none")
                    : setFlagColor("green")
                }
              />
              <label className="custom-control-label" htmlFor="flag1">
                <i className="fa fa-flag text-success"></i>
              </label>
            </div>
            <div className="custom-control custom-checkbox login-check">
              <input
                type="checkbox"
                className="custom-control-input"
                name="flag"
                id="flag2"
                value="red"
                checked={flagColor === "orange"}
                onChange={(e) =>
                  flagColor === "orange"
                    ? setFlagColor("none")
                    : setFlagColor("orange")
                }
              />
              <label className="custom-control-label" htmlFor="flag2">
                <i className="fa fa-flag text-warning"></i>
              </label>
            </div>
            <div className="custom-control custom-checkbox login-check">
              <input
                type="checkbox"
                name="flag"
                className="custom-control-input"
                id="flag3"
                value="black"
                // navigate("/admin/companies");
                checked={flagColor === "black"}
                onChange={(e) =>
                  flagColor === "black"
                    ? setFlagColor("none")
                    : setFlagColor("black")
                }
              />
              <label className="custom-control-label" htmlFor="flag3">
                <i className="fa fa-flag text-dark"></i>
              </label>
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-link my-2 see_flagbtn"
              data-toggle="modal"
              data-target="#flag-modal"
            >
              See flag details
            </button>
          </div>
        </form>
      </div>

      <div
        className="modal fade add-modal"
        id="flag-modal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content p-3">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="flag-content py-5 px-3">
                <p>
                  This section consist of the list of all the companies that
                  are:
                </p>
                <ol>
                  <li>
                    In Search results but have gaps in information(
                    <i className="fa fa-flag text-success"></i>)
                  </li>
                  <li>
                    Not in Search Results and have gaps in information(
                    <i className="fa fa-flag text-warning"></i>)
                  </li>
                  <li>
                    Not in Search Results and Company Profile is incomplete(
                    <i className="fa fa-flag text-dark"></i>)
                  </li>
                </ol>
                <p>
                  Users are invited to contact us in case they have information
                  regarding the information gaps in the Company Details and help
                  us build a more reliable information portal
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
