import axios from "../../../adapters/axios";
import { User } from "../../utils/types";

export const fetchUsers = async () => {
  const response = await axios.get("users");
  return response.data;
};

export const httpDeleteUser = async (id: string) => {
  return await axios.delete(`users/${id}`);
};

export const httpEditUser = async (id: string, user: any) => {
  return await axios.patch(`users/${id}`, user);
};

export const httpUpdatePassword = async (id: number, password: string) => {
  const response = await axios.post(`users/${id}/reset-password`, { password });
  return response.data;
};

export const httpAcceptTerms = async (user: User) => {
  const response = await axios.patch("/users/acceptTerms", user);
  return response.data;
};
