import { MouseEventHandler, useState } from "react";
import { Company } from "../../redux/utils/types";
import KeyPartnersPrompt from "../KeyPartnersPrompt";
import { RxCross2 as Cross } from "react-icons/rx";
import { useLocation } from "react-router-dom";
import CompanyNamePrompt from "../CompanyNamePrompt";
import CompanyLinkPrompt from "../CompanyLinkPrompt";

type Prompt = "company" | "name" | "link" | "none";

export default function AddKeyPartners(props: {
  closeTab: MouseEventHandler<any>;
  keyPartners: Company[];
  setKeyPartners: any;
  show?: boolean;
}) {
  const { closeTab, keyPartners, setKeyPartners, show } = props;

  const [prompt, setPrompt] = useState<Prompt>("none");

  const { pathname } = useLocation();

  const section = pathname.split("/")[1];

  const handleAddKeyPartner = (partners: any[]) => {
    const mappedPartner = partners.map((partner) => {
      if (partner.id) {
        //company already present in the database
        return { type: "company", name: partner.companyName, id: partner.id };
      } else if (partner.companyName) {
        //company not already present
        return { type: "new", name: partner.companyName };
      } else if (partner.partnerLink) {
        //link partner
        return { type: "link", name: partner.partnerLink };
      }
    });

    setPrompt("none");
    setKeyPartners([...keyPartners, ...mappedPartner]);
  };

  const handleRemovePartner = (index: number) => {
    const list = [...keyPartners];
    const newList = list.filter((partner: any, i: number) => i !== index);
    setKeyPartners(newList);
  };

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">Key Partners&nbsp;</h5>
                        </div>
                      </div>
                      <div className="top-end">
                        {section === "user" && (
                          <div>
                            <button className="btn suggest-btn">
                              Suggest Update
                            </button>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bg mt-2">
                      <div className="bg-inner mt-2">
                        {keyPartners.map((keyPartner: any, i: number) => {
                          return (
                            <div className="my-3 d-flex" key={i}>
                              <p className="border rounded w-75 p-2 bg-white list__test">
                                {keyPartner.name}
                              </p>
                              {section !== "user" && (
                                <div
                                  className="m-2"
                                  onClick={() =>
                                    // handleRemovePartner(keyPartner.id)
                                    handleRemovePartner(i)
                                  }
                                >
                                  <Cross
                                    className="text-danger h4"
                                    role="button"
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>

                      <div className="bg-inner mt-2">
                        <div className="top">
                          <div className="top-start">
                            <p className="key">
                              Key Contributer User ID &gt; abc
                            </p>
                          </div>
                        </div>
                      </div>
                      {section !== "user" && (
                        <div className="text-right my-4">
                          <span
                            className="btn key-btn"
                            role="button"
                            // onClick={() => setIsPrompt(true)}
                            onClick={() => setPrompt("company")}
                          >
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Key Partners
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <KeyPartnersPrompt
          // show={isPrompt}
          show={prompt === "company"}
          // handleCancel={() => setIsPrompt(false)}
          handleCancel={() => setPrompt("none")}
          handleOk={handleAddKeyPartner}
          selectedPartners={keyPartners}
          setPrompt={setPrompt}
        />

        <CompanyNamePrompt
          show={prompt === "name"}
          setPrompt={setPrompt}
          handleOk={handleAddKeyPartner}
        />

        <CompanyLinkPrompt
          show={prompt === "link"}
          setPrompt={setPrompt}
          handleOk={handleAddKeyPartner}
        />
      </>
    </>
  );
}
