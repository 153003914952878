import { MouseEventHandler, useState, useEffect } from "react";
import { RxCross2 as Cross } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { GrEdit as Pencil } from "react-icons/gr";
import { AiFillSave as Save } from "react-icons/ai";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addKeySource,
  deleteKeySource,
  editKeySource,
} from "../../redux/modules/companyDetails/actions";
import NotificationToast from "../NotificationToast";
import { selectCompanyDetails } from "../../redux/modules/companyDetails/selector";
import { reset } from "../../redux/modules/companyDetails/slice";
import { linkify } from "./utils";
import { FiExternalLink as LinkIcon } from "react-icons/fi";
import { Contributors } from "../Contributors";
import Time from "../Time";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import { authSelector } from "../../redux/modules/auth/userSelector";

export default function ViewAddKeySources(props: {
  closeTab: MouseEventHandler<any>;
  keySources: any[];
  setKeySources: Function;
  contributors?: string[];
}) {
  const { closeTab, keySources, setKeySources, contributors } = props;

  const { user } = useSelector(authSelector);

  const { pathname } = useLocation();

  const [isNewKeySource, setIsNewKeySource] = useState<boolean>(false);
  const [newKeySource, setNewKeySource] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch<any>();
  const companyId = Number(pathname.split("/")[3]);

  const handleAddKeySource = () => {
    setIsLoading(true);
    dispatch(addKeySource({ companyId, source: newKeySource }));
  };

  const { status, message } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      setIsNewKeySource(false);
      setNewKeySource("");

      const t = setTimeout(() => {
        dispatch(reset());
      }, 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  const latestUpdate = getLatestUpdate(keySources);

  let lastUpdate = "";
  if (latestUpdate) {
    const { time, day, date } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  return (
    <>
      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">
                            Key Sources and Links&nbsp;
                          </h5>
                        </div>
                        <div>
                          <Time time={lastUpdate} />
                        </div>
                      </div>
                      <div className="top-end">
                        {user.role === "user" && (
                          <div>
                            <Link
                              to="suggestion/source"
                              className="btn suggest-btn"
                            >
                              Suggest Update
                            </Link>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt="back"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bg mt-2">
                      <KeySourceList keySources={keySources} />
                      {isNewKeySource && (
                        <div className="my-3 d-flex align-items-center">
                          <input
                            value={newKeySource}
                            className="border-0 w-75 form-control"
                            placeholder="Enter sources and links"
                            onChange={(e) => setNewKeySource(e.target.value)}
                          />
                          <div>
                            <button
                              className="btn btn-success mx-2"
                              onClick={handleAddKeySource}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-secondary mx-2"
                              onClick={() => setIsNewKeySource(false)}
                            >
                              Cancel
                            </button>
                          </div>
                          {isLoading && (
                            <Spinner
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </div>
                      )}
                    </div>

                    <div className="bg-inner mt-2">
                      <div className="top">
                        <div className="top-start">
                          <p className="key">
                            Key Contributors &gt;
                            <span>
                              <Contributors contributors={contributors ?? []} />
                            </span>
                          </p>
                        </div>
                      </div>

                      {user.role === "admin" && (
                        <div
                          className="text-right my-4"
                          role="button"
                          onClick={() => setIsNewKeySource(true)}
                        >
                          <span className="btn key-btn">
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Key Resources & Links
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationToast message={message} show={status === "success"} />
    </>
  );
}

function KeySourceList(props: { keySources: any[] }) {
  const { keySources } = props;
  return (
    <div>
      {keySources.map((keySource, i) => (
        <KeySourceItem keySource={keySource} key={keySource.id} />
      ))}
    </div>
  );
}

function KeySourceItem(props: { keySource: any }) {
  const { keySource } = props;

  const { user } = useSelector(authSelector);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch<any>();

  const [sourceInput, setSourceInput] = useState(keySource.source);

  const [isLoading, setIsLoading] = useState(false);

  const { status } = useSelector(selectCompanyDetails);

  const handleUpdateKeySource = () => {
    setIsLoading(true);
    const newKeySource = { ...keySource, source: sourceInput };
    dispatch(editKeySource(newKeySource));
  };

  const handleDeleteKeySource = () => {
    setIsLoading(true);
    const { companyId, id: sourceId } = keySource;
    dispatch(deleteKeySource({ companyId, sourceId }));
  };

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      setIsEditing(false);
    }
  }, [status]);

  const url = linkify(sourceInput);

  return (
    <div className="my-3 d-flex align-items-center">
      <input
        value={sourceInput}
        onChange={(e) => setSourceInput(e.target.value)}
        className="border-0 form-control list__test"
        style={{ width: "65%" }}
        placeholder="Enter Key Source Information"
        disabled={user.role === "user" || !isEditing}
      />
      {url && (
        <a href={url} target="_blank" rel="noreferrer" title="Visit">
          <LinkIcon className="h4 mx-2" />
        </a>
      )}
      {user.role === "admin" && (
        <div className="m-2">
          {!isEditing ? (
            <Pencil
              className="h4 mx-2"
              role="button"
              title="Edit Key Source"
              onClick={() => setIsEditing(true)}
            />
          ) : (
            <Save
              className="h3 mx-2"
              role="button"
              title="Save"
              onClick={handleUpdateKeySource}
            />
          )}
          <Cross
            className="text-danger h4"
            role="button"
            onClick={handleDeleteKeySource}
            title="Delete KeySource"
          />
        </div>
      )}
      {isLoading && <Spinner style={{ width: "50px", height: "50px" }} />}
    </div>
  );
}
