import { useNavigate, useParams } from "react-router-dom";
import { AllNotificationsSelector } from "../../redux/modules/notifications/selectors";
import { useSelector } from "react-redux";
import { Notification } from "../../redux/utils/types";
import Back from "../../components/Buttons/Back";
import { NavbarToggler } from "../../components/NavbarToggler";
export default function NotificationDetail() {
  const navigate = useNavigate();
  const { id = "0" } = useParams();
  const notifications: Notification[] = useSelector(AllNotificationsSelector);
  const notifi: any = notifications.find(
    (n) => n.id.toString() === id.toString()
  );
  const { title, subtitle } = notifi;
  return (
    <div className="page-content p-0" id="content">
      <div className="row-header">
        <div className="container-fluid">
          <div className="align-items-center d-flex justify-content-between">
            <h2 className="head">Notifications</h2>
            <NavbarToggler />
          </div>
        </div>
      </div>

      <div className="container-fluid notifications">
        <div className="notification-head">
          <div>
            <h3>Notification Detail</h3>
          </div>
          <div>
            <Back />
          </div>
        </div>

        <div className="notification-box">
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              name="title"
              value={title}
              disabled={true}
            />
          </div>
          <div className="form-group subtitle">
            <label>Subtitle</label>
            <textarea
              className="form-control"
              value={subtitle}
              disabled={true}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}
