export function EmptyList(props: { title?: string; subtitle?: string }) {
  const {
    title = "Sorry! No result found :(",
    subtitle = "We're sorry what you're looking for, Please try after some time.",
  } = props;
  return (
    <tr>
      <td colSpan={7} style={{ height: "70vh" }}>
        <div className="result-screen d-flex flex-column align-items-center justify-content-center">
          <img src="/img/noData.jpg" alt="No Data Found" className="w-25" />
          <h5>{title}</h5>
          <p>{subtitle}</p>
        </div>
      </td>
    </tr>
  );
}
