import { Link, useSearchParams } from "react-router-dom";
import {
  Company,
  companySource,
  Flag,
  Nugget,
  RootState,
  Tag,
} from "../../redux/utils/types";
import { EmptyList } from "../EmptyList";
import { useEffect } from "react";
import styles from "./style.module.css";
import Paginate from "../Paginate";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyPage } from "../../redux/modules/company/actions";
import { escapeRegExp } from "lodash";

const Highlighted = (props: { text: string; highlights: string[] }) => {
  const { text = "", highlights = [] } = props;
  if (highlights.length === 0 || !text) {
    return <span>{text}</span>;
  }

  // Create a regex that matches any of the keywords
  const regex = new RegExp(`(${highlights.map(escapeRegExp).join("|")})`, "gi");
  const parts = text.split(regex);

  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <span style={{ background: "yellow" }} key={i}>
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  );
};

export default function CompanyList(props: {
  hideFlags?: boolean;
  filters: {
    keyword: string;
    tags: number[];
    flagged?: boolean;
    inSearch?: boolean;
    setKeyword?: Function;
  };
  sortedCompanies?: Company[];
  onPageChange?: (newPage: number) => void;

}) {
  // const { hideFlags = false, filters, sortedCompanies } = props;
  const { hideFlags = false, filters, sortedCompanies, onPageChange } = props;
  const {
    keyword = "",
    tags = [],
    flagged = false,
    inSearch = false,
    setKeyword = () => { },
  } = filters;

  const [searchParams, setSearchParams] = useSearchParams();
  const { pagination, map } = useSelector((state: RootState) => state.company);
  const { page, pageCount, total } = pagination;
  const dispatch = useDispatch<any>();
  const companies = sortedCompanies || Array.from(map.values()); // Use sortedCompanies if available

  useEffect(() => {
    let page = searchParams.get("page") ?? "1";
    const keyword = searchParams.get("keyword") ?? "";
    if (!page && !keyword) {
      const urlParam = new URLSearchParams();
      urlParam.append("page", "1");
      urlParam.append("keyword", "");
      setSearchParams(urlParam);
    } else {
      if (keyword?.trim()) {
        setKeyword(keyword);
      }
    }
  }, [searchParams, setSearchParams]);


  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(
        getCompanyPage({ pageNumber: 1, keyword, tags, flagged, inSearch })
      );
      const urlParams = searchParams;
      urlParams.set("page", "1");
      urlParams.set("keyword", keyword);
      setSearchParams(urlParams);
    }, 250);
    return () => clearTimeout(handler);
  }, [keyword, tags]);


  // const handlePageChange = (event: any) => {
  //   dispatch(
  //     getCompanyPage({
  //       pageNumber: event.selected + 1,
  //       keyword,
  //       tags,
  //       flagged,
  //       inSearch,
  //     })
  //   ).then(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  //   const urlParam = searchParams;
  //   urlParam.set("page", event.selected + 1);
  //   setSearchParams(urlParam);
  // };

  const handlePageChange = (event: any) => {
    const newPage = event.selected + 1;
    //console.log("bbb", props.onPageChange)
    if (props.onPageChange) {
      //console.log("dedsds", newPage)
      props.onPageChange(newPage);

    }

    if (!onPageChange) {
      // console.log("aaaa", event.selected + 1)
      dispatch(
        getCompanyPage({
          pageNumber: event.selected + 1,
          keyword,
          tags,
          flagged,
          inSearch,
        })
      ).then(() => window.scrollTo({ top: 0, behavior: "smooth" }));
      const urlParam = searchParams;
      urlParam.set("page", event.selected + 1);
      setSearchParams(urlParam);
    }
  };

  const keywords = keyword
    .split("+")
    .map(k => k.trim().toLowerCase())
    .filter(Boolean); // This filters out empty strings

  // Function to check if an item includes any of the keywords
  const includesAnyKeyword = (text: string) =>
    keywords.some(k => text?.toLowerCase().includes(k));

  // Check if the keyword array has any valid keywords
  const hasValidKeywords = keywords.length > 0;
  const tagSet = new Set(tags);

  return (
    <div className="container-fluid px-0">
      <div className="companies-table">
        <div className="table-responsive bg-white">
          <table className="table global-table table-stripped">
            <thead>
              <tr>
                <th>ID</th>
                <th className="text-left px-5">Company Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {companies?.map((company: Company, index) => {
                const { companyName, flagColor, id } = company;
                const nuggetsLength: Nugget[] | undefined = company?.nuggets;
                const sourcesLength: companySource[] | undefined = company?.companySources;
                const partnersLength: Company[] | undefined = company?.partners;

                let nuggets: Nugget[] | undefined;
                let tagsText: Tag[] | undefined;
                let sources: companySource[] | undefined;
                let partners: Company[] | undefined;
                let flags: Flag[] | undefined;

                if (hasValidKeywords) {
                  nuggets = company.nuggets?.filter(nugget => includesAnyKeyword(nugget.nugget));
                  tagsText = company.companyTags?.filter(tag => includesAnyKeyword(tag.tagName));
                  sources = company.companySources?.filter(source => includesAnyKeyword(source.source));
                  partners = company.partners?.filter(partner =>
                    includesAnyKeyword(partner.companyName ?? partner.partners)
                  );
                  flags = company.companyFlags?.filter(flag => includesAnyKeyword(flag.flag));
                }

                const tagsFilter: Tag[] | undefined =
                  tags.length > 0
                    ? company.companyTags?.filter(tag => tagSet.has(tag.id))
                    : undefined;

                return (
                  <tr key={id}>
                    <th>{id}</th>
                    <td className="pl-5">
                      <Link
                        to={`view/${id}`}
                        className={styles.name}
                        draggable
                        onDragStart={(e) =>
                          e.dataTransfer.setData("text/plain", companyName)
                        }
                      >
                        {companyName}
                      </Link>
                      {flagColor !== "none" && hideFlags === false && (
                        <i
                          className={`fa fa-flag ${flagColor === "orange"
                            ? "text-warning"
                            : flagColor === "green"
                              ? "text-success"
                              : flagColor === "black"
                                ? "text-dark"
                                : ""
                            } mx-2`}
                        ></i>
                      )}
                      <span> N : {(company.nuggets?.length ?? 0) || 0}</span>
                      <span> S : {(company.companySources?.length ?? 0) || 0}</span>
                      <span> P : {(company.partners?.length ?? 0) || 0}</span>

                      <div className={styles.details}>
                        {nuggets && nuggets.length > 0 && (
                          <div>
                            <h6>Nuggets</h6>
                            <ul>
                              {nuggets.map(nugget => (
                                <li key={nugget.id}>
                                  <Highlighted
                                    text={nugget.nugget}
                                    highlights={keywords}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {tagsText && tagsText.length > 0 && (
                          <div>
                            <h6>Tags</h6>
                            <ul>
                              {tagsText.map(tag => (
                                <li key={tag.id}>
                                  <Highlighted
                                    text={tag.tagName}
                                    highlights={keywords}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {sources && sources.length > 0 && (
                          <div>
                            <h6>Sources</h6>
                            <ul>
                              {sources.map(source => (
                                <li key={source.id}>
                                  <Highlighted
                                    text={source.source}
                                    highlights={keywords}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {partners && partners.length > 0 && (
                          <div>
                            <h6>Partners</h6>
                            <ul>
                              {partners.map(partner => (
                                <li key={partner?.id}>
                                  <Highlighted
                                    text={partner?.companyName ?? partner?.partners}
                                    highlights={keywords}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {flags && flags.length > 0 && (
                          <div>
                            <h6>Flags</h6>
                            <ul>
                              {flags.map(flag => (
                                <li key={flag.id}>
                                  <Highlighted
                                    text={flag.flag}
                                    highlights={keywords}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        {tagsFilter && tagsFilter.length > 0 && (
                          <div>
                            <h6>Selected Tags</h6>
                            <ul>
                              {tagsFilter.map(tag => (
                                <li key={tag.id}>
                                  <Highlighted
                                    text={tag.tagName}
                                    highlights={keywords}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="text-right px-5"></td>
                  </tr>
                );
              })}
              {companies.length === 0 && (
                <EmptyList title="No results found !" subtitle="" />
              )}
              <tr className={styles.__page_fix__}>
                <td colSpan={3}>
                  <div>
                    <Paginate
                      page={page}
                      pageCount={pageCount}
                      handlePageChange={handlePageChange}
                      total={total}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="footer">
        <Link to="/terms">Terms &amp; Condition Privacy Policy</Link>
      </div>
    </div>
  );
}