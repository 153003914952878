import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getCompanyDetails } from "../../redux/modules/company/actions";
import { reset } from "../../redux/modules/company/slice";
import Nuggets from "../../components/Nuggets";
import KeySources from "../../components/KeySources";
import Tags from "../../components/Tags";
import KeyPartners from "../../components/KeyPartners";
import Flags from "../../components/Flags";
import { Tag, Company, Request, partnerCompany } from "../../redux/utils/types";
import { useEffect } from "react";
import {
  companySelector,
  companyInfoSelector,
} from "../../redux/modules/company/selectors";
import ViewAddNuggets from "../../components/ViewAddNugget";
import ViewAddTags from "../../components/ViewAddTags";
import ViewAddKeySources from "../../components/ViewAddKeySources";
import ViewAddFlags from "../../components/ViewAddFlag";
import ViewAddKeyPartners from "../../components/ViewAddKeyPartners";
import { requestsSelector } from "../../redux/modules/requests/selectors";
import { usersSelector } from "../../redux/modules/users/selectors";
import { getKeyContributors } from "./utils";
import TopBar from "./TopBar";
import { NavbarToggler } from "../../components/NavbarToggler";
import NotificationToast from "../../components/NotificationToast";
import { getRequests } from "../../redux/modules/requests/actions";

type Tab = "none" | "nuggets" | "tags" | "keySources" | "keyPartners" | "flags";

export default function ViewCompany() {
  const { id = "0" } = useParams();

  const [currentTab, setCurrentTab] = useState<Tab>("none");
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { state } = useLocation();

  // handle new company creation notification
  const [isNewCompany, setIsNewCompany] = useState<boolean>(
    state?.isNewCompany ?? false
  );

  useEffect(() => {
    if (state?.isNewCompany) {
      const t = setTimeout(() => setIsNewCompany(false), 5000);

      return () => {
        setIsNewCompany(false);
        clearTimeout(t);
      };
    }
  }, [state]);

  //type can be either view or new

  // const status: string = useSelector(companyStatusSelector);
  const companies: Map<string, Company> = useSelector(companySelector);
  const { status, message } = useSelector(companyInfoSelector);

  const company: any = companies.get(id);

  //states for creation
  // const [nuggets, setNuggets] = useState<any>([{ text: "" }]);
  const [nuggets, setNuggets] = useState<any>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [flags, setFlags] = useState([]);
  const [keyPartners, setKeyPartners] = useState<partnerCompany[]>([]);
  const [keySources, setKeySources] = useState<any>([]);

  let { requests } = useSelector(requestsSelector);
  const users = useSelector(usersSelector);

  // filter requests which belongs to only this company and are closed
  requests = requests.filter(
    (r: Request) => +r.companyId === +id && r.status === 0
  );

  const keyCont = getKeyContributors(requests, users);

  useEffect(() => {
    if (company?.nuggets) {
      setNuggets(company?.nuggets);
    }
    if (company?.companyTags) {
      setTags(company?.companyTags);
    }
    if (company?.companySources) {
      setKeySources(company?.companySources);
    }
    if (company?.companyFlags) {
      setFlags(company?.companyFlags);
    }
    if (company?.partners) {
      setKeyPartners(company?.partners);
    }
  }, [company]);

  useEffect(() => {
    if (status === "success") {
      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        reset();
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  //effect to get company details
  useEffect(() => {
    dispatch(getCompanyDetails(+id));
    dispatch(getRequests());
  }, [dispatch, id]);

  // error state
  const goBackHandler = () => {
    switch (currentTab) {
      case "nuggets":
      case "tags":
      case "keySources":
      case "keyPartners":
      case "flags":
        setCurrentTab("none");
        break;
      default:
        navigate(-1);
    }
  };

  return (
    <div className="page-content bg-white" id="content">
      {status === "loading" && <Spinner />}

      <div className="row-header text-right">
        <NavbarToggler />
      </div>

      <TopBar handleBack={goBackHandler} />

      {/* company details */}
      <div className="company-details">
        <div className="container-fluid inner-box">
          {currentTab === "none" ? (
            <div className="row d-flex justify-content-center">
              <div className="col-xl-7">
                <Nuggets
                  selectTab={() => setCurrentTab("nuggets")}
                  nuggets={nuggets}
                  contributors={keyCont.nugget}
                />
                <KeySources
                  selectTab={() => setCurrentTab("keySources")}
                  keySources={keySources}
                  contributors={keyCont.source}
                />
              </div>
              <div className="col-xl-5">
                <Tags
                  selectTab={() => setCurrentTab("tags")}
                  tags={tags}
                  contributors={keyCont.tag}
                />
                <KeyPartners
                  selectTab={() => setCurrentTab("keyPartners")}
                  keyPartners={keyPartners}
                  contributors={keyCont.partner}
                />
                <div className="flags mt-3">
                  <Flags
                    selectTab={() => setCurrentTab("flags")}
                    flags={flags}
                    contributors={keyCont.flag}
                  />
                </div>
              </div>
            </div>
          ) : currentTab === "nuggets" ? (
            <ViewAddNuggets
              closeTab={() => setCurrentTab("none")}
              nuggets={nuggets}
              setNuggets={setNuggets}
              contributors={keyCont.nugget}
            />
          ) : currentTab === "tags" ? (
            <ViewAddTags
              closeTab={() => setCurrentTab("none")}
              tags={tags}
              setTags={setTags}
              contributors={keyCont.tag}
            />
          ) : currentTab === "keySources" ? (
            <ViewAddKeySources
              closeTab={() => setCurrentTab("none")}
              keySources={keySources}
              setKeySources={setKeySources}
              contributors={keyCont.source}
            />
          ) : currentTab === "flags" ? (
            <ViewAddFlags
              closeTab={() => setCurrentTab("none")}
              flags={flags}
              setFlags={setFlags}
              contributors={keyCont.flag}
            />
          ) : currentTab === "keyPartners" ? (
            <ViewAddKeyPartners
              closeTab={() => setCurrentTab("none")}
              keyPartners={keyPartners}
              setKeyPartners={setKeyPartners}
              contributors={keyCont.partner}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <NotificationToast
        show={status === "success" || isNewCompany === true}
        message={isNewCompany ? "Company Created Successfully !" : message}
      />
    </div>
  );
}
