import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, User } from '../../utils/types'
import { login as postLogin } from './api'

const initialState: RootState['currentUser'] = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  user_id: '',
  role: ''
}

const login = createAsyncThunk(
  'currentUser/login',
  async (user: any, thunkAPI) => {
    try {
      postLogin(user)
    } catch (err) {
      console.error(err)
    }
  }
)

export const currentUser = createSlice({
  name: 'currentUser',
  initialState: initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      // state = action.payload;
      return action.payload
    },
    getCurrentUser: state => {
      return state
    }
  }
})

export default currentUser.reducer

export const { setCurrentUser, getCurrentUser } = currentUser.actions
