import {
  companySource,
  companyTag,
  Flag,
  Nugget,
  Company,
} from "../redux/utils/types";

// export class Company {
//   constructor(
//     public id: number,
//     public companyName: string,
//     public flagColor: string,
//     public companyLink: string,
//     public createdAt: Date,
//     public updatedAt: Date,
//     public createdBy: Date,
//     public updatedBy: Date,

//     public nuggets?: Nugget[],
//     public companySources?: companySource[],
//     public companyTags?: companyTag[],
//     public companyFlags?: Flag[],
//     public partners?: Company[]
//   ) {}
// }

export default function CompanyModel(
  id: number,
  companyName: string,
  flagColor: string,
  companyLink: string,
  createdAt: Date,
  updatedAt: Date,
  createdBy: number,
  updatedBy: number,

  nuggets?: Nugget[],
  companySources?: companySource[],
  companyTags?: companyTag[],
  companyFlags?: Flag[],
  partners?: Company[]
) {
  return {
    id,
    companyName,
    flagColor,
    companyLink,
    createdAt,
    updatedAt,
    createdBy,
    updatedBy,
    nuggets,
    companySources,
    companyFlags,
    companyTags,
    partners,
  };
}
