import { useLocation } from "react-router-dom";

const styles: any = {
  background: "grey",
  borderRadius: "15px",
  fontWeight: "bold",
  padding: "7px 6px",
  margin: "8px 0",
  fontSize: '13px',
  color: "white",
  cursor: "pointer",
  whiteSpace: "nowrap",
};
/*
  background: grey;
            border-radius: 15px;
            font-weight: bold;
            padding: 7px 6px;
            margin: 8px 0;
            font-size: 13px;
            color: white;
            cursor: pointer;
            white-space: nowrap;
*/

export default function Time(props: { time: string }) {
  const { pathname } = useLocation();
  const type = pathname.split("/")[3];

  const { time } = props;
  if (time === "" || type === "new") return <></>;
  return <div style={styles}>{time}</div>;
}
