import { MouseEventHandler } from "react";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import { Contributors } from "../Contributors";

export default function Flags(props: {
  selectTab: MouseEventHandler<any>;
  flags: any[];
  contributors?: string[];
}) {
  const { selectTab, flags, contributors } = props;

  const latestUpdate = getLatestUpdate(flags);
  let lastUpdate = "";
  if (latestUpdate) {
    const { time, date, day } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  return (
    <div className="card w-100">
      <div className="card-body">
        <div className="top">
          <div className="top-start">
            <div>
              <h5 className="card-title">Flags&nbsp;</h5>
            </div>
            <div>
              <Time time={lastUpdate} />
            </div>
          </div>
          <div className="top-end">
            <div>
              <span role="button" onClick={selectTab}>
                <img src="/img/Vector.png" className="pl-3" alt="" />
              </span>
            </div>
          </div>
        </div>

        <div className="bg mt-2">
          <div className="bg-inner scroll-tag mt-2">
            {flags.map((flag, index) => (
              <p
                key={index}
                className="p-2 border rounded my-3"
                style={{ backgroundColor: "lightgrey" }}
              >
                {flag.flag}
              </p>
            ))}
          </div>
          <div className="bg-inner mt-2">
            <p className="key">
              Key Contributors &gt;
              <span>
                <Contributors contributors={contributors ?? []} />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
