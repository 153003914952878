import { MouseEventHandler, useEffect, useState } from "react";
import TagPrompt from "../TagPrompt";
import { companyTag, Tag } from "../../redux/utils/types";
import { RxCross2 as Cross } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { addTag, deleteTag } from "../../redux/modules/companyDetails/actions";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Spinner } from "../../assets/spinner.svg";
import { selectCompanyDetails } from "../../redux/modules/companyDetails/selector";
import NotificationToast from "../NotificationToast";
import { reset } from "../../redux/modules/companyDetails/slice";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import { Contributors } from "../Contributors";
import Time from "../Time";
import { authSelector } from "../../redux/modules/auth/userSelector";

export default function ViewAddTags(props: {
  closeTab: MouseEventHandler<HTMLSpanElement>;
  tags: Tag[];
  setTags: any;
  contributors?: string[];
}) {
  const { closeTab, setTags, tags, contributors } = props;
  const [isPrompt, setIsPrompt] = useState<boolean>(false);

  const { pathname } = useLocation();

  const { user } = useSelector(authSelector);

  const [isNewTag, setIsNewTag] = useState(false);
  const [newTags, setNewTags] = useState<Tag[]>([]);

  const handleAddTags = (tagsInput: any) => {
    const { companyType, productType, solutionType } = tagsInput;

    const tagsArray: Tag[] = [];

    companyType && tagsArray.push(...companyType);
    productType && tagsArray.push(...productType);
    solutionType && tagsArray.push(...solutionType);

    setIsNewTag(true);
    setNewTags(tagsArray);
    setIsPrompt(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<any>();

  const companyId = Number(pathname.split("/")[3]);
  //dispatch action for adding tag into the database
  const handleAddTag = async (tag: Tag) => {
    setIsLoading(true);
    const newTag = await dispatch(addTag({ companyId, tag }));
    const newTagList = newTags.filter(
      (tag: Tag) => tag.id !== newTag.payload.tagId
    );

    setNewTags(newTagList);
  };

  const { status, message } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      setIsNewTag(false);
      const t = setTimeout(() => {
        if (status === "success") dispatch(reset());
      }, 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  const latestUpdate = getLatestUpdate(tags);

  let lastUpdate = "";
  if (latestUpdate) {
    const { time, day, date } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  const handleAddAllTags = async () => {
    setIsLoading(true);

    await Promise.all(
      newTags.map((tag) => dispatch(addTag({ companyId, tag })))
    );
    setIsLoading(false);
    setNewTags([]);
  };

  return (
    <>
      <TagPrompt
        show={isPrompt}
        handleOk={handleAddTags}
        handleCancel={() => setIsPrompt(false)}
        selectedTags={tags}
        // setTags={setTags}
      />

      <div className="company-details">
        <div className="container-fluid inner-box">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 col-sm-12">
              {/* <!-- ------------------Nuggets------------- --> */}

              <div className="nugget-page">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="top d-flex justify-content-space">
                      <div className="top-start">
                        <div>
                          <h5 className="card-title">Tags&nbsp;</h5>
                        </div>
                        <div>{lastUpdate && <Time time={lastUpdate} />}</div>
                      </div>
                      <div className="top-end">
                        {user.role === "user" && (
                          <div>
                            <Link
                              className="btn suggest-btn"
                              to="suggestion/tag"
                            >
                              Suggest Update
                            </Link>
                          </div>
                        )}
                        <div>
                          <span role="button" onClick={closeTab}>
                            <img
                              src="/img/back.png"
                              className="pl-3 img-icon"
                              alt=""
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="bg mt-2">
                      <TagList tags={tags} />
                      <div>
                        {/* {isNewTag && */}
                        {newTags.length > 1 && (
                          <div className="text-right m-4">
                            <button
                              className="btn btn-success mx-2"
                              onClick={handleAddAllTags}
                            >
                              Save All
                            </button>
                          </div>
                        )}
                        {newTags.map((tag: Tag) => (
                          <div className="my-3 d-flex" key={tag.id}>
                            <p className="border rounded w-75 p-2 bg-white m-0">
                              {tag.tagName}
                            </p>
                            <button
                              className="btn btn-success mx-2"
                              onClick={() => handleAddTag(tag)}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-secondary mx-2"
                              onClick={() => {
                                const newTagList = newTags.filter(
                                  (t) => t.id !== tag.id
                                );
                                setNewTags(newTagList);
                              }}
                            >
                              Cancel
                            </button>
                            {isLoading && (
                              <Spinner
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="bg-inner mt-2">
                        <div className="top">
                          <div className="top-start">
                            <p className="key">
                              Key Contributors &gt;
                              <span>
                                <Contributors
                                  contributors={contributors || []}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      {user.role === "admin" && (
                        <div className="text-right my-4">
                          <button
                            className="btn key-btn"
                            onClick={() => setIsPrompt(true)}
                          >
                            <span className="fa fa-plus-circle mr-2"></span>Add
                            Tags
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationToast show={status === "success"} message={message} />
    </>
  );
}

function TagList(props: { tags: Tag[] }) {
  const { tags } = props;

  return (
    <div className="bg mt-2">
      {tags.map((tag: Tag) => (
        <TagItem key={tag.id} tag={tag} />
      ))}
    </div>
  );
}

function TagItem(props: { tag: Tag }) {
  const { tag } = props;
  const { pathname } = useLocation();
  const companyId = Number(pathname.split("/")[3]);

  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector(authSelector);

  const handleDeleteTag = () => {
    setIsLoading(true);
    dispatch(deleteTag({ companyId, tagId: +tag.id }));
  };

  const { status } = useSelector(selectCompanyDetails);

  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
    }
  }, [status]);

  return (
    <div className="my-3 d-flex">
      <p className="border rounded w-75 p-2 bg-white m-0 list__test">
        {tag.tagName}
      </p>
      <div className="m-2">
        {user.role === "admin" && (
          <Cross
            className="text-danger h4"
            role="button"
            title="Delete Tag"
            onClick={handleDeleteTag}
          />
        )}
        {isLoading && <Spinner style={{ width: "50px", height: "50px" }} />}
      </div>
    </div>
  );
}
