import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getNotifications } from "../../../redux/modules/auth/actions";
import { notificationsSelector } from "../../../redux/modules/auth/userSelector";
import { Notification } from "../../../redux/utils/types";
import { EmptyList } from "../../../components/EmptyList";
import { getAllNotifications } from "../../../redux/modules/notifications/actions";
import { NavbarToggler } from "../../../components/NavbarToggler";

export default function Notifications() {
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(getNotifications());
    dispatch(getAllNotifications());
  }, [dispatch]);

  const notifications: Notification[] = useSelector(notificationsSelector);

  return (
    <div className="page-content p-0 bg-white" id="content">
      <div className="row-header">
        <div className="container-fluid">
          <div className="align-items-center d-flex justify-content-between">
            <h2 className="head">Notifications</h2>
            <NavbarToggler />
          </div>
        </div>
      </div>

      <div className="container-fluid pt-2">
        <div className="table-responsive">
          <table className="table global-table justify-content-between p-2">
            {notifications?.map(({ id, title, subtitle }) => (
              <tr key={id}>
                <td>{title}</td>
                <td className="text-right">
                  <Link to={`view/${id}`} className="btn view-btn">
                    View
                  </Link>
                </td>
              </tr>
            ))}
            {notifications?.length === 0 && (
              <EmptyList
                title="No notification at the moment :("
                subtitle="You will be notified when admin sends a notification to you"
              />
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
