import { useDispatch } from "react-redux";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/modules/auth/actions";

export default function AdminUserBar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <div className="vertical-nav bg-white" id="sidebar">
      <h1 className="logo">
          Wendwise
          <sup className="tm">TM</sup>
        </h1>
        <ul className="nav flex-column bg-white mb-0 px-0 ">
          <li className="nav-item">
            <NavLink
              to="/home"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-home" aria-hidden="true"></i>Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/users"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-user" aria-hidden="true"></i>
              <span>User</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/settings"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-cog" aria-hidden="true"></i>
              Admin Settings
            </NavLink>
          </li>
          <li className="nav-item mb-2">
            <a
              role="button"
              onClick={handleLogout}
              className="nav-link text-dark"
            >
              <i className="fa fa-power-off text-dark" aria-hidden="true"></i>
              Logout
            </a>
          </li>
        </ul>
      </div>
      <Outlet />
    </>
  );
}
