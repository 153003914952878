import { createSlice } from "@reduxjs/toolkit";
import {
  editNugget,
  addNugget,
  deleteNugget,
  addTag,
  deleteTag,
  addKeySource,
  deleteKeySource,
  editKeySource,
  addFlag,
  deleteFlag,
  editFlag,
  addPartner,
  deletePartner,
  addPartnerName,
  addPartnerLink,
  deletePartnerLink,
  addMultiplePartners,
} from "./actions";
import { RootState } from "../../utils/types";

const initialState: RootState["companyDetails"] = {
  status: "idle",
  message: "",
};

export const companyDetails = createSlice({
  name: "companyDetails",
  initialState,
  reducers: {
    reset: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(editNugget.pending, (state) => {
        state.status = "loading";
      })

      .addCase(editNugget.fulfilled, (state) => {
        state.status = "success";
        state.message = "Nugget Updated Successfully";
      })

      .addCase(addNugget.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addNugget.fulfilled, (state) => {
        state.status = "success";
        state.message = "Nugget Added Successfully";
      })

      .addCase(deleteNugget.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteNugget.fulfilled, (state) => {
        state.status = "success";
        state.message = "Nugget Deleted Successfully";
      })

      .addCase(deleteNugget.rejected, (state) => {
        state.status = "failed";
        state.message = "Something went wrong";
      })

      .addCase(addTag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addTag.fulfilled, (state) => {
        state.status = "success";
        state.message = "Tag added Successfully";
      })

      .addCase(deleteTag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteTag.fulfilled, (state) => {
        state.status = "success";
        state.message = "Tag Deleted Successfully";
      })

      .addCase(addKeySource.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addKeySource.fulfilled, (state) => {
        state.status = "success";
        state.message = "Source Info Added Successfully";
      })

      .addCase(deleteKeySource.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteKeySource.fulfilled, (state) => {
        state.status = "success";
        state.message = "Source Info Deleted Successfully";
      })

      .addCase(editKeySource.pending, (state) => {
        state.status = "loading";
      })

      .addCase(editKeySource.fulfilled, (state) => {
        state.status = "success";
        state.message = "Source Info Updated Successfully";
      })

      .addCase(addFlag.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addFlag.fulfilled, (state) => {
        state.status = "success";
        state.message = "Flag Added Successfully";
      })

      .addCase(deleteFlag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deleteFlag.fulfilled, (state) => {
        state.status = "success";
        state.message = "Flag Deleted Successfully";
      })

      .addCase(editFlag.pending, (state) => {
        state.status = "loading";
      })

      .addCase(editFlag.fulfilled, (state) => {
        state.status = "success";
        state.message = "Flag Updated Successfully";
      })

      .addCase(addPartner.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addPartner.fulfilled, (state) => {
        state.status = "success";
        state.message = "Partner Added Successfully";
      })
      .addCase(deletePartner.pending, (state) => {
        state.status = "loading";
      })

      .addCase(deletePartner.fulfilled, (state) => {
        state.status = "success";
        state.message = "Partner Deleted Successfully";
      })

      .addCase(addPartnerName.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addPartnerName.fulfilled, (state) => {
        state.status = "success";
        state.message = "Partner Added Successfully";
      })

      .addCase(addPartnerLink.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addPartnerLink.fulfilled, (state) => {
        state.status = "success";
        state.message = "Partner Added Successfully";
      })

      .addCase(deletePartnerLink.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePartnerLink.fulfilled, (state) => {
        state.status = "success";
        state.message = "Partner Deleted Successfully";
      })

      .addCase(addMultiplePartners.pending, (state) => {
        state.status = "loading";
      })

      .addCase(addMultiplePartners.fulfilled, (state) => {
        state.status = "success";
        state.message = "Parters Added Successfully";
      });
  },
});

export default companyDetails.reducer;

export const { reset } = companyDetails.actions;
