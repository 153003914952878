import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, Notification } from '../../utils/types'
import { getAllNotifications, sendNotification } from './actions'

const initialState: RootState['notifications'] = {
  status: 'idle',
  message: '',
  notifications: []
}

export const notifications = createSlice({
  name: 'notifications',
  initialState: initialState,
  reducers: {
    updateNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload
    },
    reset: state => {
      state.status = 'idle'
      state.message = ''
    }
  },
  extraReducers: builder => {
    builder

      .addCase(getAllNotifications.pending, state => {
        state.status = 'loading'
      })

      .addCase(getAllNotifications.fulfilled, (state, action: any) => {
        state.status = 'success'

        state.notifications = action.payload
        state.status = 'idle'
      })

      .addCase(sendNotification.pending, state => {
        state.status = 'loading'
      })

      .addCase(
        sendNotification.fulfilled,
        (state, action: PayloadAction<Notification>) => {
          state.status = 'success'
          state.notifications.push(action.payload)
          state.message = 'Notification Sent Success !'
        }
      )
  }
})

export default notifications.reducer

export const { updateNotifications, reset } = notifications.actions
