import { createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { getUsers } from "../users/getUsers";
import { fetchNotifications, httpAcceptTerms } from "./api";
import { getCompanies } from "../company/actions";
import { getTags } from "../tags/actions";
import { getRequests } from "../requests/actions";
import { getAllNotifications } from "../notifications/actions";
import { User } from "../../utils/types";
import { reset } from "./slice";

export const register = createAsyncThunk(
  "auth/register",
  async (user: any, thunkAPI) => {
    try {
      const newUser = await authService.register(user);
      thunkAPI.dispatch<any>(getUsers());
      return newUser;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (
    user: { email: string; password: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await authService.login(user);
      // get all details from api on successful login

      dispatch<any>(getTags());
      dispatch<any>(getUsers());
      dispatch<any>(getRequests());
      if (response.role === "admin") {
        dispatch<any>(getAllNotifications());
      }

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

export const whoAmI = createAsyncThunk(
  "auth/whoami",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await authService.whoAmI();
      // dispatch<any>(getCompanies())
      dispatch<any>(getTags());
      dispatch<any>(getUsers());
      dispatch<any>(getRequests());
      if (response.role === "admin") {
        dispatch<any>(getAllNotifications());
      }
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getNotifications = createAsyncThunk(
  `user/notification`,
  async (_, thunkAPI) => {
    try {
      const notifications = await fetchNotifications();
      return notifications;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const acceptTerms = createAsyncThunk(
  "users/acceptTerms",
  async (user: User, thunkAPI) => {
    try {
      const res = await httpAcceptTerms({ ...user, isTermsAccepted: 1 });
      return res;
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
