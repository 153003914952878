import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, Request } from "../../utils/types";
import { closeRequest, getRequests, sendRequest } from "./actions";

const initialState: RootState["requests"] = {
  status: 'idle', 
  message: '',
  requests: [],
}

export const requests = createSlice({
  name: "requests",
  initialState: initialState,
  reducers: {
    updateRequests: (state, action: PayloadAction<Request[]>) => {
      state.requests = action.payload
    },
    reset: (state) => {
      state.status = 'idle'
    }
  }, 
  extraReducers: builder => {
    builder 

    .addCase(getRequests.fulfilled, (state, action: PayloadAction<Request[]>) => {
      state.requests = action.payload
    })

      .addCase(sendRequest.pending, (state) => {
        state.status = 'loading'
      })

      .addCase(sendRequest.fulfilled, state => {
        state.status = 'success'
        state.message= 'Request Sent Success !'
      })

      .addCase(sendRequest.rejected, state => {
        state.status = 'failed'
      })

      .addCase(closeRequest.pending, state => {
        state.status = 'loading'
      })

      .addCase(closeRequest.fulfilled, state => {
        state.status = 'success'
        state.message = 'Request Closed Success !'
      })

      .addCase(closeRequest.rejected, state => {
        state.status = 'failed'
      })
  }
})

export default requests.reducer

export const { updateRequests, reset } = requests.actions