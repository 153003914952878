import { Request, User } from "../../redux/utils/types";

export function getLatestUpdate(list: any) {
  const isValidDate = (dateObject: any) =>
    new Date(dateObject).toString() !== "Invalid Date";

  //calculate last updated at and keycontributers
  let latestUpdate = null;

  if (list.length > 0) {
    latestUpdate = new Date(list[0].updatedAt);

    for (const item of list) {
      const currentUpdate = new Date(item.updatedAt);
      if (currentUpdate > latestUpdate) {
        latestUpdate = currentUpdate;
      }
    }
  }

  let latestDate = null;
  let latestTime = null;

  if (latestUpdate && isValidDate(latestUpdate)) {
    latestDate = latestUpdate.toLocaleDateString("en-us", {
      dateStyle: "full",
    });
    latestTime = latestUpdate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const day = latestDate.slice(0, latestDate.indexOf(","));
    const date = latestDate.slice(latestDate.indexOf(" ") + 1);
    const res = {
      time: latestTime,
      day,
      date,
    };
    return res;
  } else {
    return null;
  }
}

export function getKeyContributors(
  requests: Request[],
  users: Map<string, User>
) {
  const contributors: any = {
    nugget: new Map(), //userId: freq
    tag: new Map(),
    flag: new Map(),
    partner: new Map(),
    source: new Map(),
  };

  requests.forEach((r) => {
    // const user = users.get(r.userId.toString());
    // const user_id = user?.user_id;
    let currentFreq = 0;
    switch (r.type) {
      case "nugget":
        currentFreq = contributors.nugget.get(r.userId) || 0;
        contributors.nugget.set(r.userId, currentFreq + 1);
        break;

      case "tag":
        currentFreq = contributors.tag.get(r.userId) || 0;
        contributors.tag.set(r.userId, currentFreq + 1);
        break;

      case "flag":
        currentFreq = contributors.flag.get(r.userId) || 0;
        contributors.flag.set(r.userId, currentFreq + 1);
        break;

      case "partner":
        currentFreq = contributors.partner.get(r.userId) || 0;
        contributors.partner.set(r.userId, currentFreq + 1);
        break;

      case "source":
        currentFreq = contributors.source.get(r.userId) || 0;
        contributors.source.set(r.userId, currentFreq + 1);
        break;

      default:
    }
  });

  const keyCont: any = {};

  keyCont.nugget = getFreq(contributors.nugget);
  keyCont.flag = getFreq(contributors.flag);
  keyCont.tag = getFreq(contributors.tag);
  keyCont.partner = getFreq(contributors.partner);
  keyCont.source = getFreq(contributors.source);

  function getFreq(list: Map<number, number>): any {
    let freq: any[] = [];
    list.forEach((f, u) => {
      freq.push({ userId: u, freq: f });
    });

    freq.sort((f1: any, f2: any) => (f1.freq > f2.freq ? -1 : 1));
    freq = freq.slice(0, 5);
    freq = freq.map((f) => users.get(f.userId.toString())?.user_id);

    return freq;
  }

  return keyCont;
}
