import { MouseEventHandler } from "react";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import Time from "../Time";
import { Contributors } from "../Contributors";

export default function Nuggets(props: {
  selectTab: MouseEventHandler<HTMLSpanElement>;
  nuggets: any[];
  contributors?: string[];
}) {
  const { selectTab, nuggets, contributors } = props;

  const latestUpdate = getLatestUpdate(nuggets);
  let lastUpdate = "";

  if (latestUpdate) {
    const { time, date, day } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  // const {pathname} = useLocation

  return (
    <div className="nuggets">
      <div className="card w-100">
        <div className="card-body">
          <div className="top">
            <div className="top-start">
              <div>
                <h5 className="card-title ">Nuggets&nbsp;</h5>
              </div>
              <div>
                {/* <span>Last Updated</span> */}
                <Time time={lastUpdate} />
              </div>
            </div>
            <div className="top-end">
              <div>
                <span onClick={selectTab} role="button">
                  <img
                    src="/img/Vector.png"
                    className="pl-3"
                    alt="view nuggets"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="bg mt-2">
            <div className="bg-inner scroll-nugget mt-2">
              {/* {limitedNugget[0]?.nugget && */}
              {nuggets.map((nugget, i) => (
                <p
                  className="p-2 border rounded my-3"
                  style={{ backgroundColor: "lightgrey" }}
                  key={i}
                >
                  {nugget.nugget}
                </p>
              ))}
            </div>
            <div className="bg-inner mt-2">
              <p className="key">
                Key Contributors &gt;
                <span className="mx-2">
                  <Contributors contributors={contributors || []} />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
