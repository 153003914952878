import { AppThunk } from '../../store'
import { User } from '../../utils/types'
import { updateUsers } from './slice'
import { fetchUsers } from './api'

export const getUsers = (): AppThunk => async dispatch => {
  try {
    const users: User[] = await fetchUsers()
    users.map(user => dispatch(updateUsers(user)))
  } catch (err) {
    console.error(err)
  }
}
