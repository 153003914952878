import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { usersSelector } from "../../redux/modules/users/selectors";
import { useEffect } from "react";
import NotificationToast from "../../components/NotificationToast";
import { RootState } from "../../redux/utils/types";
import { reset } from "../../redux/modules/users/slice";
import { NavbarToggler } from "../../components/NavbarToggler";
export default function AdminUsers() {
  const usersMap = useSelector(usersSelector);
  let users: any[] = Array.from(usersMap.values());

  users = users.filter((user) => user.role === "user" && user.is_deleted === 0);

  const dispatch = useDispatch<any>();

  const { message, status } = useSelector((state: RootState) => state.users);

  useEffect(() => {
    if (status === "success") {
      setTimeout(() => {
        dispatch(reset());
      }, 5000);
    }
  }, [status, dispatch]);

  return (
    <>
      <div className="page-content bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <h2 className="head">Users</h2>
              <Link to="new" className="btn suggest-btn" role="button">
                Create User Profile
              </Link>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="container-fluid px-0">
          <div className="table-responsive">
            <table className="table global-table">
              <thead className="text-center">
                <tr>
                  <th scope="col">No.</th>
                  <th scope="col">User ID</th>
                  <th scope="col">Email ID</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody className="text-center">
                {users.map((company, index) => {
                  const { id, user_id, email, companyName, status } = company;
                  return (
                    <tr key={id}>
                      <th scope="row">{index + 1}</th>
                      <td>{user_id}</td>
                      <td>{email}</td>
                      <td>{companyName || "Wendwise"}</td>
                      {status ? (
                        <td>
                          <i
                            className="fa fa-circle text-success mx-2"
                            aria-hidden="true"
                          ></i>
                          Online
                        </td>
                      ) : (
                        <td>
                          <i
                            className="fa fa-circle text-muted mx-2"
                            aria-hidden="true"
                          ></i>
                          Offline
                        </td>
                      )}
                      <td>
                        <Link to={`view/${id}`} className="btn view-btn">
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <NotificationToast show={status === "success"} message={message} />
    </>
  );
}
