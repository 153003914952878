import { MouseEventHandler } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

export default function Popup(props: {
  handleClose: any;
  show: boolean;
  message: string;
}) {
  const { handleClose, show, message } = props;
  const navigate = useNavigate();
  return (
    <Modal show={show}>
      <div className="p-3 text-center">
        <div className="text-center w-80">
          <img
            // className="w-25"
            style={{ width: "30%" }}
            src={require("../../assets/green-tick.png")}
            alt="success"
          />
        </div>
        <h3 className="text-center my-4">{message}</h3>
        <div className="d-flex justify-content-around">
          <Button onClick={handleClose}>
            Go Back
          </Button>
        </div>
      </div>
    </Modal>
  );
}