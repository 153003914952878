export function NavbarToggler() {
  return (
    <div className="toggler">
      <button
        className="btn nav-btn navbar-toggler d-block d-lg-none"
        type="button"
        onClick={() => {
          //using document api to convert jquery code
          const navBar = document.querySelector(".vertical-nav");
          navBar?.classList.toggle("menuIn");
        }}
      >
        <span className="fa fa-bars"></span>
      </button>
    </div>
  );
}
