import { MouseEventHandler, useEffect, useState } from "react";
import Time from "../Time";
import { getLatestUpdate } from "../../pages/ViewCompany/utils";
import { Contributors } from "../Contributors";
import { addKeySource } from "../../redux/modules/companyDetails/actions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectCompanyDetails } from "../../redux/modules/companyDetails/selector";
import { reset } from "../../redux/modules/companyDetails/slice";
import NotificationToast from "../NotificationToast";
import { userSelector } from "../../redux/modules/auth/userSelector";

export default function KeySources(props: {
  selectTab: MouseEventHandler<any>;
  keySources: any[];
  contributors?: string[];
}) {
  const { selectTab, keySources, contributors } = props;

  const user: any = useSelector(userSelector);

  const [newKeySource, setNewKeySource] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const latestUpdate = getLatestUpdate(keySources);
  let lastUpdate = "";

  if (latestUpdate) {
    const { time, date, day } = latestUpdate;
    lastUpdate = `${date} ${time}`;
  }

  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();
  const companyId = Number(pathname.split("/")[3]);

  const handleLinkChange = (e: any) => {
    setIsLoading(true);
    dispatch(addKeySource({ companyId, source: e }));
  };

  const { status, message } = useSelector(selectCompanyDetails);
  useEffect(() => {
    if (status === "success") {
      setIsLoading(false);
      setNewKeySource("");

      const t = setTimeout(() => {
        dispatch(reset());
      }, 5000);

      return () => {
        dispatch(reset());
        clearTimeout(t);
      };
    }
  }, [status, dispatch]);

  return (
    <>
    <div className="key-sources mt-3 mb-3">
      <div className="card w-100">
        <div className="card-body">
          <div className="top">
            <div className="top-start">
              <div>
                <h5 className="card-title">Key Sources and Links&nbsp;</h5>
              </div>
              <div>
                <Time time={lastUpdate} />
              </div>
            </div>
            <div className="top-end">
              <div>
                <span role="button" onClick={selectTab}>
                  <img src="/img/Vector.png" className="pl-3" alt="" />
                </span>
              </div>
            </div>
          </div>

          <div className="bg mt-2">
            <div className="bg-inner scroll-nugget mt-2">
            {
                user?.role=='admin'?
                <input
                  value={newKeySource}
                  placeholder="Drop sources and links to save"
                  onChange={(e) => handleLinkChange(e.target.value)}
                  onKeyDown={(e) => e.preventDefault()}
                  style={{ caretColor: "transparent" }}
                />:null
              }
              {keySources.map((keysource) => (
                <p
                  key={keysource.id}
                  className="p-2 border rounded my-3"
                  style={{ backgroundColor: "lightgrey" }}
                >
                  {keysource.source}
                </p>
              ))}
            </div>
            <div className="bg-inner mt-2">
              <p className="key">
                Key Contributors &gt;
                <span>
                  <Contributors contributors={contributors ?? []} />
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <NotificationToast message={message} show={status === "success"} />
    </>
  );
}
