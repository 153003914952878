import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { authSelector } from "../../../redux/modules/auth/userSelector";
import { reset } from "../../../redux/modules/auth/slice";
import { login, logout, whoAmI } from "../../../redux/modules/auth/actions";

export default function UserLogin() {
  const [keepSignedIn, setKeepSignedIn] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const auth = useSelector(authSelector);
  // const user = useSelector(userSelector);

  const { status, message, user } = auth;

  const [error, setError] = useState("");

  const handleLogin = () => {
    const userCredentials = { email, password };
    localStorage.setItem("remember_me", keepSignedIn.toString());
    dispatch(login(userCredentials));
  };

  useEffect(() => {
    if (user) {
      if (!user.is_deleted) {
        dispatch(reset());
        if (!user.isTermsAccepted) {
          navigate("/terms");
        } else {
          navigate("/home");
        }
      } else {
        setError("You Are Not Allowed to Login");
        dispatch(reset());
      }
    }
  }, [user, navigate, dispatch]);

  // useEffect(() => {
  //   dispatch(whoAmI());
  // }, [dispatch]);

  useEffect(() => {
    const token_persist = localStorage.getItem("remember_me");

    if (token_persist === "true") {
      setKeepSignedIn(true);
    } else {
      setKeepSignedIn(false);
    }
  }, []);

  //reset error state
  useEffect(() => {
    if (status === "failed") {
      dispatch(reset());
    }

    if (error) {
      setError("");
      dispatch(logout());
    }
  }, [email, password]);

  //for html marketing site page
  const pageNavigate = () => {
    window.open('/about.html', '_blank');
  };

  return (
    <>
      {status === "loading" && <Spinner />}

      <div className="sign-up-wrap">
        <div className="header-container">
          <div className="row w-100">
            <div className="col-8">
              <h1 className="logo">
                Wendwise
                <sup className="tm">TM</sup>
              </h1>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-center">
              <a
                // href="/about-wendwise"
                className="cursor-point text-decoration-none fs-5"
                style={{ fontSize: "18px", textDecoration: "none" }}
                onClick={pageNavigate}
              >
                About Wendwise
              </a>
            </div>
          </div>
        </div>
        <div className="form-container-wrap">
          <form
            className="form-container1"
            onSubmit={(e) => e.preventDefault()}
          >
            <h2>User Sign In</h2>
            <div className="form-group">
              {status === "failed" && (
                <p className="text-danger font-weight-bold">{message}</p>
              )}
              {error.length !== 0 && (
                <p className="text-danger font-weight-bold">{error}</p>
              )}
              <label htmlFor="useremail">Email</label>
              <input
                type="email"
                className="form-control"
                id="useremail"
                placeholder="Enter Your email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="passwd">Password</label>
              <input
                type="password"
                className="form-control"
                id="passwd"
                placeholder="Enter Password"
                // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number, one uppercase, one lowercase letter, one special character and at least 10 or more characters"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="forgot-link d-flex align-items-center justify-content-between">
              <div className="custom-control custom-checkbox login-check">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="remember"
                  checked={keepSignedIn}
                  onChange={(_) => setKeepSignedIn(!keepSignedIn)}
                />
                <label htmlFor="remember" className="custom-control-label">
                  Keep me signed in
                </label>
              </div>
              {/* <Link to="/password-reset">Forgot Password?</Link> */}
            </div>
            <button
              type="submit"
              className="btn mt-5 btn-block"
              id="btn-submit"
              // onClick={() => navigate("/admin/home")}
              onClick={handleLogin}
            >
              Sign In
            </button>
          </form>
        </div>
        {/* <Spinner /> */}
      </div>
    </>
  );
}
