import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../redux/modules/auth/userSelector";
import Back from "../../../components/Buttons/Back";
import { NavbarToggler } from "../../../components/NavbarToggler";
import TextInput from "../../../components/TextInput";
import { useEffect, useState } from "react";
import { updatePassword } from "../../../redux/modules/users/actions";
import { RootState } from "../../../redux/utils/types";
import NotificationToast from "../../../components/NotificationToast";
import Spinner from "../../../components/Spinner";
import { reset } from "../../../redux/modules/users/slice";
export default function Settings() {
  // assuming user can not edit his details

  const user = useSelector(userSelector);
  const { status, message } = useSelector((state: RootState) => state.users);
  const { firstName, lastName, user_id, email, companyName } = user;
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEditingPass, setIsEditingPass] = useState(false);

  const [errors, setErrors] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const handleUpdatePassword = (e: any) => {
    e.preventDefault();
    if (!validate()) return;

    const payload = { id: +user.id, password: password };
    dispatch(updatePassword(payload))
      .then(() => {
        setIsEditingPass(false);
        setPassword("");
        setConfirmPassword("");
      })
      .catch((err: any) => console.error(err));

    function validate() {
      if (password !== confirmPassword) {
        setErrors(["Password do not match !"]);
        return false;
      }
      return true;
    }
  };

  useEffect(() => {
    if (status === "success") {
      const t = setTimeout(() => dispatch(reset()), 5000);

      return () => {
        // dispatch(reset());
        clearTimeout(t);
      };
    }
  });

  return (
    <>
      {status === "loading" && <Spinner />}
      <div className="page-content bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">User Settings</h2>
              <NavbarToggler />
            </div>
          </div>
        </div>

        <div className="user-box">
          <div className="container-fluid">
            <form
              onSubmit={(e) => e.preventDefault()}
              style={{ paddingBottom: "0" }}
            >
              <div className="form-buttons">
                <Back />
              </div>
              <div className="user-settings">
                <div className="form-group">
                  <label htmlFor="firstname">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstname"
                    placeholder="First Name"
                    value={firstName}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="lastname">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    placeholder="Last Name"
                    value={lastName}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email ID"
                    value={email}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="userId">User ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userId"
                    placeholder="user_id"
                    value={user_id}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="company">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    placeholder="Company Name"
                    value={companyName ?? "Wendwise"}
                    disabled={true}
                  />
                </div>
              </div>
            </form>

            <form
              onSubmit={handleUpdatePassword}
              style={{ border: "2px solid rgb(179 179 179)" }}
              className="p-4 mt-5 rounded"
            >
              <div className="d-flex justify-content-end">
                {!isEditingPass ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(_) => setIsEditingPass(!isEditingPass)}
                  >
                    Edit Password
                  </button>
                ) : (
                  <div>
                    <button
                      className="btn btn-secondary mx-2"
                      type="button"
                      onClick={(_) => setIsEditingPass(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-success mx-2">
                      Save
                    </button>
                  </div>
                )}
              </div>
              {/* password updation will be manually */}
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div className="d-flex align-items-center">
                  <input
                    tabIndex={1}
                    type={passwordVisibility ? "text" : "password"}
                    className="form-control"
                    id="password"
                    placeholder="Enter new password"
                    name="password"
                    value={password}
                    disabled={!isEditingPass}
                    onChange={(e: any) => setPassword(e.target.value)}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$"
                    title="Must contain at least one number, one uppercase letter, one lowercase letter, one special character and at least 10 characters"
                    required={true}
                  />
                  <div className="validation">
                    <button
                      type="button"
                      className="btn"
                      data-toggle="modal"
                      data-target="#validation"
                    >
                      <i className="fa fa-question-circle-o"></i>
                    </button>
                  </div>
                  <span
                    className="mx-4"
                    title="See Password"
                    role="button"
                    onClick={(_) => setPasswordVisibility(!passwordVisibility)}
                  >
                    <i className="fa fa-eye"></i>
                  </span>
                </div>
              </div>

              <TextInput
                label="Confirm Password"
                attrs={{
                  type: "password",
                  id: "c-password",
                  placeholder: "Retype Password",
                  required: true,
                  disabled: !isEditingPass,
                  tabIndex: 2,
                }}
                value={confirmPassword}
                handleChange={(value: string) => {
                  setConfirmPassword(value);
                  if (errors.length) {
                    setErrors([]);
                  }
                }}
                errors={errors}
              />
            </form>
          </div>
        </div>
      </div>

      {<NotificationToast message={message} show={status === "success"} />}
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="validation"
        role="dialog"
        aria-labelledby="passwordvalidation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
              <ul>
                <li>10 characters or longer</li>
                <li>At least one number</li>
                <li>At least one lowercase letter</li>
                <li>At least one uppercase letter</li>
                <li>At least one special character</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="validation"
        role="dialog"
        aria-labelledby="passwordvalidation"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <ul>
                <li>10 characters or longer</li>
                <li>At least one number</li>
                <li>At least one lowercase letter</li>
                <li>At least one uppercase letter</li>
                <li>At least one special character</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* </div>  */}
      {/* </div> */}
    </>
  );
}
