import React from "react";
import Modal from "react-bootstrap/Modal";

export default function Prompt(props: {
  show: boolean;
  prompt: string;
  handleCancel: any;
  handleOk: any;
}) {
  const { show, prompt, handleCancel, handleOk } = props;
  return (
    <>
      <Modal show={show} centered={true}>
        <div className="p-4 text-center">
          <p style={{ fontSize: "1.3rem" }} className="my-3">
            {prompt}
          </p>
          <div className="d-flex justify-content-around my-3">
            <button
              className="btn btn-outline-primary font-weight-bold"
              onClick={handleOk}
            >
              Yes
            </button>
            <button
              className="btn btn-outline-secondary font-weight-bold"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
