import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "../../utils/types";
import {
  httpAcceptTerms,
  httpDeleteUser,
  httpEditUser,
  httpUpdatePassword,
} from "./api";

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (id: string, thunkAPI) => {
    try {
      const response = await httpDeleteUser(id);

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const editUser = createAsyncThunk(
  "users/edit",
  async (user: any, thunkAPI) => {
    const { id = "" } = user;
    // delete user.id
    try {
      const response = await httpEditUser(id, user);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "users/updatePassword",
  async (data: { id: number; password: string }, thunkAPI) => {
    const { id, password } = data;

    try {
      const res = await httpUpdatePassword(id, password);
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

