import React, { useEffect } from "react";
// import requests from "../../data/requests.json";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { requestsSelector } from "../../redux/modules/requests/selectors";
import { Request, User } from "../../redux/utils/types";
import { getRequests } from "../../redux/modules/requests/actions";
import { usersSelector } from "../../redux/modules/users/selectors";
import { NavbarToggler } from "../../components/NavbarToggler";
import { EmptyList } from "../../components/EmptyList";

//makes the comment to be presentable on the table
const makeCommentShort = (comment: string) => {
  const index = comment.indexOf("\n\n");
  comment = comment.slice(index, index + 20);
  return comment + "...";
};

export default function AdminRequests() {
  let { requests }: { requests: Request[] } = useSelector(requestsSelector);
  const users: Map<string, User> = useSelector(usersSelector);

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(getRequests());
  }, [dispatch]);

  //sort all open requests before closed one
  requests = requests?.slice().sort((req1, req2) => {
    if (req1.status === 1) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <div className="page-content bg-white" id="content">
      <div className="row-header">
        <div className="container-fluid">
          <div className="align-items-center d-flex justify-content-between">
            <h2 className="head">Requests</h2>
            <NavbarToggler />
          </div>
        </div>
      </div>
      <div className="container-fluid px-0">
        <div className="table-responsive">
          <table className="table global-table">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">User ID</th>
                <th scope="col">Requests</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {requests.length !== 0 ? (
                requests.map((request, index) => {
                  const userId = request.userId.toString();
                  const username = users?.get(userId)?.user_id;
                  const comment = makeCommentShort(request.comment);
                  const date = new Date(request.createdAt).toLocaleDateString(
                    "en-US",
                    { year: "numeric", month: "2-digit", day: "2-digit" }
                  );
                  const time = new Date(request.createdAt).toLocaleTimeString(
                    "en-US",
                    { hour: "numeric", minute: "numeric", hour12: true }
                  );
                  const status = request.status ? "Open" : "Closed";
                  return (
                    <tr key={request.id}>
                      <th scope="row">{index + 1}.</th>
                      <td>{username}</td>
                      <td>{comment}</td>
                      <td>{date}</td>
                      <td>{time}</td>
                      <td>{status}</td>
                      <td>
                        <Link
                          to={`view/${request.id}`}
                          className="btn view-btn"
                        >
                          View
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <EmptyList
                  title="No Request Present at the Moment :("
                  subtitle="Requests sent by users will be listed here, no request at the moment."
                />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
