import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { companySelector } from "../../redux/modules/company/selectors";
import { useDispatch } from "react-redux";
import { register } from "../../redux/modules/auth/actions";
import Spinner from "../../components/Spinner";
import { authSelector } from "../../redux/modules/auth/userSelector";
import Popup from "../../components/Modal";
import { reset } from "../../redux/modules/auth/slice";
import { debounce } from "lodash";
import axios from "../../adapters/axios";
import Back from "../../components/Buttons/Back";
import TextInput from "../../components/TextInput";

export default function CreateUser() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { status, message } = useSelector(authSelector);

  const [isUserNameAvailable, setIsUserNameAvailable] = useState<boolean>(true);

  const [user, setUser] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);

  const handleSuccessModal = () => {
    dispatch(reset());
    navigate(-1);
  };

  const checkAvailabiliy = async (val: any) => {
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (regex.test(val)) {
      setIsUserNameAvailable(false);
      return;
    }
    try {
      const res = await axios.post("checkAvailability", { user_id: val });
      setIsUserNameAvailable(res.data.available);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChange = useCallback(
    debounce((val) => checkAvailabiliy(val), 500),
    []
  );

  useEffect(() => {
    if (status === "failed") {
      dispatch(reset());
    }
  }, [user]);

  const handleCreateUser = (e: any) => {
    e.preventDefault();

    if (!validate() || !isUserNameAvailable) return;

    delete user.confirmPassword;
    dispatch(register(user));
  };

  const validate = () => {
    const tempErrors: any = {};
    let isValid = true;

    //regex for special characters
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (user.userId && regex.test(user.userId)) {
      tempErrors.userId = ["User ID cannot contain special characters"];
      isValid = false;
    }

    const passRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    if (user.password && passRegex.test(user.password) === false) {
      tempErrors.password = [
        "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters",
      ];
      isValid = false;
    }

    if (user.password !== user.confirmPassword) {
      tempErrors.confirmPassword = ["Password do not match"];
      isValid = false;
    }
    setErrors(tempErrors);
    return isValid;
  };

  return (
    <>
      <div className="page-content bg-white" id="content">
        {status === "loading" && <Spinner />}
        <Popup
          message={message}
          show={status === "success"}
          handleClose={handleSuccessModal}
        />
        <div className="row-header text-right">
          <div className="toggler p-2">
            <button
              className="btn nav-btn navbar-toggler d-block d-lg-none pull-right"
              type="button"
            >
              <span className="fa fa-bars"></span>
            </button>
          </div>
        </div>

        <div className="user-box">
          <div className="container-fluid">
            <form onSubmit={handleCreateUser}>
              {/* above was form tag */}
              <div className="form-buttons">
                <Back />
                <button type="submit" className="btn suggest-btn ">
                  Create Profile
                </button>
              </div>
              {status === "failed" && (
                <span className="text-danger font-weight-bold">{message}</span>
              )}

              <TextInput
                label="First Name"
                attrs={{
                  type: "text",
                  id: "firstName",
                  placeholder: "First Name",
                  required: true,
                }}
                value={user.firstName}
                errors={errors.firstName}
                handleChange={(value: string) =>
                  setUser({ ...user, firstName: value })
                }
              />

              <TextInput
                label="Last Name"
                attrs={{
                  type: "text",
                  id: "lastName",
                  placeholder: "Last Name",
                  required: true,
                }}
                value={user.lastName}
                errors={errors.lastName}
                handleChange={(value: string) =>
                  setUser({ ...user, lastName: value })
                }
              />

              <TextInput
                label="Email ID"
                attrs={{
                  type: "email",
                  id: "email",
                  placeholder: "Your Email ID",
                  required: true,
                }}
                value={user.email}
                errors={errors.email}
                handleChange={(value: string) =>
                  setUser({ ...user, email: value })
                }
              />

              <TextInput
                label="User ID"
                attrs={{
                  type: "text",
                  id: "user_id",
                  placeholder: "Enter a Unique user ID",
                  required: true,
                  title:
                    "Space and special characters are not allowed and min length should be 4",
                  minLength: 4,
                }}
                value={user.user_id}
                errors={errors.user_id}
                handleChange={(value: string) => {
                  setUser({ ...user, user_id: value });
                  handleChange(value);
                }}
              />

              {!errors.user_id &&
                user.user_id?.length >= 4 &&
                (isUserNameAvailable ? (
                  <p
                    className="font-weight-bold m-2"
                    style={{ color: "green" }}
                  >
                    User Name Available :)
                  </p>
                ) : (
                  <p className="text-danger font-weight-bold m-2">
                    User Name Not available :(
                  </p>
                ))}

              <TextInput
                label="Company Name"
                attrs={{
                  type: "text",
                  id: "company",
                  placeholder: "Company Name",
                  required: true,
                }}
                value={user.companyName}
                errors={errors.company}
                handleChange={(value: string) =>
                  setUser({ ...user, companyName: value })
                }
              />

              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <div className="d-flex align-items-center">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    className="form-control"
                    id="password"
                    placeholder="Enter Password"
                    name="password"
                    value={user.password}
                    minLength={8}
                    onChange={(e: any) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$"
                    title="Must contain at least one number, one uppercase letter, one lowercase letter, one special character and at least 10 or more characters"
                    required={true}
                  />
                  <span
                    className="mx-4"
                    title="See Password"
                    role="button"
                    onClick={(_) => setPasswordVisibility(!passwordVisibility)}
                  >
                    <i
                      className="fa fa-eye"
                      style={{ color: passwordVisibility ? "green" : "black" }}
                    ></i>
                  </span>
                </div>
              </div>

              <TextInput
                label="Confirm Password"
                attrs={{
                  type: "password",
                  hidden: false,
                  id: "c-password",
                  placeholder: "Retype Password",
                  required: true,
                }}
                value={user.confirmPassword}
                errors={errors.confirmPassword}
                handleChange={(value: string) => {
                  setUser({ ...user, confirmPassword: value });
                  if (errors.confirmPassword?.length) {
                    setErrors({ ...errors, confirmPassword: [] });
                  }
                }}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
