import styles from "./style.module.css";
import ReactPaginate from "react-paginate";

export default function Paginate(props: {
  handlePageChange: any;
  pageCount: number;
  page: number;
  total: number;
}) {
  const { handlePageChange, pageCount, page, total } = props;
  return (
    <div className={styles.paginate_container}>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next →"
        onPageChange={handlePageChange}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        previousLabel="← Previous"
        forcePage={page - 1}
        // renderOnZeroPageCount={null}
        breakClassName={`page-item ${styles["__page-item__"]}`}
        breakLinkClassName={"page-link"}
        containerClassName={`pagination ${styles.__pagination__}`}
        pageClassName={`page-item ${styles["__page-item__"]}`}
        pageLinkClassName={"page-link"}
        previousClassName={`page-item ${styles["__page-item__"]}`}
        previousLinkClassName={"page-link"}
        nextClassName={`page-item ${styles["__page-item__"]}`}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />

      <div className="d-flex align-items-center">
        <span className="mx-4 mb-0">Total Results:</span>
        <span
          className="mr-4 mb-0 font-weight-bold h4"
          style={{ color: "#007bff" }}
        >
          {total}
        </span>
      </div>
    </div>
  );
}
