import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Popup from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { sendNotification } from "../../redux/modules/notifications/actions";
import { notificationStatusSelector } from "../../redux/modules/notifications/selectors";
import { usersSelector } from "../../redux/modules/users/selectors";
import { User } from "../../redux/utils/types";
import { reset } from "../../redux/modules/notifications/slice";
import Back from "../../components/Buttons/Back";

export default function CreateNotifications() {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [users, setUsers] = useState<any>([]);

  const usersMap: any = useSelector(usersSelector);
  const { status, message } = useSelector(notificationStatusSelector);

  let usersArray: User[] = Array.from(usersMap.values());
  // filter admin from the array
  usersArray = usersArray.filter(
    (user) => user.role === "user" && user.is_deleted === 0
  );

  const usersOptions: any = usersArray.map((user: User) => {
    return { value: user.id, label: user.firstName };
  });

  const dispatch = useDispatch<any>();

  const handleCreateNotification = () => {
    if (users.length === 0) return;
    const userIds: number[] = users.map((user: any) => user.value);
    const notification = { title, subtitle, userIds };
    dispatch(sendNotification(notification));
  };

  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const handleSuccessModal = () => {
    dispatch(reset());
    navigate(-1);
  };

  return (
    <>
      {status === "loading" && <Spinner />}
      <Popup
        show={status === "success"}
        handleClose={handleSuccessModal}
        message={message}
      />
      <div className="page-content p-0 bg-white" id="content">
        <div className="row-header">
          <div className="container-fluid">
            <div className="align-items-center d-flex justify-content-between">
              <h2 className="head">Notifications</h2>
              <div className="toggler">
                <button
                  className="btn nav-btn navbar-toggler d-block d-lg-none"
                  type="button"
                >
                  <span className="fa fa-bars"></span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="user-box">
          <div className="container-fluid">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group form-buttons">
                <Back />
                <button
                  onClick={handleCreateNotification}
                  className="btn suggest-btn"
                >
                  Create
                </button>
              </div>

              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="subtitle">Subtitle</label>
                <textarea
                  className="form-control"
                  id="subtitle"
                  placeholder="Subtitle"
                  value={subtitle}
                  onChange={(e) => setSubtitle(e.target.value)}
                ></textarea>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="company">Company</label>
                    <input
                      type="text"
                      className="form-control search-btn"
                      id="company"
                      placeholder="company"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="user">Users</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      // defaultValue={[colourOptions[4], colourOptions[5]]}
                      isMulti
                      onChange={(e) => setUsers(e)}
                      options={usersOptions}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
