import { AdminRoutes } from "./AdminRoutes";
import UserRoutes from "./UserRoutes";

export default function Routers() {
  const isAdminSite = window.location.hostname.includes("ad");

  if (isAdminSite) {
    return <AdminRoutes />;
  } else {
    return <UserRoutes />;
  }
}
