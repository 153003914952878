import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AllNotificationsSelector } from "../../redux/modules/notifications/selectors";
import { NavbarToggler } from "../../components/NavbarToggler";
import { EmptyList } from "../../components/EmptyList";

export default function AdminNotifications() {
  const notifications = useSelector(AllNotificationsSelector);

  return (
    <div className="page-content p-0 bg-white" id="content">
      <div className="row-header">
        <div className="container-fluid">
          <div className="align-items-center d-flex justify-content-between flex-wrap">
            <h2 className="head">Notifications</h2>
            <Link to="new" className="btn suggest-btn" role="button">
              Create Notification
            </Link>
            <NavbarToggler />
          </div>
        </div>
      </div>

      <div className="container-fluid pt-2">
        <div className="table-responsive">
          <table className="table global-table justify-content-between p-2">
            {notifications.map((notification) => (
              <tr key={notification.id}>
                <td>{notification.title}</td>
                <td className="text-right">
                  <Link to={`view/${notification.id}`} className="btn view-btn">
                    View
                  </Link>
                </td>
              </tr>
            ))}
            {notifications.length === 0 && (
              <EmptyList
                title="You are all caught up with Notifications :)"
                subtitle="Send a notification to your user to notify them"
              />
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
