import { createAsyncThunk } from '@reduxjs/toolkit'
import { httpCloseRequest, httpGetRequests, httpSendRequest } from './api'

export const getRequests = createAsyncThunk(
  'requests/get',
  async (_, thunkAPI) => {
    try {
      return await httpGetRequests()
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const sendRequest = createAsyncThunk(
  'requests/send',
  async (
    request: {
      companyId: number
      comment: string
      type: string
    },
    thunkAPI
  ) => {
    // 'nugget' | 'tag' | 'flag' | 'source' | 'partner'
    try {
      return await httpSendRequest(request)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const closeRequest = createAsyncThunk(
  'request/close',
  async (id: number, thunkAPI) => {
    try {
      return await httpCloseRequest(id)
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)
