import React, { useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useSearchParams } from "react-router-dom";

import UserMenu from "../components/UserMenu";
import Home from "../pages/User/Home";
import ActivityFeed from "../pages/User/ActivityFeed";
import Flagged from "../pages/User/Flagged/Flagged";
import Settings from "../pages/User/Settings";
import Notifications from "../pages/User/Notifications";
import CompanyDetails from "../pages/ViewCompany";
import NotificationDetails from "../pages/NotificationDetails";
import Suggestion from "../pages/Suggestion";
import UserLogin from "../pages/Login/User";
import { TermsAndConditions } from "../pages/User/TermsAndConditions";
import Unauthorized from "../pages/Unauthorized";
import { authSelector } from "../redux/modules/auth/userSelector";
import { useDispatch, useSelector } from "react-redux";
import PasswordReset from "../pages/PasswordReset";
import Requests from "../pages/User/Requests";
import { logout, whoAmI } from "../redux/modules/auth/actions";
import Spinner from "../components/Spinner";
import { reset } from "../redux/modules/users/slice";
import NotFound from "../pages/NotFound";
import { setThirdPartyUser } from "../redux/modules/thirdPartyUser/slice";
import AIResponse from "../pages/User/Home/aiResponse";
import Analytics from "../pages/User/Analytics";

export default function UserRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<UserLogin />} />
      <Route path="/airesponse" element={<AIResponse />} />
      <Route path="/thirdparty/auth" element={<ProxyLogin />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route element={<UserMenu />}>
          <Route index element={<Navigate to="/home" />} />
          <Route path="/airesponse" element={<AIResponse />} />
          {/* <Route path="home" element={<Home />} /> */}
          <Route path="home">
            <Route index element={<Home />} />
            <Route path="view/:id">
              <Route index element={<CompanyDetails />} />
              <Route path="suggestion" element={<Suggestion />} />
              <Route path="suggestion/:type" element={<Suggestion />} />
            </Route>
          </Route>
          <Route path="analytics">
            <Route index element={<Analytics />} />
            <Route path="view/:id">
              <Route index element={<CompanyDetails />} />
            </Route>
          </Route>
          <Route path="activity" element={<ActivityFeed />} />
          <Route path="requests" element={<Requests />} />
          {/* <Route path="companies/view/:id" element={<CompanyDetails />} */}
          <Route path="flagged">
            <Route index element={<Flagged />} />
            <Route path="view/:id">
              <Route index element={<CompanyDetails />} />
              <Route path="suggestion" element={<Suggestion />} />
              <Route path="suggestion/:type" element={<Suggestion />} />
            </Route>
          </Route>
          <Route path="settings" element={<Settings />} />
          <Route path="notifications">
            <Route index element={<Notifications />} />
            <Route path="view/:id" element={<NotificationDetails />} />
          </Route>
        </Route>
        <Route path="terms" element={<TermsAndConditions />} />
      </Route>
      {/* <Route path="password-reset" element={<PasswordReset />} /> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

function PrivateRoute() {
  const dispatch = useDispatch<any>();
  const { user, status } = useSelector(authSelector);

  useEffect(() => {
    if (!user) {
      dispatch(whoAmI()).finally(() => dispatch(reset()));
    }
  }, [dispatch, user]);

  if (user && user.is_deleted) {
    dispatch(logout());
    return <Unauthorized />;
  } else if (user) {
    return <Outlet />;
  } else if (status === "failed") {
    return <Navigate to="/login" />;
  } else {
    return <Spinner />;
  }
}

function ProxyLogin(){
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { user, status } = useSelector(authSelector);

  if(token){
    sessionStorage.setItem("token", token);
    dispatch(setThirdPartyUser(false));
    dispatch(whoAmI()).finally(() => dispatch(reset()));
  }
  if (user && user.is_deleted) {
    dispatch(logout());
    return <Unauthorized />;
  } else if (user) {
    return <Navigate to="/home" />;
  } else if (status === "failed") {
    return <Navigate to="/login" />;
  } else {
    return <Spinner />;
  }
}