import { useState, useEffect, useRef, MouseEventHandler } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { companySelector } from "../../redux/modules/company/selectors";
import {
  editCompany,
  removeCompany,
} from "../../redux/modules/company/actions";
import Prompt from "../../components/ConfirmationPrompt";
import { authSelector } from "../../redux/modules/auth/userSelector";
type FlagColor = "none" | "orange" | "black" | "green";

export default function TopBar(props: { handleBack: MouseEventHandler }) {
  const { id = "0" } = useParams();
  const companies: any = useSelector(companySelector);

  const { user } = useSelector(authSelector);

  const company: any = companies.get(id);

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [isPrompt, setIsPrompt] = useState<boolean>(false);

  //compnay will be undefined if its a create compnay tab

  const { handleBack } = props;

  const [companyName, setCompanyName] = useState("");

  const [flagColor, setFlagColor] = useState<FlagColor>("black");
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    if (company?.companyName) {
      setCompanyName(company.companyName);
    }

    if (company?.flagColor) {
      setFlagColor(company.flagColor);
    }
  }, [company]);

  const handleDeleteCompany = (e: any) => {
    dispatch(removeCompany(id));
    navigate(-1);
  };

  const handleEditCompany = (e: any) => {
    if (formRef?.current?.checkValidity() === false) {
      formRef?.current?.reportValidity();
      return;
    }
    const company = { companyName, flagColor };
    const payload = { companyId: +id, company };
    setIsEditing(false);

    dispatch(editCompany(payload));
  };

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <>
      <Prompt
        show={isPrompt}
        prompt="Are you sure you want this company?"
        handleCancel={() => setIsPrompt(false)}
        handleOk={handleDeleteCompany}
      />

      <div className="container-fluid">
        <form onSubmit={(e) => e.preventDefault()} ref={formRef}>
          <div className="company">
            <div className="company-card">
              <div
                className="position-relative"
                role="button"
                // style={{ height: "80px" }}
              >
                <img
                  src="/img/company.png"
                  style={{ width: "100px" }}
                  alt="company-logo"
                />
              </div>
              <div>
                <textarea
                  id="companyname"
                  className="title company-input mt-2 form-control rounded"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  disabled={!isEditing}
                  required={true}
                ></textarea>
              </div>
            </div>

            <div className="form-buttons">
              <button className="btn back-btn mx-2" onClick={handleBack}>
                Back
              </button>

              {user.role === "admin" ? (
                <>
                  {!isEditing ? (
                    <>
                      <button
                        className="btn suggest-btn mx-2"
                        onClick={() => setIsEditing(true)}
                        type="button"
                      >
                        Edit
                      </button>
                      <button
                        className="btn back-btn mx-2"
                        onClick={() => setIsPrompt(true)}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="btn suggest-btn mx-2"
                        onClick={handleEditCompany}
                      >
                        Save
                      </button>
                      <button
                        className="btn back-btn mx-2"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="check-flags">
            <div className="custom-control custom-checkbox login-check">
              <input
                type="checkbox"
                className="custom-control-input"
                id="flag1"
                name="flag"
                value="green"
                checked={flagColor === "green"}
                onChange={(e) =>
                  flagColor === "green"
                    ? setFlagColor("none")
                    : setFlagColor("green")
                }
                disabled={!isEditing}
              />
              <label className="custom-control-label" htmlFor="flag1">
                <i className="fa fa-flag text-success"></i>
              </label>
            </div>
            <div className="custom-control custom-checkbox login-check">
              <input
                type="checkbox"
                className="custom-control-input"
                name="flag"
                id="flag2"
                value="red"
                checked={flagColor === "orange"}
                onChange={(e) =>
                  flagColor === "orange"
                    ? setFlagColor("none")
                    : setFlagColor("orange")
                }
                disabled={!isEditing}
              />
              <label className="custom-control-label" htmlFor="flag2">
                <i className="fa fa-flag text-warning"></i>
              </label>
            </div>
            <div className="custom-control custom-checkbox login-check">
              <input
                type="checkbox"
                name="flag"
                className="custom-control-input"
                id="flag3"
                value="black"
                // navigate("/admin/companies");
                checked={flagColor === "black"}
                onChange={(e) =>
                  flagColor === "black"
                    ? setFlagColor("none")
                    : setFlagColor("black")
                }
                disabled={!isEditing}
              />
              <label className="custom-control-label" htmlFor="flag3">
                <i className="fa fa-flag text-dark"></i>
              </label>
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-link my-2 see_flagbtn"
              data-toggle="modal"
              data-target="#flag-modal"
            >
              See flag details
            </button>
          </div>
        </form>
      </div>

      <div
        className="modal fade add-modal"
        id="flag-modal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content p-3">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="flag-content py-5 px-3">
                <p>
                  This section consist of the list of all the companies that
                  are:
                </p>
                <ol>
                  <li>
                    In Search results but have gaps in information(
                    <i className="fa fa-flag text-success"></i>)
                  </li>
                  <li>
                    Not in Search Results and have gaps in information(
                    <i className="fa fa-flag text-warning"></i>)
                  </li>
                  <li>
                    Not in Search Results and Company Profile is incomplete(
                    <i className="fa fa-flag text-dark"></i>)
                  </li>
                </ol>
                <p>
                  Users are invited to contact us in case they have information
                  regarding the information gaps in the Company Details and help
                  us build a more reliable information portal
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
